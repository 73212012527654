import React from 'react';

export interface SelectedOptionProps {
  optionName: string;
  optionQuantity: number;
}

function SelectedOption({ optionName, optionQuantity }: SelectedOptionProps) {
  return (
    <li className="selected-option-item">
      {optionName} (<strong>{optionQuantity}</strong>)
    </li>
  );
}

export default SelectedOption;
