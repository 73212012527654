/* eslint-disable react/react-in-jsx-scope */
interface AraAllEatIconProps {
  size?: number | string;
}
export const AraAllEatIcon = ({ size = 40 }: AraAllEatIconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="18" stroke="#F27900" strokeWidth="3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 10C15.5523 10 16 10.4477 16 11V17.8092C16.0386 17.7953 16.0771 17.7804 16.1154 17.7644C16.3878 17.6509 16.6046 17.5028 16.7504 17.3206C16.8836 17.1541 17 16.908 17 16.5V11.5C17 10.9477 17.4477 10.5 18 10.5C18.5523 10.5 19 10.9477 19 11.5V16.5C19 17.342 18.7414 18.0334 18.3121 18.57C17.8954 19.0909 17.3622 19.4116 16.8846 19.6106C16.5732 19.7403 16.2678 19.8258 16 19.8826V29C16 29.5523 15.5523 30 15 30C14.4477 30 14 29.5523 14 29V19.8826C13.7322 19.8258 13.4268 19.7403 13.1154 19.6106C12.6378 19.4116 12.1046 19.0909 11.6879 18.57C11.2586 18.0334 11 17.342 11 16.5V11.5C11 10.9477 11.4477 10.5 12 10.5C12.5523 10.5 13 10.9477 13 11.5V16.5C13 16.908 13.1164 17.1541 13.2496 17.3206C13.3954 17.5028 13.6122 17.6509 13.8846 17.7644C13.9229 17.7804 13.9614 17.7953 14 17.8092V11C14 10.4477 14.4477 10 15 10ZM27 10C27.5523 10 28 10.4477 28 11V29C28 29.5523 27.5523 30 27 30C26.4477 30 26 29.5523 26 29V20.5H23C22.4477 20.5 22 20.0523 22 19.5V15C22 13.825 22.2966 12.8773 22.7929 12.1328C23.2851 11.3946 23.9342 10.9149 24.5528 10.6056C25.1675 10.2982 25.7701 10.1495 26.2106 10.0761C26.4332 10.039 26.6209 10.0201 26.7569 10.0104C26.825 10.0055 26.8807 10.0029 26.9218 10.0015C26.9423 10.0008 26.9593 10.0005 26.9724 10.0003L26.9892 10L26.9953 10L26.9979 10L26.999 10C26.999 10 27 10 27 11V10ZM26 12.1753C25.8203 12.2304 25.6324 12.3018 25.4472 12.3944C25.0658 12.5851 24.7149 12.8554 24.4571 13.2422C24.2034 13.6227 24 14.175 24 15V18.5H26V12.1753Z"
        fill="white"
      />
    </svg>
  );
};
