/* eslint @typescript-eslint/no-var-requires: "off" */
const pageSound = require('sound/page.wav');
const orderSound = require('sound/order.wav');
const plusSound = require('sound/plus.wav');
const minusSound = require('sound/minus.wav');
const addSound = require('sound/add.wav');
const cancelSound = require('sound/cancel.wav');
const closeSound = require('sound/close.wav');
const confirmSound = require('sound/confirm.wav');
const deleteSound = require('sound/delete.wav');
const errorSound = require('sound/error.wav');
const modeSound = require('sound/mode.wav');
const okSound = require('sound/ok.wav');
const subSound = require('sound/sub.wav');

export interface SoundBuffer {
  [key: string]: AudioBuffer;
}

export interface SoundFile {
  [key: string]: any;
}

// create source sound files
const soundFiles: SoundFile = {};
soundFiles['minus'] = minusSound;
soundFiles['plus'] = plusSound;
soundFiles['order'] = orderSound;
soundFiles['page'] = pageSound;
soundFiles['add'] = addSound;
soundFiles['cancel'] = cancelSound;
soundFiles['close'] = closeSound;
soundFiles['confirm'] = confirmSound;
soundFiles['delete'] = deleteSound;
soundFiles['error'] = errorSound;
soundFiles['mode'] = modeSound;
soundFiles['ok'] = okSound;
soundFiles['sub'] = subSound;

const AudioContext = window.AudioContext || window.webkitAudioContext;
const context = new AudioContext(); // Make it crossbrowser

// initial buffer for each sound file
const soundBuffers: SoundBuffer = {};
for (const soundName in soundFiles) {
  fetch(soundFiles[soundName])
    .then((response) => response.arrayBuffer())
    .then((arrayBuffer) =>
      context.decodeAudioData(
        arrayBuffer,
        (audioBuffer) => {
          soundBuffers[soundName] = audioBuffer;
        },
        (error) => console.error(error)
      )
    );
}

// play sound with key
export function playSound(key: string) {
  const source = context.createBufferSource();
  source.buffer = soundBuffers[key];
  source.connect(context.destination);
  source.start();
}
