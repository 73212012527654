import axios from 'axios';
import { getToken } from 'utils/Utils';

// Set config defaults when creating the instance
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

// Add a request interceptor
axiosClient.interceptors.request.use(
  function (config) {
    config.headers = {
      Authorization: 'Bearer ' + getToken(),
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosClient;
