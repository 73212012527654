import AlertModal from 'components/AlertModal/AlertModal';
import ConfirmModal from 'components/ComfirmModal/ConfirmModal';
import MenuBarOrderSuccessModal from 'components/MenuBarOrderSuccessModal/MenuBarOrderSuccessModal';
import React from 'react';
import { Payload } from 'types';
import { MODAL_TYPES } from '../../constants';
import createGlobalModalContext, { GlobalModalComponentType, GlobalModalStoreType } from './createGlobalModalContext';

export interface GlobalModalProps {
  children: React.ReactNode;
}

const MODAL_COMPONENTS: Payload = {
  [MODAL_TYPES.ALERT_MODAL]: AlertModal,
  [MODAL_TYPES.CONFIRM_MODAL]: ConfirmModal,
  [MODAL_TYPES.MENU_BAR_ORDER_SUCCESS_MODAL]: MenuBarOrderSuccessModal,
};

const initalState: GlobalModalStoreType<GlobalModalComponentType> = {
  modalType: null,
  modalProps: null,
};

export const GlobalModalContext = createGlobalModalContext(initalState);
const { GlobalModalProvider, useGlobalModalState } = GlobalModalContext;

function GlobalModalDisplay() {
  const [modalType] = useGlobalModalState((store) => store.modalType);
  const [modalProps] = useGlobalModalState((store) => store.modalProps);

  if (!modalType || !modalProps) {
    return null;
  }

  const ModalComponent = MODAL_COMPONENTS[modalType];
  if (!ModalComponent) {
    return null;
  }

  return <ModalComponent {...modalProps} />;
}

function GlobalModal({ children }: GlobalModalProps) {
  return (
    <GlobalModalProvider>
      <GlobalModalDisplay />
      {children}
    </GlobalModalProvider>
  );
}

export default GlobalModal;
