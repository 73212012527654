import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AraLanguageIcon } from 'theme/icons';
import SessionStorage, { SESSION_LANGUAGE_CODE } from 'utils/sessionStorage';

function LanguageBox() {
  const { t, i18n } = useTranslation();
  const languageSelectorRef = useRef<HTMLUListElement>(null);

  const toggleShowLanguageBox = () => {
    if (languageSelectorRef.current) {
      languageSelectorRef.current.classList.toggle('show');
    }
  };

  const changeAppLanguage = (languageCode: string) => {
    i18n.changeLanguage(languageCode);
    toggleShowLanguageBox();
    SessionStorage.setItem(SESSION_LANGUAGE_CODE, languageCode);
  };

  useEffect(() => {
    const detectOutsideLanguageBox = (event: MouseEvent) => {
      if (!document.getElementById('language-box')?.contains(event.target as HTMLElement)) {
        // Clicked outside the box
        if (languageSelectorRef.current) {
          languageSelectorRef.current.classList.remove('show');
        }
      }
    };
    document.addEventListener('click', detectOutsideLanguageBox);
    return () => {
      document.removeEventListener('click', detectOutsideLanguageBox);
    };
  }, []);

  return (
    <div id="language-box">
      <a
        id="language-button"
        className="language-button align-items-center d-inline-flex"
        onClick={toggleShowLanguageBox}
      >
        <AraLanguageIcon size={24} />
        <span className="language-button__text">{t('App.language')}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="11"
          fill="#F27900"
          className="bi bi-caret-down-fill"
          viewBox="0 0 16 16"
        >
          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
        </svg>
      </a>
      <ul className="language-selector list-unstyled mb-0" ref={languageSelectorRef}>
        <li
          className={`language-selector-item ${i18n.language === 'jp' ? 'selected' : ''}`}
          onClick={changeAppLanguage.bind(null, 'jp')}
        >
          日本語
        </li>
        <li
          className={`language-selector-item ${i18n.language === 'us' ? 'selected' : ''}`}
          onClick={changeAppLanguage.bind(null, 'us')}
        >
          English
        </li>
        <li
          className={`language-selector-item ${i18n.language === 'cn' ? 'selected' : ''}`}
          onClick={changeAppLanguage.bind(null, 'cn')}
        >
          中文（簡体字）
        </li>
        <li
          className={`language-selector-item ${i18n.language === 'tw' ? 'selected' : ''}`}
          onClick={changeAppLanguage.bind(null, 'tw')}
        >
          中文（繁体字）
        </li>
        <li
          className={`language-selector-item ${i18n.language === 'kr' ? 'selected' : ''}`}
          onClick={changeAppLanguage.bind(null, 'kr')}
        >
          한국어
        </li>
        <li
          className={`language-selector-item ${i18n.language === 'vn' ? 'selected' : ''}`}
          onClick={changeAppLanguage.bind(null, 'vn')}
        >
          Tiếng Việt
        </li>
      </ul>
    </div>
  );
}

// export default withTranslation()(LanguageBox);
export default LanguageBox;
