import { useAppSelector } from 'app/hooks';
import { QuantityButtonGroup } from 'components/QuantityButtonGroup/QuantityButtonGroup';
import {
  log,
  LOG_OP_TYPE_MENU_DETAILS_CHANGE_THE_QUANTITY_OF_SUBMENUS,
  LOG_OP_TYPE_MENU_DETAILS_SUBMENU_SELECTION,
} from 'log/Log';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { playSound } from 'sound/Sound';
import { selectThMenuInfo } from 'store/allYouCanEatSlice';
import { selectMenuInfo } from 'store/mainSlice';
import { selectPhMenuInfo } from 'store/pitaSlice';
import { Payload } from 'types';
import { attrLang, convertPriceInTaxToNoTax, getCategoryCodeFromDishCode } from 'utils/Utils';
import { dishDispatchContext } from '../DishModal';

export interface OptionItemProps {
  modeCode: string;
  itemCode: string;
  orderIdx: number;
  groupInfo: Payload;
  menuItemInfo: Payload;
  menuItemState: Payload;
}

function OptionItem({ modeCode, itemCode, orderIdx, groupInfo, menuItemInfo, menuItemState }: OptionItemProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const menuInfo = useAppSelector(selectMenuInfo);
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);
  const menuDetailDispatch = useContext(dishDispatchContext);
  const menuItemCode: string = menuItemInfo.poscd;
  const groupCode: string = groupInfo.gno;
  const type = groupInfo.hisuflg === '1' && groupInfo.multipleflg === '0' ? 'radio' : 'checkbox';
  const checked: boolean = menuItemState.checked;
  const optionName = attrLang(menuItemInfo, 'lang');
  const optionPrice = parseInt(menuItemInfo.price);
  const freeClass: string = optionPrice === 0 ? 'free' : '';

  const categoryCode = getCategoryCodeFromDishCode(menuInfo, thMenuInfo, phMenuInfo, itemCode);

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    playSound('sub');

    menuDetailDispatch({
      type: 'checkbox',
      orderIdx: orderIdx,
      groupCode: groupCode,
      itemCode: menuItemCode,
      payload: e.target.checked, // use event.target.checked to handle both radio and checkbox
    });

    log({
      op_type: LOG_OP_TYPE_MENU_DETAILS_SUBMENU_SELECTION,
      op_detail: {
        mode: modeCode,
        category: categoryCode,
        parent_poscd: itemCode,
        group: groupCode,
        poscd: menuItemInfo.poscd,
        on_off: e.target.checked ? '1' : '0',
      },
    });
  };

  const handleDecrease = () => {
    menuDetailDispatch({
      type: 'quantity',
      orderIdx: orderIdx,
      groupCode: groupCode,
      itemCode: menuItemCode,
      payload: 'decrease',
    });

    log({
      op_type: LOG_OP_TYPE_MENU_DETAILS_CHANGE_THE_QUANTITY_OF_SUBMENUS,
      op_detail: {
        mode: modeCode,
        category: categoryCode,
        parent_poscd: itemCode,
        group: groupCode,
        poscd: menuItemInfo.poscd,
        plus_minus: '0',
        num: menuItemState.quantity > 1 ? '1' : '0',
      },
    });
  };

  const handleIncrease = () => {
    menuDetailDispatch({
      type: 'quantity',
      orderIdx: orderIdx,
      groupCode: groupCode,
      itemCode: menuItemCode,
      payload: 'increase',
    });
    log({
      op_type: LOG_OP_TYPE_MENU_DETAILS_CHANGE_THE_QUANTITY_OF_SUBMENUS,
      op_detail: {
        mode: modeCode,
        category: categoryCode,
        parent_poscd: itemCode,
        group: groupCode,
        poscd: menuItemInfo.poscd,
        plus_minus: '1',
        num: menuItemState.quantity < groupInfo.kousenum ? '1' : '0',
      },
    });
  };

  const hasKousenum = groupInfo.kousenum > 1 && menuItemState.checked;

  return (
    <li className="menu-option">
      <div className="d-flex align-items-start justify-content-between">
        <div className="w-50">
          {type === 'checkbox' ? (
            <label className="ara-checkbox">
              <span className="ara-checkbox-text">{optionName}</span>
              <input
                id={`option-${itemCode}-${orderIdx}-${groupCode}-${menuItemCode}`}
                type="checkbox"
                value={menuItemCode}
                onChange={handleCheck}
                checked={checked}
              />
              <span className="checkmark"></span>
            </label>
          ) : (
            <label className="ara-radio">
              <span className="ara-radio-text">{optionName}</span>
              <input
                id={`option-${itemCode}-${orderIdx}-${groupCode}-${menuItemCode}`}
                type="radio"
                value={menuItemCode}
                onChange={handleCheck}
                checked={checked}
              />
              <span className="checkmark"></span>
            </label>
          )}
        </div>
        <div className="w-50">
          {groupInfo.kousenum > 1 && menuItemState.checked && (
            <div className="text-end mb-2">
              <QuantityButtonGroup
                handleDecrease={handleDecrease}
                quantity={menuItemState.quantity}
                handleIncrease={handleIncrease}
                min={1}
                disableMin
              />
            </div>
          )}

          <div
            className={`option-price ${freeClass} ${menuItemInfo.price < 0 ? 'text-danger' : ''} ${hasKousenum ? 'kousenum' : ''
              }`}
          >
            <span className="tax-excluded">
              {freeClass === '' && menuItemInfo.price > 0 && <span>+</span>}
              {t('OrderHistory.currency')}
              <NumberFormat
                value={convertPriceInTaxToNoTax(menuItemInfo.price)}
                displayType={'text'}
                thousandSeparator={true}
              />
            </span>
            <span className="tax-included">
              ({t('OrderHistory.tax_included')}&nbsp;
              {t('OrderHistory.currency')}
              <NumberFormat value={menuItemInfo.price} displayType={'text'} thousandSeparator={true} />)
            </span>
          </div>
        </div>
      </div>
    </li>
  );
}

export default React.memo(OptionItem);
