import AnimatedRoutes from 'components/AnimatedRoutes/AnimatedRoutes';
import { useEffect, useLayoutEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import { animationRippleClickEvent, checkIsMember, decrypt, getQueryParam, isDeviceIOS } from 'utils/Utils';
import SessionStorage, {
  SESSION_CURRENT_CATEGROY_CODE,
  SESSION_CURRENT_MODE_CODE,
  SESSION_CUSTOMER_ID_KEY,
  SESSION_CUSTOMER_TOKEN_KEY,
} from 'utils/sessionStorage';
import './App.scss';
import { CATEGORY_SEARCH_MENU_ID } from './constants';

function appStart(customer_id: number, token: string): number {
  SessionStorage.setItem(SESSION_CUSTOMER_ID_KEY, customer_id.toString());
  SessionStorage.setItem(SESSION_CUSTOMER_TOKEN_KEY, token);
  return 1;
}

// must cast as any to set property on window
const _global = (window /* browser */ || global) /* node */ as any;
_global.appStart = appStart;

function App() {
  const cid = getQueryParam(SESSION_CUSTOMER_ID_KEY) || '';
  const ctoken = getQueryParam(SESSION_CUSTOMER_TOKEN_KEY) || '';
  if (!SessionStorage.getItem(SESSION_CUSTOMER_ID_KEY) || !SessionStorage.getItem(SESSION_CUSTOMER_TOKEN_KEY)) {
    SessionStorage.setItem(SESSION_CUSTOMER_ID_KEY, decrypt(cid));
    SessionStorage.setItem(SESSION_CUSTOMER_TOKEN_KEY, ctoken);
  }

  useLayoutEffect(() => {
    const reloadPageHandler = () => {
      if (isDeviceIOS()) {
        if (document.visibilityState === 'visible') {
          // iPad or iPhone & tab active
          location.reload();
        }
      }
    };
    document.addEventListener('visibilitychange', reloadPageHandler);

    return () => {
      document.removeEventListener('visibilitychange', reloadPageHandler);
    };
  }, []);

  useEffect(() => {
    //fix 100vh safari
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
      doc.style.setProperty('--position-modal-top', `0px`);
      doc.style.setProperty('--main-page-scrolltop', `0p`);
    };
    window.addEventListener('resize', appHeight);
    appHeight();

    //fix smooth scroll
    smoothscroll.polyfill();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const disablePageZoom = (event: any) => {
      if (isDeviceIOS()) {
        event.scale !== 1 && event.preventDefault();
      }
    };
    document.addEventListener('dblclick', disablePageZoom, { passive: false });
    document.addEventListener('touchmove', disablePageZoom, { passive: false });

    if (isDeviceIOS()) {
      window.addEventListener('gesturestart', (e) => e.preventDefault());
      window.addEventListener('gesturechange', (e) => e.preventDefault());
      window.addEventListener('gestureend', (e) => e.preventDefault());
    }

    // tap ripple animation
    document.addEventListener('click', animationRippleClickEvent);

    return () => {
      window.removeEventListener('resize', appHeight);
      document.removeEventListener('click', animationRippleClickEvent);
      document.removeEventListener('dblclick', disablePageZoom);
      document.removeEventListener('touchmove', disablePageZoom);

      // reset modecode and category when exit app
      if (SessionStorage.getItem(SESSION_CURRENT_CATEGROY_CODE) === CATEGORY_SEARCH_MENU_ID) {
        SessionStorage.removeItem(SESSION_CURRENT_MODE_CODE);
        SessionStorage.removeItem(SESSION_CURRENT_CATEGROY_CODE);
      }
    };
  }, []);

  const classMember = checkIsMember() ? 'member' : 'non-member';

  return (
    <div id="smart-order" className={`${classMember}`}>
      <BrowserRouter>
        <AnimatedRoutes />
      </BrowserRouter>
    </div>
  );
}

export default App;
