import { useAppSelector } from 'app/hooks';
import { playSound } from 'sound/Sound';
import { selectThMenuInfo } from 'store/allYouCanEatSlice';
import { selectPhMenuInfo } from 'store/pitaSlice';
import { AraTimesIcon } from 'theme/icons';
import { imgFullPath } from 'utils/Utils';
import { TAP_ANIMATION_TIME } from '../../constants';
import './scss/CourseIntroductionModal.scss';

type CourseIntroductionModalProps = {
  show?: boolean;
  type: string;
  onClose: () => void;
};

function CourseIntroductionModal({ show = false, type, onClose }: CourseIntroductionModalProps) {
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);

  let imageUrl = '';
  if (type === 'all-you-can-eat') {
    imageUrl = imgFullPath(thMenuInfo?.introduction.img);
  } else if (type === 'pita') {
    imageUrl = imgFullPath(phMenuInfo?.introduction.img);
  }

  const handleClose = () => {
    setTimeout(() => {
      playSound('close');
      onClose();
      const pageWrapperElem = document.querySelector('.page-wrapper') as HTMLDivElement;
      if (pageWrapperElem) {
        pageWrapperElem.removeAttribute('style');
      }
      document.documentElement.style.setProperty('--position-modal-top', '0px');
    }, TAP_ANIMATION_TIME / 2);
  };

  return show ? (
    <div className="course-intro-modal modal" tabIndex={-1} style={{ display: 'block' }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <a className="modal-btn-close-wrapper" aria-label="Close" onClick={handleClose}>
            <span className="modal-btn-close">
              <AraTimesIcon />
            </span>
          </a>
          <div className="course-intro-modal-body">{show && <img src={imageUrl} />}</div>
        </div>
      </div>
    </div>
  ) : null;
}

export default CourseIntroductionModal;
