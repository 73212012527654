import {
  ALL_YOU_CAN_EAT_MENU_MODEID,
  GENRE_FREQUENTLY_ORDERED_MENU_ID,
  GENRE_SEARCH_MENU_ID,
  MODAL_TYPES,
  PITA_MODEID,
} from '../../constants';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Payload } from 'types';
import {
  attrLang,
  checkAllYouCanEatMenuAvailable,
  checkPitaMenuAvailable,
  getDishInfo,
  getSourceMenuInfoByMode,
} from 'utils/Utils';
import MenuItemThumbnail from 'components/MenuItemThumbnail/MenuItemThumbnail';
import { playSound } from 'sound/Sound';
import { AlertModalProps } from 'components/AlertModal/AlertModal';
import { GlobalModalContext } from 'components/GlobalModal';
import { useAppSelector } from 'app/hooks';
import { selectMenuInfo, selectOutOfStockItems } from 'store/mainSlice';
import { selectThCourseMenu, selectThMenuInfo } from 'store/allYouCanEatSlice';
import { selectPhCourseMenu, selectPhMenuInfo } from 'store/pitaSlice';

export interface GenreMenuProps {
  currentModeCode: string;
  categoryCode: string;
  genreCode: string;
  genreSub: Payload;
}

function GenreMenu({ currentModeCode, categoryCode, genreCode, genreSub }: GenreMenuProps) {
  const { t } = useTranslation();
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const thCourseMenu = useAppSelector(selectThCourseMenu);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);
  const phCourseMenu = useAppSelector(selectPhCourseMenu);
  const menuInfo = useAppSelector(selectMenuInfo);
  const sourceMenuInfo = getSourceMenuInfoByMode(currentModeCode, menuInfo, thMenuInfo, phMenuInfo);
  const outOfStockItems = useAppSelector(selectOutOfStockItems);

  let currentGenre: Payload = {};
  switch (genreCode) {
    case GENRE_FREQUENTLY_ORDERED_MENU_ID: {
      currentGenre = {
        code: GENRE_FREQUENTLY_ORDERED_MENU_ID,
        lang: {
          jp: t('App.genre_freq_ordered_menu', { lng: 'jp' }),
          us: t('App.genre_freq_ordered_menu', { lng: 'us' }),
          cn: t('App.genre_freq_ordered_menu', { lng: 'cn' }),
          kr: t('App.genre_freq_ordered_menu', { lng: 'kr' }),
          vn: t('App.genre_freq_ordered_menu', { lng: 'vn' }),
        },
      };
      break;
    }
    case GENRE_SEARCH_MENU_ID: {
      currentGenre = {
        code: GENRE_SEARCH_MENU_ID,
        lang: {
          jp: t('App.genre_search_menu', { lng: 'jp' }),
          us: t('App.genre_search_menu', { lng: 'us' }),
          cn: t('App.genre_search_menu', { lng: 'cn' }),
          kr: t('App.genre_search_menu', { lng: 'kr' }),
          vn: t('App.genre_search_menu', { lng: 'vn' }),
        },
      };
      break;
    }
    default: {
      currentGenre = sourceMenuInfo?.genre[genreCode];
    }
  }
  const genreName = attrLang(currentGenre, 'lang');
  let menuList: string[] = genreSub.menu;
  if (currentModeCode === ALL_YOU_CAN_EAT_MENU_MODEID) {
    menuList = (genreSub.menu as string[]).filter((itemMenuCode) => {
      return checkAllYouCanEatMenuAvailable(thMenuInfo, thCourseMenu, itemMenuCode);
    });
  } else if (currentModeCode === PITA_MODEID) {
    menuList = (genreSub.menu as string[]).filter((itemMenuCode) => {
      return checkPitaMenuAvailable(phMenuInfo, phCourseMenu, itemMenuCode);
    });
  }

  const ListOfMenuItems = menuList.map((menuItemCode) => {
    const menuItemInfo = getDishInfo(sourceMenuInfo, menuItemCode);
    if (!menuItemInfo) return null;
    const outOfStocked = outOfStockItems.includes(menuItemCode);
    return (
      <MenuItemThumbnail
        key={menuItemCode}
        itemInfo={menuItemInfo}
        modeCode={currentModeCode}
        categoryCode={categoryCode}
        outOfStocked={outOfStocked}
      />
    );
  });

  useEffect(() => {
    if (menuList.length == 0 && currentGenre.code === GENRE_SEARCH_MENU_ID) {
      playSound('error');
      showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
        message: t('MenuList.search_no_result'),
      });
    }
  }, [genreSub]);

  if (currentGenre && currentGenre.code === GENRE_SEARCH_MENU_ID) {
    return (
      <div className="genre-menu-block">
        {genreName && genreName.length > 0 && <div className="genre-name sticky">{genreName}</div>}
        {menuList.length == 0 && (
          <div className="thumbnail-block no-result">
            <p
              dangerouslySetInnerHTML={{
                __html: t('MenuList.search_no_result'),
              }}
            />
          </div>
        )}
        <div className="thumbnail-block">{ListOfMenuItems}</div>
      </div>
    );
  }

  return ListOfMenuItems.length > 0 ? (
    <div id={`genre-menu-${genreCode}`} className="genre-menu-block">
      {genreName && genreName.length > 0 && (
        <div className="genre-name" data-genrename={genreName} dangerouslySetInnerHTML={{ __html: genreName }}></div>
      )}
      <div className="thumbnail-block">{ListOfMenuItems}</div>
    </div>
  ) : null;
}

export default GenreMenu;
