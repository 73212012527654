import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sendLog } from 'apis/Api';
import { HistoryOrderResult } from 'apis/types';
import { RootState } from 'app/store';
import { log, LOG_OP_TYPE_APP_START_COMPLETION } from 'log/Log';
import { BarInfoMap, BarMenuMap, FavMenuType, HistoryFlagType, Payload } from 'types';
import { SESSION_CURRENT_CATEGROY_CODE, SESSION_CURRENT_MODE_CODE } from 'utils/sessionStorage';
import { checkHistoryFlag, checkObjectNotEmpty, getInitalModeCode } from 'utils/Utils';
import { ALL_YOU_CAN_EAT_MENU_MODEID, CATEGORY_SEARCH_MENU_ID, CATEGORY_TOP_MENU_ID, PITA_MODEID } from '../constants';

export interface MainState {
  loading: boolean;
  barInfo: BarInfoMap;
  barMenu: BarMenuMap;
  favItems: FavMenuType;
  freqItems: Payload;
  haveadult: number;
  menuInfo: Payload;
  orderList: Payload[];
  phCourseMenu: Payload;
  phMenuInfo: Payload;
  outOfStockItems: string[]; //shinagire
  startTm: Payload;
  tenpoInfo: Payload[];
  thCourseMenu: Payload;
  thMenuInfo: Payload;

  // app state
  currentModeCode: string;
  currentCategoryCode: string;
  campaignGenrecd: string;
  searchPOSCD: string[] | null;

  // order history
  fetchOrderHistoryLoading: boolean;
  orderHistory: HistoryOrderResult;
  historyFlag: HistoryFlagType;
}

const initialState: MainState = {
  loading: true,
  barInfo: {},
  barMenu: {},
  favItems: {},
  freqItems: {},
  haveadult: 1,
  menuInfo: {},
  orderList: [],
  phCourseMenu: {},
  phMenuInfo: {},
  outOfStockItems: [],
  startTm: {},
  tenpoInfo: [],
  thCourseMenu: {},
  thMenuInfo: {},

  currentModeCode: '',
  currentCategoryCode: '',
  campaignGenrecd: '',
  searchPOSCD: null,
  fetchOrderHistoryLoading: false,
  orderHistory: {
    OrderHeader: {},
    OrderDetail: [],
  },
  historyFlag: {
    setmenu_flg: 0,
    lunch_flg: 0,
  },
};

const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    fetchStartData(state) {
      state.loading = true;
    },
    fetchStartDataSuccess(state, action: PayloadAction<Payload>) {
      state.barInfo = action.payload.data.bar_info;
      state.barMenu = action.payload.data.bar_menu;
      state.menuInfo = action.payload.data.menu_info;
      state.favItems = action.payload.data.fav_menu;
      state.freqItems = action.payload.data.freq_menu;
      state.outOfStockItems = action.payload.data.shinagire || [];
      state.haveadult = Number(action.payload.data.haveadult);

      const timeFrame = parseInt(action.payload.data.start_tm.timeframe);
      const thCourseMenu = action.payload.data.th_course_menu === undefined ? null : action.payload.data.th_course_menu;
      const phCourseMenu = action.payload.data.ph_course_menu === undefined ? null : action.payload.data.ph_course_menu;

      const allYouCanEatState = {
        isShowEatRemainTime: false,
        isShowDrinkRemainTime: false,
      };
      allYouCanEatState.isShowEatRemainTime = thCourseMenu?.th_flg === '1' && thCourseMenu?.th_limit > 0;
      allYouCanEatState.isShowDrinkRemainTime = thCourseMenu?.nh_flg === '1' && thCourseMenu?.nh_limit > 0;
      const isShowRemainAllYouCanEat =
        thCourseMenu !== null && (allYouCanEatState.isShowEatRemainTime || allYouCanEatState.isShowDrinkRemainTime);

      const pitaState = {
        isShowEatRemainTime: false,
        isShowDrinkRemainTime: false,
      };
      pitaState.isShowEatRemainTime = phCourseMenu?.ph_flg === '1' && phCourseMenu?.ph_limit > 0;
      pitaState.isShowDrinkRemainTime = phCourseMenu?.nh_flg === '1' && phCourseMenu?.nh_limit > 0;
      const isShowRemainPita =
        phCourseMenu !== null && (pitaState.isShowEatRemainTime || pitaState.isShowDrinkRemainTime);

      const initialModeCode: string = getInitalModeCode(state.menuInfo, timeFrame, thCourseMenu, phCourseMenu);
      state.currentModeCode = initialModeCode;
      state.currentCategoryCode = '0';

      // load currentModeCode, currentCategoryCode from sessionStorage
      const currentModeCode = sessionStorage.getItem(SESSION_CURRENT_MODE_CODE);
      const currentCategoryCode = sessionStorage.getItem(SESSION_CURRENT_CATEGROY_CODE);

      if (state.menuInfo.mode && currentModeCode && currentCategoryCode) {
        // check mode exists in MenuInfo before restore state
        if (!state.menuInfo.mode[currentModeCode]) {
          sessionStorage.setItem(SESSION_CURRENT_MODE_CODE, initialModeCode);
          sessionStorage.setItem(SESSION_CURRENT_CATEGROY_CODE, CATEGORY_TOP_MENU_ID);
          state.currentModeCode = initialModeCode;
          state.currentCategoryCode = CATEGORY_TOP_MENU_ID;
        } else if (currentModeCode === ALL_YOU_CAN_EAT_MENU_MODEID && !isShowRemainAllYouCanEat) {
          sessionStorage.setItem(SESSION_CURRENT_MODE_CODE, initialModeCode);
          sessionStorage.setItem(SESSION_CURRENT_CATEGROY_CODE, CATEGORY_TOP_MENU_ID);
          state.currentModeCode = initialModeCode;
          state.currentCategoryCode = CATEGORY_TOP_MENU_ID;
        } else if (currentModeCode === PITA_MODEID && !isShowRemainPita) {
          sessionStorage.setItem(SESSION_CURRENT_MODE_CODE, initialModeCode);
          sessionStorage.setItem(SESSION_CURRENT_CATEGROY_CODE, CATEGORY_TOP_MENU_ID);
          state.currentModeCode = initialModeCode;
          state.currentCategoryCode = CATEGORY_TOP_MENU_ID;
        } else {
          state.currentModeCode = currentModeCode;
          state.currentCategoryCode = currentCategoryCode;
        }
      }

      //sendLog
      sendLog().then((sendLogResponse) => {
        if (sendLogResponse) {
          log({
            op_type: LOG_OP_TYPE_APP_START_COMPLETION,
            op_detail: {
              mode: initialModeCode,
            },
          });
        }
      });

      state.loading = false;
    },
    fetchStartDataFailed(state) {
      state.loading = false;
    },
    setOutOfStockItems(state, action: PayloadAction<string[]>) {
      const newOutOfStockItems = new Set([...state.outOfStockItems, ...action.payload]);
      state.outOfStockItems = Array.from(newOutOfStockItems);
    },
    addFavItem(state, action: PayloadAction<{ modeCode: string; poscd: string }>) {
      state.favItems = {
        ...state.favItems,
        [action.payload.modeCode]: state.favItems[action.payload.modeCode]
          ? state.favItems[action.payload.modeCode].concat([action.payload.poscd])
          : [action.payload.poscd],
      };
    },
    removeFavItem(state, action: PayloadAction<{ modeCode: string; poscd: string }>) {
      state.favItems = {
        ...state.favItems,
        [action.payload.modeCode]: state.favItems[action.payload.modeCode]?.filter(
          (item) => item !== action.payload.poscd
        ),
      };
    },
    setCurrentModeCode(state, action: PayloadAction<string>) {
      state.currentModeCode = action.payload;
      state.currentCategoryCode = CATEGORY_TOP_MENU_ID;
      state.campaignGenrecd = '';
      state.searchPOSCD = null;
    },
    setCurrentCategoryCode(state, action: PayloadAction<string>) {
      state.currentCategoryCode = action.payload;
      state.campaignGenrecd = '';
      state.searchPOSCD = null;
    },
    updateCampaign(state, action: PayloadAction<{ modeCode: string; categorycd: string; genrecd: string }>) {
      state.currentModeCode = action.payload.modeCode;
      state.currentCategoryCode = action.payload.categorycd;
      state.campaignGenrecd = action.payload.genrecd;
      state.searchPOSCD = null;
    },
    updateCampaignGenrecd(state, action: PayloadAction<string>) {
      state.campaignGenrecd = action.payload;
    },
    setSearchPOSCD(state, action: PayloadAction<string[] | null>) {
      state.currentCategoryCode = CATEGORY_SEARCH_MENU_ID;
      state.searchPOSCD = action.payload;
    },
    fetchOrderHistoryData(state) {
      state.fetchOrderHistoryLoading = true;
    },
    fetchOrderHistoryDataSuccess(state, action: PayloadAction<Payload>) {
      state.orderHistory = action.payload.result as HistoryOrderResult;
      if (checkObjectNotEmpty(state.menuInfo) && checkObjectNotEmpty(state.orderHistory.OrderDetail)) {
        state.historyFlag = checkHistoryFlag(
          state.menuInfo,
          state.thMenuInfo,
          state.phMenuInfo,
          state.orderHistory.OrderDetail
        );
      }
    },
    fetchOrderHistoryDataFailed(state) {
      state.fetchOrderHistoryLoading = false;
    },
    setHistoryFlag(state, action: PayloadAction<HistoryFlagType>) {
      state.historyFlag = action.payload;
    },
  },
});

// Actions
export const mainActions = mainSlice.actions;

// Selectors
export const selectMainLoading = (state: RootState) => state.main.loading;
export const selectMenuInfo = (state: RootState) => state.main.menuInfo;
export const selectFavItems = (state: RootState) => state.main.favItems;
export const selectFreqItems = (state: RootState) => state.main.freqItems;
export const selectOutOfStockItems = (state: RootState) => state.main.outOfStockItems;
export const selectHaveAdult = (state: RootState) => state.main.haveadult;

export const selectCurrentModeCode = (state: RootState) => state.main.currentModeCode;
export const selectCurrentCategoryCode = (state: RootState) => state.main.currentCategoryCode;
export const selectCampaignGenrecd = (state: RootState) => state.main.campaignGenrecd;
export const selectSearchPOSCD = (state: RootState) => state.main.searchPOSCD;

export const selectOrderHistoryData = (state: RootState) => state.main.orderHistory;
export const selectHistoryFlag = (state: RootState) => state.main.historyFlag;

export const selectBarInfo = (state: RootState) => state.main.barInfo;
export const selectBarMenu = (state: RootState) => state.main.barMenu;

// Reducer
export const mainReducer = mainSlice.reducer;
