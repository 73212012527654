/* eslint-disable react/react-in-jsx-scope */
interface AraMinusIconProps {
  width?: number | string;
  height?: number | string;
  color?: string;
}
export const AraMinusIcon = ({ width = 14, height = 4, color = '#F27900' }: AraMinusIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="14" y="0.5" width="3" height="14" rx="1.5" transform="rotate(90 14 0.5)" fill={color} />
    </svg>
  );
};
