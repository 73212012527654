import { useAppSelector } from 'app/hooks';
import AllYouCanEatBoard from 'components/AllYouCanEatBoard/AllYouCanEatBoard';
import PitaBoard from 'components/PitaBoard/PitaBoard';
import React, { useEffect } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { selectIsShowRemainAllYouCanEat } from 'store/allYouCanEatSlice';
import { selectCurrentCategoryCode } from 'store/mainSlice';
import { selectIsShowRemainPita } from 'store/pitaSlice';
import { AraLogoIcon } from 'theme/icons';
import { scrollAnimationTo } from 'utils/Utils';
import { CATEGORY_FREQUENTLY_ORDERED_MENU_ID, CATEGORY_SEARCH_MENU_ID } from '../../constants';
import CouponIcon from './components/CouponIcon';
import LanguageBox from './components/LanguageBox';
import './scss/Header.scss';

function Header() {
  const isShowRemainAllYouCanEat = useAppSelector(selectIsShowRemainAllYouCanEat);
  const isShowRemainPita = useAppSelector(selectIsShowRemainPita);
  const currentCategoryCode = useAppSelector(selectCurrentCategoryCode);

  const scrollTopMenuList = () => {
    if ([CATEGORY_SEARCH_MENU_ID, CATEGORY_FREQUENTLY_ORDERED_MENU_ID].includes(currentCategoryCode)) {
      scrollAnimationTo(document.querySelector('.page-wrapper') as HTMLElement, 0, 300);
    }
  };

  useEffect(() => {
    scrollTopMenuList();
  }, [currentCategoryCode]);

  return (
    <>
      <header className={`header-wrapper ${isShowRemainAllYouCanEat || isShowRemainPita ? 'all-you-can-eat' : ''}`}>
        <div id="top-section">
          <div className="header">
            <div className="d-flex align-items-center justify-content-between">
              <div className="col">
                <LanguageBox />
              </div>
              <div className="col text-center">
                <AraLogoIcon />
              </div>
              <div className="col d-flex align-items-center justify-content-end">
                <CouponIcon />
              </div>
            </div>
          </div>
        </div>
        {isShowRemainAllYouCanEat && <AllYouCanEatBoard />}
        {isShowRemainPita && <PitaBoard />}
      </header>
    </>
  );
}

export default React.memo(Header);
