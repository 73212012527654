import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { PortionStateType } from 'components/MenuDetail/types';
import { Payload } from 'types';
import { CartStateType } from '../pages/Cart/types';

export interface CartState {
  data: CartStateType;
}

const initialState: CartState = {
  data: {},
};

function getKeyValue(dict: Payload): [string, Payload] {
  // only for dict with a single pair of key, value
  const entry = Object.entries(dict)[0];
  const key = entry[0];
  const value = { ...entry[1] };
  return [key, value];
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    initData(state, action: PayloadAction<Payload>) {
      state.data = action.payload;
    },
    reset(state) {
      state.data = {};
    },
    add(state, action: PayloadAction<CartStateType>) {
      const [addedKey, addedValue] = getKeyValue(action.payload);
      let newDishValue;
      if (addedKey in state.data) {
        newDishValue = {
          init: addedValue.init,
          state: state.data[addedKey].state.concat(addedValue.state),
        };
      } else {
        newDishValue = {
          init: addedValue.init,
          state: addedValue.state,
        };
      }

      const newCartState = { ...state.data, [addedKey]: newDishValue };
      const filteredEmpty: CartStateType = {};
      Object.entries(newCartState).map(([key, value]) => {
        if (value.state.length > 0) filteredEmpty[key] = value;
      });

      state.data = filteredEmpty;
    },
    replace(state, action: PayloadAction<CartStateType>) {
      const addedState: CartStateType = action.payload;
      const newCartState = { ...state.data, ...addedState };
      const filteredEmpty: CartStateType = {};
      Object.entries(newCartState).map(([key, value]) => {
        if (value.state.length > 0) filteredEmpty[key] = value;
      });

      state.data = filteredEmpty;
    },
    increase(state, action: PayloadAction<{ key: string; payload: PortionStateType[] }>) {
      const dishKey = action.payload.key;
      const currentDishValue = state.data[dishKey];
      const currentDishState = currentDishValue.state;
      const newDishState = {
        ...currentDishValue,
        state: currentDishState.concat(action.payload.payload),
      };
      const newCartState = { ...state.data, [dishKey]: newDishState };

      const filteredEmpty: CartStateType = {};
      Object.entries(newCartState).map(([key, value]) => {
        if (value.state.length > 0) filteredEmpty[key] = value;
      });

      state.data = filteredEmpty;
    },
    decrease(state, action: PayloadAction<{ key: string; payload: PortionStateType }>) {
      let newCartState;

      const dishKey = action.payload.key;
      const currentDishValue = state.data[dishKey];
      const currentDishState = currentDishValue.state;
      if (currentDishState.length === 0) {
        newCartState = state.data;
      } else {
        const foundIndex = currentDishState.findIndex((dishState) => {
          return JSON.stringify(action.payload.payload) === JSON.stringify(dishState);
        });
        if (foundIndex >= 0) {
          currentDishState.splice(foundIndex, 1);
        }
        const newDishState = {
          ...currentDishValue,
          state: [...currentDishState],
        };
        newCartState = { ...state.data, [dishKey]: newDishState };

        const filteredEmpty: CartStateType = {};
        Object.entries(newCartState).map(([key, value]) => {
          if (value.state.length > 0) filteredEmpty[key] = value;
        });

        state.data = filteredEmpty;
      }
    },
  },
});

// Actions
export const cartActions = cartSlice.actions;

// Selectors
export const selectCartState = (state: RootState) => state.cart.data;

// Reducer
export const cartReducer = cartSlice.reducer;
