import { getCustomerQuantity } from 'apis/Api';
import { RES_CODE_SUCCESS } from '../constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Payload } from 'types';
import { customerQuantityActions } from './customerQuantitySlice';
import { handleHttpError, handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';

function* fetchCustomerQuantityData() {
  try {
    const response: Payload = yield call(getCustomerQuantity);
    if (response.code !== RES_CODE_SUCCESS) {
      yield handleResponseError(response);
    } else {
      yield put(customerQuantityActions.fetchCustomerQuantityDataSuccess(response));
    }
  } catch (e) {
    yield put(customerQuantityActions.fetchCustomerQuantityDataFailed());
    yield handleHttpError(e as AxiosError);
  }
}

export default function* customerQuantitySaga() {
  yield takeLatest(customerQuantityActions.fetchCustomerQuantityData.type, fetchCustomerQuantityData);
}
