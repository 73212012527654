import { useAppSelector } from 'app/hooks';
import Layout from 'components/Layout/Layout';
import ProtectedPage from 'components/ProtectedPage/ProtectedPage';
import Cart from 'pages/Cart/Cart';
import MainPage from 'pages/MainPage/MainPage';
import OrderHistory from 'pages/OrderHistory/OrderHistory';
import ThankPage from 'pages/ThankPage/ThankPage';
import { Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { selectIsShowRemainAllYouCanEat } from 'store/allYouCanEatSlice';
import { selectIsShowRemainPita } from 'store/pitaSlice';
import './scss/AnimatedRoutes.scss';

function AnimatedRoutes() {
  const location = useLocation();
  const isShowRemainAllYouCanEat = useAppSelector(selectIsShowRemainAllYouCanEat);
  const isShowRemainPita = useAppSelector(selectIsShowRemainPita);

  return (
    <TransitionGroup component={null} enter={location.pathname !== '/'}>
      <CSSTransition
        key={location.key}
        classNames={location.pathname !== '/' ? 'page-effect' : 'page-home-effect'}
        timeout={600}
        appear={true}
        unmountOnExit
        onExit={(node) => {
          node.style.position = 'fixed';
          node.style.overflow = 'hidden';
          node.style.top = -1 * window.scrollY + 'px';
        }}
      >
        <Routes location={location}>
          <Route
            path="/cart/"
            element={
              <div className={`page-wrapper cart`}>
                <ProtectedPage>
                  <Layout>
                    <Cart />
                  </Layout>
                </ProtectedPage>
              </div>
            }
          />
          <Route
            path="/order-history/"
            element={
              <div className={`page-wrapper history`}>
                <ProtectedPage>
                  <Layout>
                    <OrderHistory />
                  </Layout>
                </ProtectedPage>
              </div>
            }
          />
          <Route
            path="/thanks-page/"
            element={
              <div className={`page-wrapper`}>
                <ThankPage />
              </div>
            }
          />
          <Route
            path="/"
            element={
              <div className={`page-wrapper ${isShowRemainAllYouCanEat || isShowRemainPita ? 'all-you-can-eat' : ''}`}>
                <ProtectedPage>
                  <Layout>
                    <MainPage />
                  </Layout>
                </ProtectedPage>
              </div>
            }
          />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default AnimatedRoutes;
