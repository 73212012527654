import { useAppSelector } from 'app/hooks';
import { useMemo } from 'react';
import { selectThMenuInfo } from 'store/allYouCanEatSlice';
import { selectCurrentModeCode, selectMenuInfo } from 'store/mainSlice';
import { selectPhMenuInfo } from 'store/pitaSlice';
import { Swiper as SwiperCore } from 'swiper';
import { Payload } from 'types';
import { checkCategoryAvailable, getSourceMenuInfoByMode } from 'utils/Utils';
import {
  FREQUENTLY_ORDERED_MENU_MODEID,
  GRAND_MENU_MODEID,
  LUNCH_MENU_MODEID,
  SEARCH_MENU_MODEID,
} from '../../constants';
import CategoryThumbnail from './components/CategoryThumbnail';
import './scss/Category.scss';
export interface CategoryProps {
  mainSwiper?: SwiperCore;
  currentCategoryCodeAvailables: string[];
}

export default function Category({ mainSwiper, currentCategoryCodeAvailables }: CategoryProps) {
  const currentModeCode = useAppSelector(selectCurrentModeCode);
  const menuInfo = useAppSelector(selectMenuInfo);
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);
  const sourceMenuInfo = getSourceMenuInfoByMode(currentModeCode, menuInfo, thMenuInfo, phMenuInfo);
  const modeData: Payload = menuInfo.mode;
  const currentMode: Payload = modeData[currentModeCode];

  const currentCategoryCodes: string[] = useMemo(() => {
    if (currentModeCode === SEARCH_MENU_MODEID || currentModeCode === FREQUENTLY_ORDERED_MENU_MODEID) {
      return [];
    }

    return currentMode.category.filter((categoryCode: string) => {
      let isShow = true;
      if (currentModeCode === GRAND_MENU_MODEID || currentModeCode === LUNCH_MENU_MODEID) {
        isShow = checkCategoryAvailable(menuInfo, categoryCode);
      }

      return isShow;
    });
  }, [currentModeCode]);

  const ListOfCategories = currentCategoryCodes.map((categoryCode, index) => {
    const categoryInfo: Payload = sourceMenuInfo?.category[categoryCode];
    const availableIndex = currentCategoryCodeAvailables.findIndex(function (code) {
      return code === categoryCode;
    });

    return categoryInfo ? (
      <CategoryThumbnail
        key={`CategoryThumbnail-${currentModeCode}-${categoryCode}-${index}`}
        mainSwiper={mainSwiper}
        slideIndex={availableIndex}
        modeCode={currentModeCode}
        categoryCode={categoryCode}
        categoryInfo={categoryInfo}
      />
    ) : null;
  });

  return <div id="category-thumbnail-block">{ListOfCategories}</div>;
}
