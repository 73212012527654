import { useAppSelector } from 'app/hooks';
import { Collapse } from 'bootstrap';
import {
  log,
  LOG_OP_TYPE_MENU_DETAILS_DELETE_MULTIPLE_SUBMENUS,
  LOG_OP_TYPE_MENU_DETAILS_SWITCHING_BETWEEN_MULTIPLE_SUBMENUS,
} from 'log/Log';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectThMenuInfo } from 'store/allYouCanEatSlice';
import { selectMenuInfo } from 'store/mainSlice';
import { selectPhMenuInfo } from 'store/pitaSlice';
import { AraAngleDownIcon } from 'theme/icons';
import { Payload } from 'types';
import {
  attrLang,
  getCategoryCodeFromDishCode,
  getDishInfo,
  getSourceMenuInfo,
  getSourceMenuInfoByMode,
  summarizeOrderState,
} from 'utils/Utils';
import { dishDispatchContext } from '../DishModal';
import OptionGroup from './OptionGroup';
import SelectedOption from './SelectedOption';

export interface PortionProps {
  modeCode: string;
  itemCode: string;
  orderIdx: number;
  orderState: Payload;
  portionQuantity: number;
}

function Portion({ modeCode, itemCode, orderIdx, orderState, portionQuantity }: PortionProps) {
  const { t } = useTranslation();
  const dishDispatch = useContext(dishDispatchContext);
  const menuInfo = useAppSelector(selectMenuInfo);
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);
  const defaultRef = useRef<HTMLDivElement>(null);
  const selectedRef = useRef<HTMLDivElement>(null);
  const [collapseClass, setCollapseClass] = useState<string>('');

  const compactState = summarizeOrderState(orderState);
  const sourceDishMenuInfo = getSourceMenuInfoByMode(modeCode, menuInfo, thMenuInfo, phMenuInfo);
  const dishInfo = getDishInfo(sourceDishMenuInfo, itemCode);
  const categoryCode = getCategoryCodeFromDishCode(menuInfo, thMenuInfo, phMenuInfo, itemCode);

  const handleToggle = () => {
    if (defaultRef.current) {
      const defaultController = Collapse.getOrCreateInstance(defaultRef.current);
      defaultController.toggle();
      setCollapseClass((prevState) => (prevState === '' ? 'collapse' : ''));
    }
    if (selectedRef.current) {
      const selectedController = Collapse.getOrCreateInstance(selectedRef.current);
      selectedController.toggle();
    }
    if (portionQuantity > 1) {
      log({
        op_type: LOG_OP_TYPE_MENU_DETAILS_SWITCHING_BETWEEN_MULTIPLE_SUBMENUS,
        op_detail: {
          mode: modeCode,
          category: categoryCode,
          parent_poscd: itemCode,
          no: orderIdx + 1,
        },
      });
    }
  };

  const handleDelete = () => {
    dishDispatch({ type: 'delete-order', payload: orderIdx });
    if (portionQuantity > 1) {
      log({
        op_type: LOG_OP_TYPE_MENU_DETAILS_DELETE_MULTIPLE_SUBMENUS,
        op_detail: {
          mode: modeCode,
          category: categoryCode,
          parent_poscd: itemCode,
          no: orderIdx + 1,
        },
      });
    }
  };

  const ListOfSelectedOption = Object.entries(compactState).map(([optionCode, optionQuantity]) => {
    const sourceMenuInfo = getSourceMenuInfo(optionCode, menuInfo, thMenuInfo, phMenuInfo);
    const optionInfo = getDishInfo(sourceMenuInfo, optionCode);
    const optionName = attrLang(optionInfo, 'lang');
    return <SelectedOption key={optionCode} optionName={optionName} optionQuantity={optionQuantity} />;
  });

  const hasGlassGroup = Object.entries(orderState).some(([, groupState]) => {
    return groupState.basic.glassflg === '1';
  });

  const ListOfGroups: Array<JSX.Element | null> = [];
  if (dishInfo) {
    dishInfo.group.forEach((dishGroupCode: string) => {
      Object.entries(orderState).forEach(([groupCode, groupState]) => {
        if (dishGroupCode === groupCode) {
          if (
            groupState.basic.noselectflg === '1' ||
            groupState.basic.glassflg === '1' ||
            (hasGlassGroup && Object.keys(groupState.detail).length <= 1)
          ) {
            ListOfGroups.push(null);
          } else {
            ListOfGroups.push(
              <OptionGroup
                key={groupCode}
                modeCode={modeCode}
                itemCode={itemCode}
                orderIdx={orderIdx}
                groupState={groupState}
              />
            );
          }
        }
      });
    });
  }

  useEffect(() => {
    if (portionQuantity === 1) {
      setCollapseClass('');
      selectedRef.current?.classList.remove('show');
      if (!defaultRef.current?.classList.contains('show')) {
        defaultRef.current?.classList.add('show');
      }
    } else {
      if (collapseClass === 'collapse') {
        if (!selectedRef.current?.classList.contains('show')) {
          selectedRef.current?.classList.add('show');
        }
        defaultRef.current?.classList.remove('show');
      } else {
        selectedRef.current?.classList.remove('show');
        if (!defaultRef.current?.classList.contains('show')) {
          defaultRef.current?.classList.add('show');
        }
      }
    }
  }, [portionQuantity]);

  const groupHasValue = ListOfGroups.some((groupItem) => {
    return groupItem != null;
  });

  return groupHasValue ? (
    <div>
      {portionQuantity > 1 && ListOfGroups.length > 0 && (
        <div className={`collapse-button ${collapseClass}`} onClick={handleToggle}>
          <div className="order-name">
            <span>{`${t('MenuItemDetail.portion_name')}-${orderIdx + 1}`}</span>
            <span className="order-name-nav">
              <AraAngleDownIcon />
            </span>
          </div>
        </div>
      )}
      <div className="collapse" ref={selectedRef}>
        <div className="list-group-wrapper">
          <div className="selected-options mb-3">
            <ul className="list-option-group">{ListOfSelectedOption}</ul>
          </div>
          <button className="ara-btn ara-btn-outline delete-list-option-group w-100" onClick={handleDelete}>
            <div>{`${t('MenuItemDetail.portion_name')}${orderIdx + 1}${t('MenuItemDetail.delete_portion')}`}</div>
          </button>
        </div>
      </div>
      <div className="collapse show" ref={defaultRef}>
        <div className="list-group-wrapper">{ListOfGroups}</div>
      </div>
    </div>
  ) : null;
}

export default Portion;
