import { useAppSelector } from 'app/hooks';
import { log, LOG_OP_TYPE_FLOATING_WINDOW_CART_TRANSITION } from 'log/Log';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { playSound } from 'sound/Sound';
import { selectCartState } from 'store/cartSlice';
import { AraCartIcon } from 'theme/icons';
import { getTotalOrderItem, updateMainPageScrollTop } from 'utils/Utils';
import { TAP_ANIMATION_TIME } from '../../constants';
import './scss/ViewCartButton.scss';

export default function ViewCartButton() {
  const cartState = useAppSelector(selectCartState);
  const totalOrderItem = getTotalOrderItem(cartState);
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleViewCart = () => {
    // check button has disabled status, prevent click
    if (buttonRef.current) {
      if (buttonRef.current.disabled) {
        return;
      }
    }

    // change status of button to disabled
    if (buttonRef.current) buttonRef.current.disabled = true;

    setTimeout(() => {
      log({
        op_type: LOG_OP_TYPE_FLOATING_WINDOW_CART_TRANSITION,
        op_detail: {},
      });
      playSound('page');
      updateMainPageScrollTop();
      navigate('/cart', { replace: true });
    }, TAP_ANIMATION_TIME / 2);
  };

  return (
    <div className="cart-button">
      <button ref={buttonRef} onClick={totalOrderItem > 0 ? handleViewCart : undefined}>
        {totalOrderItem > 0 && <a className="cart-button-link"></a>}
        <div className="cart-button-wrapper">
          <div className="cart-button-icon">
            <AraCartIcon />
          </div>
          <div className="cart-button-text">{totalOrderItem}</div>
        </div>
      </button>
    </div>
  );
}
