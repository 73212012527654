const SessionStorage = {
  setItem: (key: string, value: string) => {
    window.sessionStorage.setItem(key, value);
  },

  getItem: (key: string): string | null => {
    return window.sessionStorage.getItem(key);
  },

  removeItem: (key: string) => {
    window.sessionStorage.removeItem(key);
  },

  clear: () => {
    window.sessionStorage.clear();
  },
};

export const SESSION_CURRENT_MODE_CODE = 'currentModeCode';
export const SESSION_CURRENT_CATEGROY_CODE = 'currentCategoryCode';
export const SESSION_CUSTOMER_ID_KEY = 'cid';
export const SESSION_CUSTOMER_TOKEN_KEY = 'token';
export const SESSION_MAIN_PAGE_LOADED = 'main_page_loaded';
export const SESSION_NOTIFY_AGE_LIMIT = 'notify_age_limit';
export const SESSION_SEARCH_VALUE = 'search_value';
export const SESSION_LANGUAGE_CODE = 'language_code';
export default SessionStorage;
