import { useAppDispatch } from 'app/hooks';
import { log, LOG_OP_TYPE_CATEGORY_SWITCHING } from 'log/Log';
import React, { useRef } from 'react';
import { mainActions } from 'store/mainSlice';
import SessionStorage, { SESSION_CURRENT_CATEGROY_CODE } from 'utils/sessionStorage';
import { stripHtml } from 'utils/Utils';
import './scss/CategoryButton.scss';

export interface CategoryButtonProps {
  isSearchMode: boolean;
  setIsSearchMode: (value: React.SetStateAction<boolean>) => void;
  modeCode: string;
  categoryCode: string;
  categoryName: string;
}

function CategoryButton({ isSearchMode, setIsSearchMode, modeCode, categoryCode, categoryName }: CategoryButtonProps) {
  const appDispatch = useAppDispatch();
  const buttonRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    //prevent event click again on itself
    if (!isSearchMode) {
      return;
    }

    // category is not available, prevent click
    if (buttonRef?.current?.parentElement?.classList.contains('disabled')) {
      return;
    }

    // save categoryCode to SessionStorage and main store
    appDispatch(mainActions.setCurrentCategoryCode(categoryCode));
    SessionStorage.setItem(SESSION_CURRENT_CATEGROY_CODE, categoryCode);

    setIsSearchMode(false);

    log({
      op_type: LOG_OP_TYPE_CATEGORY_SWITCHING,
      op_detail: {
        mode: modeCode,
        category: categoryCode,
      },
    });
  };

  return (
    <div id={`category-btn-${categoryCode}`} className={`category-button`} onClick={handleClick} ref={buttonRef}>
      {stripHtml(categoryName)}
    </div>
  );
}

export default React.memo(CategoryButton);
