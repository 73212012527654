import GenreMenu from 'components/GenreMenu/GenreMenu';
import React, { useEffect } from 'react';
import { Payload } from 'types';
import { getSourceMenuInfoByMode } from 'utils/Utils';
import {
  CATEGORY_FREQUENTLY_ORDERED_MENU_ID,
  CATEGORY_SEARCH_MENU_ID,
  GENRE_FREQUENTLY_ORDERED_MENU_ID,
  GENRE_SEARCH_MENU_ID,
} from '../../constants';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectThMenuInfo } from 'store/allYouCanEatSlice';
import { customerQuantityActions } from 'store/customerQuantitySlice';
import { selectFreqItems, selectMenuInfo } from 'store/mainSlice';
import { selectPhMenuInfo } from 'store/pitaSlice';
import './scss/MenuList.scss';

export interface MenuListProps {
  currentModeCode: string;
  categoryCode: string;
  searchPOSCD: string[] | null;
}

function MenuList({ currentModeCode, categoryCode, searchPOSCD }: MenuListProps) {
  const appDispatch = useAppDispatch();
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);
  const menuInfo = useAppSelector(selectMenuInfo);
  const sourceMenuInfo = getSourceMenuInfoByMode(currentModeCode, menuInfo, thMenuInfo, phMenuInfo);
  const freqItems = useAppSelector(selectFreqItems);
  const currentFreqMenu = freqItems[currentModeCode] || [];
  const couponCategory = menuInfo.category[categoryCode]?.categorynm === 'クーポン';

  let currentGenreMenu: Payload[] = [];
  switch (categoryCode) {
    case CATEGORY_FREQUENTLY_ORDERED_MENU_ID: {
      currentGenreMenu = [{ genrecd: GENRE_FREQUENTLY_ORDERED_MENU_ID, menu: currentFreqMenu }];
      break;
    }
    case CATEGORY_SEARCH_MENU_ID: {
      currentGenreMenu = [{ genrecd: GENRE_SEARCH_MENU_ID, menu: searchPOSCD }];
      break;
    }
    default: {
      currentGenreMenu = sourceMenuInfo?.menu_sub[categoryCode];
    }
  }

  useEffect(() => {
    if (couponCategory) {
      appDispatch(customerQuantityActions.fetchCustomerQuantityData());
    }
  }, [couponCategory]);

  const ListOfGenres =
    currentGenreMenu &&
    currentGenreMenu.map((genreInfo: Payload) => {
      return (
        <GenreMenu
          key={genreInfo.genrecd}
          currentModeCode={currentModeCode}
          categoryCode={categoryCode}
          genreCode={genreInfo.genrecd}
          genreSub={genreInfo}
        />
      );
    });

  return <div className="menu-list-item">{ListOfGenres}</div>;
}

export default React.memo(MenuList);
