import { getTimeFrame, updateFav } from 'apis/Api';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { AlertModalProps } from 'components/AlertModal/AlertModal';
import { GlobalModalContext } from 'components/GlobalModal';
import Thumbnail from 'components/Thumbnail/Thumbnail';
import {
  ADD_FAVORITES,
  CANCEL_FAVORITES,
  LOG_OP_TYPE_MENU_LIST_ADD_CANCEL_FAVORITES,
  LOG_OP_TYPE_MENU_LIST_MENU_SELECTION,
  log,
} from 'log/Log';
import { DishModalContext } from 'pages/MainPage/MainPage';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { playSound } from 'sound/Sound';
import { selectAllYouCanEatState, selectThCourseMenu, selectThMenuInfo } from 'store/allYouCanEatSlice';
import { selectCartState } from 'store/cartSlice';
import { selectCutomerQuantity } from 'store/customerQuantitySlice';
import {
  mainActions,
  selectFavItems,
  selectHaveAdult,
  selectHistoryFlag,
  selectMenuInfo,
  selectOrderHistoryData,
} from 'store/mainSlice';
import { selectPhCourseMenu, selectPhMenuInfo, selectPitaState } from 'store/pitaSlice';
import { selectTimeFrame, timeFrameActions } from 'store/timeFrameSlice';
import { Payload } from 'types';
import {
  attrLang,
  checkIsMember,
  countCouponUsed,
  dispatchErrorResponse,
  getErrorMessage,
  imgFullPath,
} from 'utils/Utils';
import SessionStorage, {
  SESSION_CURRENT_CATEGROY_CODE,
  SESSION_CURRENT_MODE_CODE,
  SESSION_NOTIFY_AGE_LIMIT,
} from 'utils/sessionStorage';
import {
  CATEGORY_TOP_MENU_ID,
  GRAND_MENU_MODEID,
  LUNCH_MENU_MODEID,
  MODAL_TYPES,
  RES_CODE_SUCCESS,
  TIME_FRAME_GRAND,
  TIME_FRAME_LUNCH,
} from '../../constants';
import './scss/MenuItemThumbnail.scss';

export interface MenuItemThumbnailProps {
  itemInfo: Payload;
  modeCode: string;
  categoryCode: string;
  disabled?: boolean;
  outOfStocked: boolean;
}

function MenuItemThumbnail({ itemInfo, modeCode, categoryCode, outOfStocked }: MenuItemThumbnailProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const appDispatch = useAppDispatch();

  // context
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();
  const historyFlag = useAppSelector(selectHistoryFlag);
  const timeFrame = useAppSelector(selectTimeFrame);
  const menuInfo = useAppSelector(selectMenuInfo);
  const orderHistory = useAppSelector(selectOrderHistoryData);
  const haveAdult = useAppSelector(selectHaveAdult);
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const thCourseMenu = useAppSelector(selectThCourseMenu);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);
  const phCourseMenu = useAppSelector(selectPhCourseMenu);
  const allYouCanEatState = useAppSelector(selectAllYouCanEatState);
  const pitaState = useAppSelector(selectPitaState);
  const cartState = useAppSelector(selectCartState);
  const customerQuantity = useAppSelector(selectCutomerQuantity);
  const { showDishModal } = useContext(DishModalContext);

  // state
  const [isLoading, setIsLoading] = useState(false);
  const favItems = useAppSelector(selectFavItems)[modeCode] || [];

  // compose data
  const itemName = attrLang(itemInfo, 'lang');
  const price = parseInt(itemInfo.price);
  const imgSrc = imgFullPath(itemInfo.img);
  const favored = favItems.includes(itemInfo.poscd);
  const favoredClass = favored ? 'active' : '';
  const message = getErrorMessage(
    modeCode,
    timeFrame,
    itemInfo,
    historyFlag,
    thMenuInfo,
    thCourseMenu,
    allYouCanEatState,
    phMenuInfo,
    phCourseMenu,
    pitaState,
    haveAdult,
    outOfStocked
  );
  const addedClass = message !== '' ? 'call-staff' : '';
  const isMember = checkIsMember();

  const handleClick = async () => {
    if (message !== '') return undefined;

    // check exceed coupon
    if (itemInfo.coupon_flg === '1') {
      const couponUsed = countCouponUsed(menuInfo, thMenuInfo, phMenuInfo, cartState, orderHistory.OrderDetail);
      const maxCouponQuantity = customerQuantity * 2;
      if (couponUsed >= maxCouponQuantity) {
        playSound('error');
        showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
          message: t('App.max_coupon_num_exceeded'),
        });
      }
    }

    // check menu in timeFrame lunch
    if (timeFrame === TIME_FRAME_LUNCH) {
      setIsLoading(true);
      const updatedTimeFrameRes = await getTimeFrame();
      setIsLoading(false);

      const updatedTimeFrame = parseInt(updatedTimeFrameRes.data.timeframe) || TIME_FRAME_GRAND;
      appDispatch(timeFrameActions.setTimeFrameData(updatedTimeFrameRes.data));
      const updatedMessage = getErrorMessage(
        modeCode,
        updatedTimeFrame,
        itemInfo,
        historyFlag,
        thMenuInfo,
        thCourseMenu,
        allYouCanEatState,
        phMenuInfo,
        phCourseMenu,
        pitaState,
        haveAdult,
        outOfStocked
      );
      if (updatedMessage !== '') {
        if (modeCode === LUNCH_MENU_MODEID) {
          // save modeCode and categoryCode to SessionStorage and main store
          appDispatch(mainActions.setCurrentModeCode(GRAND_MENU_MODEID));
          SessionStorage.setItem(SESSION_CURRENT_MODE_CODE, GRAND_MENU_MODEID);
          SessionStorage.setItem(SESSION_CURRENT_CATEGROY_CODE, CATEGORY_TOP_MENU_ID);
        }
        playSound('confirm');
        showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
          message: t('App.lunch_menu_unavailable'),
        });
        return;
      }
    }

    //check menu agelimit_flg=1
    if (itemInfo.agelimit_flg === '1') {
      if (sessionStorage.getItem(SESSION_NOTIFY_AGE_LIMIT) === null) {
        playSound('confirm');

        showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
          message: t('App.warning_age_limit'),
          actionClose: openMenuDetail,
        });

        sessionStorage.setItem(SESSION_NOTIFY_AGE_LIMIT, 'true');
        return;
      }
    }

    openMenuDetail();
  };

  const openMenuDetail = () => {
    log({
      op_type: LOG_OP_TYPE_MENU_LIST_MENU_SELECTION,
      op_detail: {
        mode: modeCode,
        category: categoryCode,
        poscd: itemInfo.poscd,
      },
    });

    showDishModal && showDishModal(itemInfo.poscd as string);
    playSound('page');
  };

  const handleFavor = async () => {
    const modifyDirection = favored ? 'remove' : 'add';
    if (modifyDirection === 'add') {
      appDispatch(
        mainActions.addFavItem({
          modeCode: modeCode,
          poscd: itemInfo.poscd,
        })
      );
    } else if (modifyDirection === 'remove') {
      appDispatch(
        mainActions.removeFavItem({
          modeCode: modeCode,
          poscd: itemInfo.poscd,
        })
      );
    }

    const response = await updateFav(itemInfo.poscd, modeCode, modifyDirection);
    if (response.code !== RES_CODE_SUCCESS) {
      dispatchErrorResponse(showGlobalModal, response);
    }

    log({
      op_type: LOG_OP_TYPE_MENU_LIST_ADD_CANCEL_FAVORITES,
      op_detail: {
        mode: modeCode,
        category: categoryCode,
        poscd: itemInfo.poscd,
        on_off: favored ? ADD_FAVORITES : CANCEL_FAVORITES,
      },
    });
  };

  const isShowMenu = message !== t('MenuList.lunch_time_only') && message !== t('MenuList.lunch_time_excluded');

  return isShowMenu ? (
    <>
      <div className={`menu-detail-thumbnail ${addedClass}`}>
        <Thumbnail
          modeCode={modeCode}
          name={itemName}
          imgSrc={imgSrc}
          handleClick={!isLoading ? handleClick : undefined}
          price={price}
          imageHeight={'26vw'}
          // lazy={![CATEGORY_FREQUENTLY_ORDERED_MENU_ID, CATEGORY_SEARCH_MENU_ID].includes(categoryCode)}
          lazy={true}
        />

        {isLoading && (
          <div className="message">
            <div className="message-content">
              <div className="spinner-border menu-detail-thumbnail-loading" role="status">
                <span className="visually-hidden">checking...</span>
              </div>
            </div>
          </div>
        )}

        {isMember && (
          <div className={`fav-icon-wrapper ${favoredClass}`} onClick={handleFavor}>
            <i className="bi bi-suit-heart"></i>
          </div>
        )}

        {message !== '' && (
          <div className="message">
            <div className="message-content">{message}</div>
          </div>
        )}
      </div>
    </>
  ) : (
    <></>
  );
}

export default React.memo(MenuItemThumbnail);
