import { PortionStateType } from 'components/MenuDetail/types';
import { Payload } from 'types';
import { changeQuantityOne, getSelectedQuantity, handleMultipleOption, handleSelectOne } from '../../utils/Utils';
import './scss/DishModal.scss';

export default function glassReducer(state: PortionStateType[], action: Payload): PortionStateType[] {
  let newState;
  switch (action.type) {
    case 'init': {
      const initState = action.payload.slice();
      return initState;
    }
    case 'delete-order': {
      newState = state.filter((order, idx) => idx !== action.payload);
      break;
    }
    case 'increase': {
      // newState = state.length === 9 ? state : state.concat(action.payload);
      newState = state.concat(action.payload);
      break;
    }
    case 'decrease': {
      newState = state.length === 0 ? state : state.slice(0, -1);
      break;
    }
    case 'quantity':
    case 'checkbox': {
      // check out Immer or immutability-helper if want to refactor the code bellow
      const orderIdx = parseInt(action.orderIdx);
      const groupCode: string = action.groupCode;
      const currentItemCode: string = action.itemCode;
      const currentOrderState = state[orderIdx];
      const currentGroupState = currentOrderState[groupCode];
      const currentGroupDetailState = currentGroupState.detail;
      const currentGroupBasicState = currentGroupState.basic;
      const kousenum = currentGroupBasicState.kousenum;
      const currentSelectedArray = currentGroupState.selected;
      const selectedStack = new Set(currentSelectedArray);
      const currentItemState = currentGroupDetailState[currentItemCode];
      const newGroupDetailState = {
        ...currentGroupDetailState,
      };
      switch (currentGroupBasicState.multipleflg) {
        case '0': {
          // can only select one option at a time
          switch (action.type) {
            case 'checkbox': {
              const targetChecked: boolean = action.payload;
              // when select an option, deselect the other options
              selectedStack.clear();
              if (targetChecked) selectedStack.add(currentItemCode);
              Object.entries(currentGroupDetailState).forEach(([optionCode, optionState]) => {
                const newOptionState = handleSelectOne(optionCode, optionState, currentItemCode, targetChecked);
                newGroupDetailState[optionCode] = newOptionState;
              });
              break;
            }
            case 'quantity': {
              const targetDirection: string = action.payload;
              // one option with many quantity
              const newItemState = changeQuantityOne(currentItemState, kousenum, targetDirection);
              newGroupDetailState[currentItemCode] = newItemState;
            }
          }
          break;
        }
        default: {
          // allow to choose many options
          selectedStack.delete(currentItemCode);
          if (action.payload) selectedStack.add(currentItemCode); // if check or modify quantity
          const newItemState = handleMultipleOption(currentItemState, action.type, action.payload, kousenum);

          if (action.countMenuItemInCart && action.editType && action.editType === 'replace') {
            newItemState.quantity = action.countMenuItemInCart;
          }

          newGroupDetailState[currentItemCode] = newItemState;

          const selectedQuantity = getSelectedQuantity(newGroupDetailState);
          if (selectedQuantity > kousenum) {
            // reduce or remove the first item in selectedStack
            const selectedArray = Array.from(selectedStack);
            const firstSelectedItemCode = selectedArray[0];
            const firstSelectedItemQuantity = newGroupDetailState[firstSelectedItemCode].quantity;

            if (firstSelectedItemQuantity === 1) {
              // remove the first item in stack
              selectedStack.delete(firstSelectedItemCode);
              newGroupDetailState[firstSelectedItemCode] = {
                checked: false,
                quantity: 1,
              };
            } else {
              // reduce the quantity of the first item in stack
              newGroupDetailState[firstSelectedItemCode] = {
                ['checked']: true,
                ['quantity']: firstSelectedItemQuantity - 1,
              };
            }
          }
        }
      }

      const newGroupState = {
        ...currentGroupState,
        ['detail']: newGroupDetailState,
        ['selected']: Array.from(selectedStack),
      };

      const newOrderState = {
        ...currentOrderState,
        [groupCode]: newGroupState,
      };
      newState = state.map((orderState, idx) => {
        return idx === orderIdx ? newOrderState : orderState;
      });
      break;
    }
    default:
      newState = { ...state };
  }
  return newState;
}
