import { callStaff, getTimeFrame, sendLog, sendOrder, updateCartInfo } from 'apis/Api';
import { CartInfoType } from 'apis/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { AlertModalProps } from 'components/AlertModal/AlertModal';
import DishPrice from 'components/DishPrice/DishPrice';
import { GlobalModalContext } from 'components/GlobalModal';
import { MenuBarOrderSuccessModalProps } from 'components/MenuBarOrderSuccessModal/MenuBarOrderSuccessModal';
import PageHeader from 'components/PageHeader/PageHeader';
import { LOG_OP_TYPE_CART_SCREEN_BACK, LOG_OP_TYPE_CART_SCREEN_SEND, log } from 'log/Log';
import { OrderAPIType } from 'pages/OrderHistory/types';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { playSound } from 'sound/Sound';
import { selectAllYouCanEatState, selectThCourseMenu, selectThMenuInfo } from 'store/allYouCanEatSlice';
import { cartActions, selectCartState } from 'store/cartSlice';
import {
  mainActions,
  selectBarInfo,
  selectBarMenu,
  selectHaveAdult,
  selectHistoryFlag,
  selectMenuInfo,
} from 'store/mainSlice';
import { selectPhCourseMenu, selectPhMenuInfo, selectPitaState } from 'store/pitaSlice';
import { timeFrameActions } from 'store/timeFrameSlice';
import {
  attrLang,
  checkInvalidItemInCart,
  checkServiceRequired,
  convertPortionStateToPortionInfo,
  dispatchErrorResponse,
  getDishCost,
  getDishInfo,
  getSourceMenuInfo,
  getSourceMenuInfoByMode,
  groupOrderBySimilarity,
  prepareDataToOrder,
} from 'utils/Utils';
import '../../components/PageCloseButton/scss/PageCloseButton.scss';
import {
  MODAL_TYPES,
  RES_CODE_EXCEED_COUPON_LIMIT,
  RES_CODE_OUT_OF_STOCK_ERROR,
  RES_CODE_SUCCESS,
  TAP_ANIMATION_TIME,
  TIME_FRAME_GRAND,
} from '../../constants';
import DishGroup from './components/DishGroup';
import './scss/Cart.scss';

export default function Cart() {
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();
  const appDispatch = useAppDispatch();
  const cartState = useAppSelector(selectCartState);
  const barInfo = useAppSelector(selectBarInfo);
  const barMenu = useAppSelector(selectBarMenu);
  const menuInfo = useAppSelector(selectMenuInfo);
  const historyFlag = useAppSelector(selectHistoryFlag);
  const haveAdult = useAppSelector(selectHaveAdult);
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const thCourseMenu = useAppSelector(selectThCourseMenu);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);
  const phCourseMenu = useAppSelector(selectPhCourseMenu);
  const allYouCanEatState = useAppSelector(selectAllYouCanEatState);
  const pitaState = useAppSelector(selectPitaState);
  const { t } = useTranslation();
  const navigate = useNavigate();
  let displayedPrice = 0;
  const buttonSubmitRef = useRef<HTMLButtonElement>(null);
  const buttonBackRef = useRef<HTMLButtonElement>(null);

  function getBarInfoItemInOrder(cartData: OrderAPIType) {
    const barCodes: number[] = [];

    cartData.order_info.forEach((orderInfo) => {
      if (barMenu[orderInfo.MenuCode]) {
        const barCode = Number(barMenu[orderInfo.MenuCode].bar_code);
        if (barInfo[barCode] && !barCodes.includes(barCode)) {
          barCodes.push(barCode);
        }
      }

      orderInfo.MenuInfo?.forEach(function (menuInfo) {
        if (barMenu[menuInfo.MenuCode]) {
          const barCode = Number(barMenu[menuInfo.MenuCode].bar_code);
          if (barInfo[barCode] && !barCodes.includes(barCode)) {
            barCodes.push(barCode);
          }
        }
      });
    });

    if (barCodes.length === 0) {
      return undefined;
    }

    // combined barCodes
    const combinedBarCode = barCodes.reduce(function (a, b) {
      return a | b;
    }, 0);

    if (barInfo[combinedBarCode]) {
      return barInfo[combinedBarCode];
    }

    return undefined;
  }

  const handleOrder = () => {
    // check button has disabled status, prevent click
    if (buttonSubmitRef.current) {
      if (buttonSubmitRef.current.disabled) {
        return;
      }
    }

    // change status of button to disabled
    if (buttonSubmitRef.current) buttonSubmitRef.current.disabled = true;

    setTimeout(async () => {
      playSound('order');
      const updatedTimeFrameRes = await getTimeFrame();
      appDispatch(timeFrameActions.setTimeFrameData(updatedTimeFrameRes.data));

      const updatedTimeFrame = parseInt(updatedTimeFrameRes.data.timeframe) || TIME_FRAME_GRAND;

      const { name: invalidItemName, message: errorMessage } = checkInvalidItemInCart(
        menuInfo,
        cartState,
        updatedTimeFrame,
        historyFlag,
        thMenuInfo,
        thCourseMenu,
        allYouCanEatState,
        haveAdult,
        phMenuInfo,
        phCourseMenu,
        pitaState
      );
      if (invalidItemName !== '') {
        // warn user about invalid item in cart
        showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
          message: `${t('App.invalid_item_exist')}: ${errorMessage} (${invalidItemName})`,
        });
        if (buttonSubmitRef.current) buttonSubmitRef.current.disabled = false;
        return undefined;
      }

      const cartData = prepareDataToOrder(cartState, menuInfo, thMenuInfo, phMenuInfo);

      sendOrder(cartData)
        // send order through API, set alert content, reset cart and update order history.
        .then((response) => {
          log({
            op_type: LOG_OP_TYPE_CART_SCREEN_SEND,
            op_detail: {},
          });

          sendLog();

          if (response.data.code !== RES_CODE_SUCCESS) {
            if (buttonSubmitRef.current) buttonSubmitRef.current.disabled = false;
            if (response.data.code.slice(2, 4) === RES_CODE_OUT_OF_STOCK_ERROR) {
              const outOfStockItems: string[] = response.data.result.additionInfo;
              const itemNames: string[] = [];
              if (outOfStockItems?.length > 0) {
                outOfStockItems.forEach((itemCode) => {
                  const sourceMenuInfo = getSourceMenuInfo(itemCode, menuInfo, thMenuInfo, phMenuInfo);
                  const itemInfo = getDishInfo(sourceMenuInfo, itemCode);
                  const itemName = attrLang(itemInfo, 'lang');
                  itemName !== '' && itemNames.push(itemName);
                });
                appDispatch(mainActions.setOutOfStockItems(outOfStockItems));
              }
              playSound('error');
              showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
                message: `${t('Cart.out_of_stock')} ${itemNames.length > 0 ? itemNames.join(', ') : ''}`,
              });
              appDispatch(cartActions.reset());

              const serviceRequiredItems = checkServiceRequired(menuInfo, thMenuInfo, phMenuInfo, cartState);
              const availableServiceRequiredItems = serviceRequiredItems.filter((poscd) => !(poscd in outOfStockItems));
              if (availableServiceRequiredItems.length > 0) {
                callStaff(availableServiceRequiredItems);
              }
            } else if (response.data.code === RES_CODE_EXCEED_COUPON_LIMIT) {
              playSound('error');
              showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
                message: t('Cart.exceed_coupon_limit'),
              });
            } else {
              //handle error from response API
              dispatchErrorResponse(showGlobalModal, response.data, response.data.result.message);
            }
          } else {
            //TODO: check bar_info, bar_menu
            const barInfo = getBarInfoItemInOrder(cartData);
            if (barInfo) {
              showGlobalModal<MenuBarOrderSuccessModalProps>(MODAL_TYPES.MENU_BAR_ORDER_SUCCESS_MODAL, {
                barInfo: barInfo,
                onClose: () => navigate(-1),
              });
            } else {
              showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
                message: t('Cart.order_success'),
                actionClose: () => navigate(-1),
              });
            }

            if (buttonSubmitRef.current) buttonSubmitRef.current.disabled = false;
            appDispatch(cartActions.reset());

            const serviceRequiredItems = checkServiceRequired(menuInfo, thMenuInfo, phMenuInfo, cartState);
            if (serviceRequiredItems.length > 0) {
              callStaff(serviceRequiredItems);
            }
          }
        })
        .catch((err) => {
          showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
            message: t('Cart.order_fail'),
          });
          console.error(err);
        });
    }, TAP_ANIMATION_TIME / 2);
  };

  const handleGoBack = () => {
    // check button has disabled status, prevent click
    if (buttonBackRef.current) {
      if (buttonBackRef.current.disabled) {
        return;
      }
    }

    // change status of button to disabled
    if (buttonBackRef.current) buttonBackRef.current.disabled = true;

    log({
      op_type: LOG_OP_TYPE_CART_SCREEN_BACK,
      op_detail: {},
    });
    playSound('page');
    navigate(-1);
  };

  useEffect(() => {
    // close modal when no item in shopping cart
    if (Object.entries(cartState).length == 0) {
      setTimeout(() => {
        if (!document.querySelector('.alert-modal')) {
          navigate(-1);
        }
      }, 100);
    } else {
      // update cartInfo in backend
      const orderList: CartInfoType[] = [];
      Object.entries(cartState).forEach(([dishKey, dishValue]) => {
        const mapUniquePortionQuantity = groupOrderBySimilarity(dishValue.state);
        Object.entries(mapUniquePortionQuantity).forEach(([portionStateString, portionQuantity]) => {
          const portionState = JSON.parse(portionStateString);
          const portionInfo = convertPortionStateToPortionInfo(dishKey, portionState, portionQuantity);
          orderList.push(portionInfo);
        });
      });
      updateCartInfo(orderList);
    }
  }, [cartState]);

  // use Effects to check if body tag has full height class
  useEffect(() => {
    const body = document.getElementsByTagName('BODY')[0];
    if (!body.classList.contains('full-height')) {
      body.classList.add('full-height');
      window.scrollTo(0, 0);
    }

    return () => {
      if (body.classList.contains('full-height')) {
        body.classList.remove('full-height');
      }
    };
  }, []);

  const ListOfGroup = Object.entries(cartState).map(([dishKey, dishValue]) => {
    const [modeCode, dishCode] = JSON.parse(dishKey);
    const sourceMenuInfo = getSourceMenuInfoByMode(modeCode, menuInfo, thMenuInfo, phMenuInfo);
    displayedPrice = displayedPrice + getDishCost(dishValue.state, sourceMenuInfo, dishCode);
    return <DishGroup key={dishKey} dishKey={dishKey} dishValue={dishValue} />;
  });

  return (
    <div id="cart">
      <PageHeader handleGoBack={handleGoBack} />
      <div id="dishes" className="cart-body">
        {ListOfGroup}
      </div>
      <div className="cart-footer">
        <div className="row g-0 cart-price">
          <div className="col-3 total-price-text">{t('OrderHistory.total_price')}</div>
          <div className="col total-price">
            <DishPrice price={displayedPrice} />
          </div>
        </div>
        <div className="row g-0">
          <div className="col button-cart-back">
            <button className="ara-btn ara-btn-outline single" ref={buttonBackRef} onClick={handleGoBack}>
              <div>{t('Cart.go_back')}</div>
            </button>
          </div>
          <div className="col button-cart-order">
            <button className="ara-btn ara-btn-primary single" ref={buttonSubmitRef} onClick={handleOrder}>
              <div>{t('Cart.order')}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
