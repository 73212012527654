/* eslint-disable react/react-in-jsx-scope */
interface AraTicketIconProps {
  size?: number | string;
}
export const AraTicketIcon = ({ size = 24 }: AraTicketIconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_3476_4558" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 5C1 4.44772 1.44772 4 2 4H22C22.5523 4 23 4.44772 23 5V8.7326C23 9.20821 22.6503 9.60031 22.2398 9.84041C21.4982 10.2741 21 11.0789 21 12C21 12.9211 21.4982 13.7259 22.2398 14.1596C22.6503 14.3997 23 14.7918 23 15.2674V19C23 19.5523 22.5523 20 22 20H2C1.44772 20 1 19.5523 1 19V15.2674C1 14.7918 1.34969 14.3997 1.76024 14.1596C2.50184 13.7259 3 12.9211 3 12C3 11.0789 2.50184 10.2741 1.76024 9.84041C1.34969 9.60031 1 9.2082 1 8.7326V5Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 5C1 4.44772 1.44772 4 2 4H22C22.5523 4 23 4.44772 23 5V8.7326C23 9.20821 22.6503 9.60031 22.2398 9.84041C21.4982 10.2741 21 11.0789 21 12C21 12.9211 21.4982 13.7259 22.2398 14.1596C22.6503 14.3997 23 14.7918 23 15.2674V19C23 19.5523 22.5523 20 22 20H2C1.44772 20 1 19.5523 1 19V15.2674C1 14.7918 1.34969 14.3997 1.76024 14.1596C2.50184 13.7259 3 12.9211 3 12C3 11.0789 2.50184 10.2741 1.76024 9.84041C1.34969 9.60031 1 9.2082 1 8.7326V5Z"
        fill="#FFEF40"
      />
      <path
        d="M1.76024 14.1596L0.750587 12.4331L1.76024 14.1596ZM2 2C0.343146 2 -1 3.34315 -1 5H3C3 5.55228 2.55228 6 2 6V2ZM22 2H2V6H22V2ZM25 5C25 3.34315 23.6569 2 22 2V6C21.4477 6 21 5.55229 21 5H25ZM25 8.7326V5H21V8.7326H25ZM23 12C23 11.8183 23.0959 11.6566 23.2494 11.5669L21.2301 8.11396C19.9005 8.89157 19 10.3395 19 12H23ZM23.2494 12.4331C23.0959 12.3434 23 12.1817 23 12H19C19 13.6605 19.9005 15.1084 21.2301 15.886L23.2494 12.4331ZM25 19V15.2674H21V19H25ZM22 22C23.6569 22 25 20.6569 25 19H21C21 18.4477 21.4477 18 22 18V22ZM2 22H22V18H2V22ZM-1 19C-1 20.6569 0.343148 22 2 22V18C2.55228 18 3 18.4477 3 19H-1ZM-1 15.2674V19H3V15.2674H-1ZM1 12C1 12.1817 0.904132 12.3434 0.750587 12.4331L2.76988 15.886C4.09955 15.1084 5 13.6605 5 12H1ZM0.750588 11.5669C0.904132 11.6566 1 11.8183 1 12H5C5 10.3395 4.09955 8.89157 2.76988 8.11396L0.750588 11.5669ZM-1 5V8.7326H3V5H-1ZM2.76988 8.11396C2.74769 8.10098 2.78011 8.11251 2.83385 8.18515C2.8949 8.26764 3 8.45801 3 8.7326H-1C-1 10.2392 0.0657227 11.1663 0.750588 11.5669L2.76988 8.11396ZM3 15.2674C3 15.542 2.8949 15.7324 2.83385 15.8149C2.78011 15.8875 2.74769 15.899 2.76988 15.886L0.750587 12.4331C0.0657245 12.8337 -1 13.7608 -1 15.2674H3ZM21.2301 15.886C21.2523 15.899 21.2199 15.8875 21.1661 15.8149C21.1051 15.7324 21 15.542 21 15.2674H25C25 13.7608 23.9343 12.8337 23.2494 12.4331L21.2301 15.886ZM21 8.7326C21 8.45801 21.1051 8.26764 21.1661 8.18515C21.2199 8.11251 21.2523 8.10098 21.2301 8.11396L23.2494 11.5669C23.9343 11.1663 25 10.2392 25 8.7326H21Z"
        fill="#F27900"
        mask="url(#path-1-inside-1_3476_4558)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 6.5C16.1642 6.5 16.5 6.83579 16.5 7.25L16.5 8.75C16.5 9.16421 16.1642 9.5 15.75 9.5C15.3358 9.5 15 9.16421 15 8.75V7.25C15 6.83579 15.3358 6.5 15.75 6.5ZM15.75 10.5C16.1642 10.5 16.5 10.8358 16.5 11.25L16.5 12.75C16.5 13.1642 16.1642 13.5 15.75 13.5C15.3358 13.5 15 13.1642 15 12.75V11.25C15 10.8358 15.3358 10.5 15.75 10.5ZM16.5 15.25C16.5 14.8358 16.1642 14.5 15.75 14.5C15.3358 14.5 15 14.8358 15 15.25V16.75C15 17.1642 15.3358 17.5 15.75 17.5C16.1642 17.5 16.5 17.1642 16.5 16.75L16.5 15.25Z"
        fill="#F27900"
      />
    </svg>
  );
};
