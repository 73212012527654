import { useAppDispatch, useAppSelector } from 'app/hooks';
import { AlertModalProps } from 'components/AlertModal/AlertModal';
import { ConfirmModalProps } from 'components/ComfirmModal/ConfirmModal';
import DishPrice from 'components/DishPrice/DishPrice';
import { GlobalModalContext } from 'components/GlobalModal';
import { DishOptionGroupType, PortionStateType } from 'components/MenuDetail/types';
import PortionQuantitySelector from 'components/PortionQuantitySelector/PortionQuantitySelector';
import {
  LOG_OP_TYPE_CART_SCREEN_QUANTITY_CHANGE,
  LOG_OP_TYPE_MENU_DETAILS_ADDITIONAL_CART,
  LOG_OP_TYPE_MENU_DETAILS_CLOSE,
  log,
} from 'log/Log';
import { CartStateType } from 'pages/Cart/types';
import React, { Dispatch, createContext, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useNavigate } from 'react-router-dom';
import { playSound } from 'sound/Sound';
import { selectIsShowRemainAllYouCanEat, selectThMenuInfo } from 'store/allYouCanEatSlice';
import { cartActions, selectCartState } from 'store/cartSlice';
import { selectCutomerQuantity } from 'store/customerQuantitySlice';
import { selectMenuInfo, selectOrderHistoryData } from 'store/mainSlice';
import { selectIsShowRemainPita, selectPhMenuInfo } from 'store/pitaSlice';
import { AraTimesIcon } from 'theme/icons';
import { Payload } from 'types';
import { GRAND_MENU_MODEID, LUNCH_MENU_MODEID, MODAL_TYPES, TAP_ANIMATION_TIME } from '../../constants';
import {
  attrLang,
  checkIsExceedAllowQuantityOrder,
  countCouponUsed,
  countMenuItemInCart,
  getCategoryCodeFromDishCode,
  getDishCost,
  getDishInfo,
  getModeCodeFromDishInfo,
  getSourceMenuInfo,
  getSourceMenuInfoByMode,
  imgFullPath,
  isMenuOfPita,
  isMenuOfYouCanEat,
  scrollAnimationTo,
  stripHtml,
  updateMainPageScrollTop,
} from '../../utils/Utils';
import GlassPortion from './components/GlassPortion';
import Portion from './components/Portion';
import glassReducer from './glassreducer';
import dishReducer from './reducer';
import './scss/DishModal.scss';

export const dishDispatchContext = createContext<Dispatch<Payload>>(() => undefined);
export const glassDispatchContext = createContext<Dispatch<Payload>>(() => undefined);

function initGlassState(
  cartState: CartStateType,
  sourceMenuInfo: Payload | null,
  dishCode: string,
  glassMenuCodes: string[]
): PortionStateType[] {
  const itemInfo = getDishInfo(sourceMenuInfo, dishCode);
  const optionGroupData: Payload = sourceMenuInfo?.group;
  const groupInfoData: Payload = sourceMenuInfo?.group_menu;
  const groupCodes: string[] = (itemInfo && itemInfo.group) || [];
  const initOrderState: Payload = {};

  const validGroupCodes = groupCodes.filter(
    (groupCode) => groupCode in optionGroupData && groupCode in groupInfoData && 'lang' in optionGroupData[groupCode]
  );
  validGroupCodes.forEach((groupCode) => {
    const groupInfo = optionGroupData[groupCode];
    const groupDetailInfo: Payload = groupInfoData[groupCode];
    const optionGroupDetail: Payload = {};
    const noselectflg: string = optionGroupData[groupCode].noselectflg;

    Object.values(groupDetailInfo).forEach((menuItem) => {
      const optionCode: string = menuItem.poscd;
      const defaultFlg: string = menuItem.defaultflg;

      if (glassMenuCodes.includes(optionCode)) {
        const countGlassMenuInCart = countMenuItemInCart(cartState, optionCode);

        const initOptionState = {
          checked: countGlassMenuInCart >= 0 ? true : defaultFlg === '1' || noselectflg === '1' ? true : false,
          quantity: countGlassMenuInCart,
        };
        optionGroupDetail[optionCode] = initOptionState;
      }
    });

    const optionGroup: DishOptionGroupType = {
      basic: groupInfo,
      detail: optionGroupDetail,
      selected: glassMenuCodes,
    };
    initOrderState[groupCode] = optionGroup;
  });

  return [initOrderState];
}

export interface DishModalProps {
  open?: boolean;
  type: 'add' | 'replace';
  modeCode: string;
  itemCode: string;
  initState: PortionStateType[];
  addedState: PortionStateType;
  onClose?: () => void;
}

export default function DishModal({
  open = false,
  type,
  modeCode,
  itemCode,
  // modalRef,
  initState,
  addedState,
  onClose,
}: DishModalProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();

  // context
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();
  const cartState = useAppSelector(selectCartState);
  const menuInfo = useAppSelector(selectMenuInfo);
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);
  const appDispatch = useAppDispatch();
  const isShowRemainAllYouCanEat = useAppSelector(selectIsShowRemainAllYouCanEat);
  const isShowRemainPita = useAppSelector(selectIsShowRemainPita);
  const orderHistory = useAppSelector(selectOrderHistoryData);
  const customerQuantity = useAppSelector(selectCutomerQuantity);
  const buttonAddCartRef = useRef<HTMLButtonElement>(null);

  // hooks
  const navigate = useNavigate();

  const sourceMenuInfo = getSourceMenuInfoByMode(modeCode, menuInfo, thMenuInfo, phMenuInfo);
  const [dishState, dishDispatcher] = useReducer(dishReducer, initState);
  const itemInfo = getDishInfo(sourceMenuInfo, itemCode);
  const itemName = attrLang(itemInfo, 'lang');
  const imgSrc = imgFullPath(itemInfo?.img);
  const itemDesc = attrLang(itemInfo, 'desp');
  const basePrice = parseInt(itemInfo?.price);
  const totCost: number = getDishCost(dishState, sourceMenuInfo, itemCode);
  const itemQuantity = dishState.length;

  // found group has glass menu item
  let firstGlassGroup: Payload | undefined = undefined;
  let glassMenuItemCodes: string[] = [];
  if (itemInfo?.group) {
    (itemInfo.group as Array<string>).forEach((groupCode) => {
      if (sourceMenuInfo?.group[groupCode]) {
        if (sourceMenuInfo?.group[groupCode].glassflg === '1') {
          firstGlassGroup = sourceMenuInfo?.group[groupCode];
        }
      }
    });

    if (firstGlassGroup) {
      //step2: get poscd
      glassMenuItemCodes = Object.keys(sourceMenuInfo?.group_menu[firstGlassGroup['gno']]);
    }
  }

  const initGlassStateValue = React.useMemo(
    () => initGlassState(cartState, sourceMenuInfo, itemCode, glassMenuItemCodes),
    [cartState, itemCode]
  );

  const [glassState, glassDispatcher] = useReducer(glassReducer, initGlassStateValue);

  useEffect(() => {
    // update init value when in cart
    dishDispatcher({
      type: 'init',
      payload: initState,
    });
  }, [initState]);

  useEffect(() => {
    // update init value when in cart
    glassDispatcher({
      type: 'init',
      payload: initGlassStateValue,
    });
  }, [initGlassStateValue]);

  const ListOfOrder = dishState.map((orderState, orderIdx) => {
    return (
      <Portion
        key={orderIdx}
        modeCode={modeCode}
        itemCode={itemCode}
        orderIdx={orderIdx}
        orderState={orderState}
        portionQuantity={dishState.length}
      />
    );
  });

  const ListOfGlassOrder = [glassState[0]].map((orderState, orderIdx) => {
    return (
      <GlassPortion
        type={type}
        modeCode={modeCode}
        itemCode={itemCode}
        key={orderIdx}
        orderIdx={orderIdx}
        orderState={orderState}
      />
    );
  });

  const resetDishModal = () => {
    dishDispatcher({ type: 'init', payload: initState });
    glassDispatcher({ type: 'init', payload: initGlassStateValue });
    onClose && onClose();
  };

  const hasConfirmMenuBeforeAddToCart = () => {
    // still in all-you-can-eat time and not menu of all-you-can-eat --> show dialog to confirm
    if (isShowRemainAllYouCanEat) {
      if (!isMenuOfYouCanEat(thMenuInfo, itemCode)) {
        playSound('confirm');
        showGlobalModal<ConfirmModalProps>(MODAL_TYPES.CONFIRM_MODAL, {
          message: t('MenuItemDetail.pay_menu_in_you_can_eat_time_confirm_message'),
          actionClose: () => {
            if (buttonAddCartRef.current) buttonAddCartRef.current.disabled = false;
          },
          actionAgree: addToCartAction,
        });
        return true;
      } else {
        // check price > 0
        if (parseInt(itemInfo?.price || 0) > 0) {
          playSound('confirm');
          showGlobalModal<ConfirmModalProps>(MODAL_TYPES.CONFIRM_MODAL, {
            message: t('AllYouCanEat.warning_paid_menu'),
            actionClose: () => {
              if (buttonAddCartRef.current) buttonAddCartRef.current.disabled = false;
            },
            actionAgree: addToCartAction,
          });
          return true;
        }
      }
    }

    // still in pita time and not menu of pita --> show dialog to confirm
    if (isShowRemainPita) {
      if (!isMenuOfPita(phMenuInfo, itemCode)) {
        playSound('confirm');
        showGlobalModal<ConfirmModalProps>(MODAL_TYPES.CONFIRM_MODAL, {
          message: t('MenuItemDetail.pay_menu_in_you_can_eat_time_confirm_message'),
          actionClose: () => {
            if (buttonAddCartRef.current) buttonAddCartRef.current.disabled = false;
          },
          actionAgree: addToCartAction,
        });
        return true;
      }
    }

    return false;
  };

  const handleAddCart = () => {
    // check button has disabled status, prevent click
    if (buttonAddCartRef.current) {
      if (buttonAddCartRef.current.disabled) {
        return;
      }
    }

    // change status of button to disabled
    if (buttonAddCartRef.current) buttonAddCartRef.current.disabled = true;

    setTimeout(() => {
      playSound('add');

      //check exceed coupon
      if (itemInfo?.coupon_flg === '1') {
        const maxCouponQuantity = customerQuantity * 2;
        const usedCouponQuantity = countCouponUsed(
          menuInfo,
          thMenuInfo,
          phMenuInfo,
          cartState,
          orderHistory.OrderDetail
        );
        if (usedCouponQuantity + 1 > maxCouponQuantity) {
          playSound('error');
          showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
            message: t('App.max_coupon_num_exceeded'),
            actionClose: () => {
              if (buttonAddCartRef.current) buttonAddCartRef.current.disabled = false;
            },
          });
          return;
        }
      }

      //check quantity exceed
      const isExceedAllowQuantityOrder = checkIsExceedAllowQuantityOrder(
        modeCode,
        cartState,
        thMenuInfo,
        1,
        customerQuantity
      );
      if (isExceedAllowQuantityOrder) {
        showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
          message: t('MenuItemDetail.exceed_quantity_warning'),
          actionClose: () => {
            if (buttonAddCartRef.current) buttonAddCartRef.current.disabled = false;
          },
        });
        return;
      }

      const messageContent = t('MenuItemDetail.select_more_items_message');
      const warningMessage = new Set();
      let warningGroupId = '';
      // check glass option menu required (glassState)
      Object.entries(glassState).forEach(([, orderItem]) => {
        Object.entries(orderItem).forEach(([, itemGroup]) => {
          if (itemGroup.basic.hisuflg === '1' && itemGroup.basic.glassflg === '1') {
            const kousenum = parseInt(itemGroup.basic.kousenum);
            if (kousenum === 1) {
              if (itemGroup.selected.length !== kousenum) {
                warningMessage.add(messageContent.replace('{0}', attrLang(itemGroup.basic, 'lang')).replace('{1}', ''));
              }
            }
          }
        });
      });

      // check option menu required (dishState)
      Object.entries(dishState).forEach(([orderIdx, orderItem]) => {
        //for ordering group
        itemInfo?.group.forEach((dishGroupCode: string) => {
          Object.entries(orderItem).forEach(([itemGroupIdx, itemGroup]) => {
            if (
              dishGroupCode === itemGroup.basic.gno &&
              itemGroup.basic.hisuflg === '1' &&
              itemGroup.basic.glassflg === '0'
            ) {
              const kousenum = parseInt(itemGroup.basic.kousenum);
              if (kousenum === 1) {
                if (itemGroup.selected.length !== kousenum) {
                  warningMessage.add(
                    messageContent.replace('{0}', attrLang(itemGroup.basic, 'lang')).replace('{1}', '')
                  );
                  if (warningGroupId === '') {
                    warningGroupId = `option-group-${itemCode}-${orderIdx}-${itemGroupIdx}`;
                  }
                }
              } else if (kousenum > 1) {
                let totalItem = 0;
                Object.entries(itemGroup.detail).forEach(([, optionType]) => {
                  if (optionType.checked) totalItem += optionType.quantity;
                });
                if (totalItem != kousenum) {
                  warningMessage.add(
                    messageContent
                      .replace('{0}', attrLang(itemGroup.basic, 'lang'))
                      .replace('{1}', kousenum.toString() + t('MenuItemDetail.kind'))
                  );
                  if (warningGroupId === '') {
                    warningGroupId = `option-group-${itemCode}-${orderIdx}-${itemGroupIdx}`;
                  }
                }
              }
            }
          });
        });
      });

      if (warningMessage.size > 0) {
        showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
          message: Array.from(warningMessage).join(''),
          actionClose: () => {
            if (buttonAddCartRef.current) buttonAddCartRef.current.disabled = false;
          },
        });
        if (warningGroupId !== '') {
          const optionGroupEl = document.getElementById(warningGroupId);
          if (optionGroupEl) {
            const groupParent = optionGroupEl.parentElement?.parentElement;
            let timeDelay = 0;
            if (!groupParent?.classList.contains('show')) {
              const orderNameEl =
                optionGroupEl.parentElement?.parentElement?.parentElement?.querySelector('.order-name')?.parentElement;
              if (orderNameEl) {
                (orderNameEl as HTMLElement).click();
                timeDelay = 400;
              }
            }
            setTimeout(() => {
              const scrolltopElem = optionGroupEl.offsetTop - optionGroupEl.scrollTop;
              scrollAnimationTo(document.querySelector('.dish-modal .modal-body') as HTMLElement, scrolltopElem, 300);
            }, timeDelay);
          }
        }
        return;
      }

      const hasConfirm = hasConfirmMenuBeforeAddToCart();
      if (!hasConfirm) {
        addToCartAction();
      }
    }, TAP_ANIMATION_TIME / 2);
  };

  const addToCartAction = () => {
    const dishKey = JSON.stringify([modeCode, itemCode]);
    const dishData: CartStateType = {
      [dishKey]: {
        state: dishState,
        init: addedState,
      },
    };
    const currentQuantityOfMenuItem = countMenuItemInCart(cartState, itemCode);
    if (type === 'add') {
      appDispatch(cartActions.add(dishData));
    } else if (type === 'replace') {
      appDispatch(cartActions.replace(dishData));
    }

    //add glass menu item to cart
    if (firstGlassGroup && glassMenuItemCodes.length > 0) {
      glassMenuItemCodes.forEach((glassMenuCode) => {
        const sourceGlassMenuInfo = getSourceMenuInfo(glassMenuCode, menuInfo, thMenuInfo, phMenuInfo);
        const glassDishInfo = getDishInfo(sourceGlassMenuInfo, glassMenuCode);
        const modeGlassDish = getModeCodeFromDishInfo(glassDishInfo);

        const glassDishKey = JSON.stringify([modeGlassDish, glassMenuCode]);

        const glassStateValue = firstGlassGroup && glassState[0][firstGlassGroup['gno']];
        if (glassStateValue) {
          if (glassMenuCode in glassStateValue.detail) {
            if (glassStateValue.detail[glassMenuCode].checked && glassStateValue.detail[glassMenuCode].quantity > 0) {
              const glassDishData: CartStateType = {
                [glassDishKey]: {
                  state: Array.from({ length: glassStateValue.detail[glassMenuCode].quantity }, () => {
                    return {};
                  }),
                  init: {},
                },
              };
              if (type === 'add') {
                appDispatch(cartActions.add(glassDishData));
              } else if (type === 'replace') {
                appDispatch(cartActions.replace(glassDishData));
              }
            }
          }
        }
      });
    }

    onClose && onClose();

    if (type === 'add') {
      // if the modal is in MenuList page, then reset state and redirect to cart
      dishDispatcher({ type: 'init', payload: [addedState] });
      glassDispatcher({ type: 'init', payload: [addedState] });
      updateMainPageScrollTop();
      navigate('/cart/', { replace: true });
      if (buttonAddCartRef.current) buttonAddCartRef.current.disabled = false;
    }

    //log event
    const categoryCode = getCategoryCodeFromDishCode(menuInfo, thMenuInfo, phMenuInfo, itemCode);
    log({
      op_type: LOG_OP_TYPE_MENU_DETAILS_ADDITIONAL_CART,
      op_detail: {
        mode: modeCode,
        category: categoryCode,
        poscd: itemCode,
        num: itemQuantity,
      },
    });

    if (currentQuantityOfMenuItem !== itemQuantity) {
      log({
        op_type: LOG_OP_TYPE_CART_SCREEN_QUANTITY_CHANGE,
        op_detail: {
          poscd: itemCode,
          plus_minus: currentQuantityOfMenuItem > itemQuantity ? '0' : '1',
          num: Math.abs(currentQuantityOfMenuItem - itemQuantity).toString(),
        },
      });
    }
  };

  const closeModal = () => {
    setTimeout(() => {
      playSound('close');

      if (type === 'replace') {
        dishDispatcher({ type: 'init', payload: initState });
        glassDispatcher({ type: 'init', payload: initGlassStateValue });
      }

      const pageWrapperElem = document.querySelector('.page-wrapper') as HTMLDivElement;
      if (pageWrapperElem) {
        pageWrapperElem.removeAttribute('style');
        document.documentElement.style.setProperty('--position-modal-top', '0px');
      }

      const categoryCode = getCategoryCodeFromDishCode(menuInfo, thMenuInfo, phMenuInfo, itemCode);
      log({
        op_type: LOG_OP_TYPE_MENU_DETAILS_CLOSE,
        op_detail: {
          mode: modeCode,
          category: categoryCode,
          poscd: itemInfo?.poscd,
        },
      });

      onClose && onClose();
    }, TAP_ANIMATION_TIME / 2);
  };

  if (!open) return null;
  return (
    <dishDispatchContext.Provider value={dishDispatcher}>
      <glassDispatchContext.Provider value={glassDispatcher}>
        <div className="modal dish-modal" tabIndex={-1} data-bs-backdrop="static" style={{ display: 'block' }}>
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <>
                <a className="modal-btn-close-wrapper" onClick={closeModal}>
                  <span className="modal-btn-close">
                    <AraTimesIcon />
                  </span>
                </a>
                <div className="modal-body">
                  <LazyLoadImage
                    wrapperClassName="d-block"
                    className="img-fluid rounded-start w-100"
                    alt="item_image"
                    effect="opacity"
                    src={imgSrc}
                  />

                  <div className="dish-name">{stripHtml(itemName)}</div>
                  {itemDesc && itemDesc.length > 0 && (
                    <div className="dish-description" dangerouslySetInnerHTML={{ __html: itemDesc }} />
                  )}
                  {[GRAND_MENU_MODEID, LUNCH_MENU_MODEID].includes(modeCode) && (
                    <div className="dish-price-section">
                      <DishPrice price={basePrice} />
                    </div>
                  )}
                  {/* add component group with glassflg=1 here */}
                  {ListOfGlassOrder}
                  {ListOfOrder}
                </div>
                <div className="dish-footer">
                  <div className="dish-footer-detail">
                    <PortionQuantitySelector
                      modeCode={modeCode}
                      itemCode={itemCode}
                      addedState={addedState}
                      initialQuantity={initState.length}
                      quantity={itemQuantity}
                      type={type}
                      onReset={resetDishModal}
                    />
                    <DishPrice price={totCost} />
                  </div>
                  <div className="text-center">
                    <button
                      type="button"
                      className="ara-btn ara-btn-dark add-cart-btn"
                      ref={buttonAddCartRef}
                      onClick={handleAddCart}
                    >
                      {t('MenuItemDetail.add_to_cart')}
                    </button>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </glassDispatchContext.Provider>
    </dishDispatchContext.Provider>
  );
}
