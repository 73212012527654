import { imgDB, sendOrder } from 'apis/Api';
import {
  CartInfoGroupType,
  CartInfoMenuType,
  CartInfoType,
  HistoryOrderDetailMenuInfoType,
  HistoryOrderDetailType,
} from 'apis/types';
import { AppDispatch } from 'app/store';
import { Buffer } from 'buffer';
import { AlertModalProps } from 'components/AlertModal/AlertModal';
import { GlobalModalComponentType } from 'components/GlobalModal/createGlobalModalContext';
import { initDishState } from 'components/MenuDetail/MenuDetail';
import { DishOptionGroupDetailType, DishOptionType, PortionStateType } from 'components/MenuDetail/types';
import { AES, enc, lib } from 'crypto-ts';
import { WordArray } from 'crypto-ts/src/lib/WordArray';
import dayjs from 'dayjs';
import * as sha256 from 'fast-sha256';
import i18n from 'i18n/i18n';
import { CartStateType, CompactOrderStateType } from 'pages/Cart/types';
import { OrderAPIMenuInfoType, OrderAPIOrderInfoType, OrderAPIType } from 'pages/OrderHistory/types';
import { playSound } from 'sound/Sound';
import { mainActions } from 'store/mainSlice';
import { AllYouCanEatType, HistoryFlagType, Payload, PitaType } from 'types';
import {
  ALL_YOU_CAN_EAT_MENU_MODEID,
  DECRYPT_PASSWORD,
  DEFAULT_LANGUAGE,
  GRAND_MENU_MODEID,
  LUNCH_MENU_MODEID,
  MAX_QUANTITY_ORDER_BUFFET_ITEM_IN_CART,
  MAX_QUANTITY_ORDER_ITEM_IN_CART,
  MIN_NON_MEMBER_ID,
  MODAL_TYPES,
  PITA_MODEID,
  RES_CODE_CREDENTIAL_ERROR,
  RES_CODE_DATA_READ_ERROR,
  RES_CODE_DATA_WRITE_ERROR,
  RES_CODE_DB_CONNECTION_ERROR,
  RES_CODE_PARAMETER_ERROR,
  RES_CODE_SERVER_CONNECTION_ERROR,
  RES_CODE_SUCCESS,
  RES_CODE_UNAUTHORIZED,
  TAP_ANIMATION_TIME,
  TIME_FRAME_LUNCH,
} from '../constants';
import SessionStorage, { SESSION_CUSTOMER_ID_KEY, SESSION_CUSTOMER_TOKEN_KEY } from './sessionStorage';
import { GroupOrderBySimilarityReturnedType } from './types';

export function dateTimeStr(d: Date): string {
  const day = dayjs(d);
  return day.format('YYYY-MM-DD HH:mm:ss');
}

export function nowStr(): string {
  return dateTimeStr(new Date());
}

export function getFreqDisability(currentFreqMenu: undefined | string[], menuInfo: Payload): boolean {
  let freqDisabled = true;
  if (currentFreqMenu) freqDisabled = !currentFreqMenu.some((freqItemCode) => freqItemCode in menuInfo.menu_detail);
  return freqDisabled;
}

export function imgFullPath(img: Payload | undefined): string {
  if (!img) return '';

  const path = img[i18n.language] ?? img[DEFAULT_LANGUAGE];

  return path ? imgDB + path : '';
}

export function attrLang(obj: Payload | undefined, attr: string): string {
  if (!obj || !obj[attr]) return '';

  const contentInLang = obj[attr][i18n.language] ?? obj[attr][DEFAULT_LANGUAGE];

  return contentInLang ?? '';
}

export function getSelectedQuantity(object: DishOptionGroupDetailType): number {
  let selectedNumber = 0;
  Object.values(object).map((itemState) => {
    if (itemState.checked) selectedNumber = selectedNumber + itemState.quantity;
  });
  return selectedNumber;
}

export function findMatched(keywordData: Payload, currentModeCode: string, searchKey: string): string[] {
  const matchedPairs: Payload[] = keywordData[currentModeCode];
  const matchedCodes: string[] = [];
  if (matchedPairs) {
    matchedPairs.forEach((matchPair) => {
      const matchedKeys: string[] = matchPair.keyword[i18n.language] ? matchPair.keyword[i18n.language] : [];
      const regex = new RegExp(searchKey, 'gi');
      if (matchedKeys.some((e) => regex.test(e))) {
        matchedCodes.push(matchPair.poscd);
      }
    });
  }

  return matchedCodes;
}

export function handleSelectOne(
  optionCode: string,
  optionState: DishOptionType,
  targetCode: string,
  checked: boolean
): DishOptionType {
  // use event.target.checked to handle both radio and checbox
  const newItemValue = targetCode === optionCode ? checked : false;
  const newItemState = {
    ...optionState,
    checked: newItemValue,
  };
  return newItemState;
}

export function changeQuantityOne(itemState: DishOptionType, kousenum: number, direction: string): DishOptionType {
  const itemQuantity = itemState.quantity;
  const newItemValue = direction === 'increase' ? Math.min(kousenum, itemQuantity + 1) : Math.max(1, itemQuantity - 1);
  const newItemState = {
    ...itemState,
    ['quantity']: newItemValue,
  };
  return newItemState;
}

export function handleMultipleOption(
  optionState: DishOptionType,
  type: string,
  payload: string | boolean,
  kousenum: number
): DishOptionType {
  let newOptionState = { ...optionState };
  switch (type) {
    case 'checkbox': {
      newOptionState = {
        ...newOptionState,
        checked: payload as boolean,
        quantity: 1,
      };
      break;
    }
    case 'quantity': {
      const newItemValue =
        payload === 'increase'
          ? Math.min(kousenum, newOptionState.quantity + 1)
          : Math.max(1, newOptionState.quantity - 1);
      newOptionState = {
        ...newOptionState,
        quantity: newItemValue,
      };
    }
  }
  return newOptionState;
}

export function summarizeOrderState(state: PortionStateType): CompactOrderStateType {
  const orderContent: CompactOrderStateType = {};
  Object.values(state).forEach((groupState) => {
    const groupDetail: Payload = groupState.detail;
    Object.entries(groupDetail).forEach(([optionCode, optionState]) => {
      if (optionState.checked) orderContent[optionCode] = optionState.quantity;
    });
  });
  return orderContent;
}

export function summarizeDishState(state: PortionStateType[]): CompactOrderStateType {
  const dishContent: CompactOrderStateType = {};
  state.forEach((orderState) => {
    const compactOrderState = summarizeOrderState(orderState);
    Object.entries(compactOrderState).forEach(([optionCode, optionQuantity]) => {
      dishContent[optionCode] = optionCode in dishContent ? dishContent[optionCode] + optionQuantity : optionQuantity;
    });
  });
  return dishContent;
}

export function getOrderCost(order: PortionStateType, sourceMenuInfo: Payload | null, dishCode: string): number {
  const dishInfo = getDishInfo(sourceMenuInfo, dishCode);
  const basePrice = (dishInfo && parseInt(dishInfo.price)) || 0;
  let totCost = basePrice;
  Object.values(order).forEach((group) => {
    const groupDetailState = group.detail;
    Object.entries(groupDetailState).forEach(([optionCode, optionState]) => {
      const optionInfo = getDishInfo(sourceMenuInfo, optionCode);
      const optionPrice = optionState.checked ? (optionInfo && parseInt(optionInfo.price)) || 0 : 0;
      totCost = totCost + optionPrice * optionState.quantity;
    });
  });
  return totCost;
}

export function getDishCost(dishState: PortionStateType[], menuInfo: Payload | null, dishCode: string): number {
  let totCost = 0;
  // get total dish's cost from dishState (multiple portions)
  dishState.forEach((orderState) => {
    totCost = totCost + getOrderCost(orderState, menuInfo, dishCode);
  });
  return totCost;
}

export function getDishInfo(sourceMenuInfo: Payload | null, code: string): Payload | undefined {
  let dishInfo: Payload | undefined;

  if (sourceMenuInfo?.menu_detail && sourceMenuInfo?.menu_detail[code]) {
    dishInfo = sourceMenuInfo?.menu_detail[code];
  }

  return dishInfo;
}

export function groupOrderBySimilarity(orders: PortionStateType[]): GroupOrderBySimilarityReturnedType {
  const stringOrders = orders.map((order) => JSON.stringify(order));
  const stateQuantityPair: GroupOrderBySimilarityReturnedType = {};
  stringOrders.forEach((order) => {
    if (!(order in stateQuantityPair)) stateQuantityPair[order] = 0;
    stateQuantityPair[order] = stateQuantityPair[order] + 1;
  });
  return stateQuantityPair;
}

export function getSourceMenuInfo(
  itemCode: string,
  menuInfo: Payload,
  thMenuInfo: Payload | null,
  phMenuInfo: Payload | null
) {
  let sourceMenuInfo: Payload | null = menuInfo;
  if (isMenuOfPita(phMenuInfo, itemCode)) {
    sourceMenuInfo = phMenuInfo;
  } else if (isMenuOfYouCanEat(thMenuInfo, itemCode)) {
    sourceMenuInfo = thMenuInfo;
  }

  return sourceMenuInfo;
}

export function getSourceMenuInfoByMode(
  modeCode: string,
  menuInfo: Payload,
  thMenuInfo: Payload | null,
  phMenuInfo: Payload | null
) {
  let sourceMenuInfo: Payload | null = menuInfo;
  if (modeCode === PITA_MODEID) {
    sourceMenuInfo = phMenuInfo;
  } else if (modeCode === ALL_YOU_CAN_EAT_MENU_MODEID) {
    sourceMenuInfo = thMenuInfo;
  }

  return sourceMenuInfo;
}

export function prepareDataToOrder(
  cartState: CartStateType,
  menuInfo: Payload,
  thMenuInfo: Payload | null,
  phMenuInfo: Payload | null
): OrderAPIType {
  let orderIdx = 1;
  const orderInfo: OrderAPIOrderInfoType[] = [];
  Object.entries(cartState).forEach(([key, value]) => {
    const [modeCode, itemCode] = JSON.parse(key);
    const sourceMenuInfo = getSourceMenuInfoByMode(modeCode, menuInfo, thMenuInfo, phMenuInfo);

    const dishInfo = getDishInfo(sourceMenuInfo, itemCode);
    const singleMode = dishInfo?.single_flg === '1' ? true : false;

    const { state: dishState } = value;
    const uniqueOrders = groupOrderBySimilarity(dishState);
    Object.entries(uniqueOrders).forEach(([orderStateString, itemQuantity]) => {
      if (singleMode) {
        for (let i = 0; i < itemQuantity; i++) {
          const dishDict = {
            MenuID: orderIdx,
            ModeCode: modeCode,
            MenuCode: itemCode,
            CountInfo: {
              Count: 1,
            },
          };
          const orderState: PortionStateType = JSON.parse(orderStateString);
          const [menuInfo, newOrderIdx] = createMenuInfo(orderState, orderIdx, 1);
          orderIdx = newOrderIdx;
          Object.assign(dishDict, {
            MenuInfo: menuInfo,
          });
          orderInfo.push(dishDict);
        }
      } else {
        const dishDict = {
          MenuID: orderIdx,
          ModeCode: modeCode,
          MenuCode: itemCode,
          CountInfo: {
            Count: itemQuantity,
          },
        };
        const orderState: PortionStateType = JSON.parse(orderStateString);
        const [menuInfo, newOrderIdx] = createMenuInfo(orderState, orderIdx, itemQuantity);
        orderIdx = newOrderIdx;
        Object.assign(dishDict, {
          MenuInfo: menuInfo,
        });
        orderInfo.push(dishDict);
      }
    });
  });
  const returnDict = {
    customer_id: getCustomerId().toString(),
    order_info: orderInfo,
  };
  return returnDict;
}

function createMenuInfo(
  orderState: PortionStateType,
  orderIdx: number,
  itemQuantity: number
): [OrderAPIMenuInfoType[], number] {
  const menuInfo: OrderAPIMenuInfoType[] = [];
  let newOrderIdx = orderIdx + 1;
  Object.entries(orderState).forEach(([groupCode, groupState]) => {
    const groupDetail: Payload = groupState.detail;
    Object.entries(groupDetail).forEach(([optionCode, optionState]) => {
      if (optionState.checked) {
        const optionInfo = createOptionInfo(optionCode, optionState.quantity, itemQuantity, newOrderIdx, groupCode);
        newOrderIdx = newOrderIdx + 1;
        menuInfo.push(optionInfo);
      }
    });
  });
  return [menuInfo, newOrderIdx];
}

export function createOptionInfo(
  optionCode: string,
  optionQuantity: number,
  itemQuantity: number,
  idx: number,
  groupCode: string
): OrderAPIMenuInfoType {
  const optionInfo = {
    MenuID: idx,
    MenuCode: optionCode,
    CountInfo: {
      Count: optionQuantity * itemQuantity,
    },
    Segment: 'サブメニュー',
    GroupNo: groupCode,
  };
  return optionInfo;
}

export function summarizeHistoryOrder(
  historyOrder: HistoryOrderDetailMenuInfoType
): DishOptionGroupDetailType | Payload {
  const compactState: DishOptionGroupDetailType | Payload = {};
  const subMenu = historyOrder.MenuInfo || [];
  subMenu.forEach((optionState) => {
    const groupCode = optionState.GroupNo;
    const optionCode = optionState.MenuCode;
    const quantity = Math.ceil(parseInt(optionState.CountInfo.Count) / parseInt(historyOrder.CountInfo.Count));
    if (!(groupCode in compactState)) compactState[groupCode] = {};
    compactState[groupCode][optionCode] = {
      checked: true,
      quantity: quantity,
    };
  });
  return compactState;
}

export function addHistoryOrderToCart(
  historyState: HistoryOrderDetailMenuInfoType,
  initState: PortionStateType
): PortionStateType {
  let newState = { ...initState };
  const compactHistoryState = summarizeHistoryOrder(historyState);
  if (!checkObjectNotEmpty(compactHistoryState)) return newState;
  Object.entries(newState).forEach(([groupCode, groupState]) => {
    const compactGroupState = compactHistoryState[groupCode];
    if (!compactGroupState) return undefined;

    const selectedOptionMenuCodes = [];
    for (const optionMenuCode in compactGroupState) {
      selectedOptionMenuCodes.push(optionMenuCode);
    }

    const groupDetail = groupState.detail;
    const newGroupDetail = {
      ...groupDetail,
      ...compactGroupState,
    };
    const newGroupState = {
      ...groupState,
      detail: newGroupDetail,
      selected: selectedOptionMenuCodes,
    };
    newState = {
      ...newState,
      [groupCode]: newGroupState,
    };
  });
  return newState;
}

export function getHistoryRowCost(
  menuInfo: Payload,
  thMenuInfo: Payload | null,
  phMenuInfo: Payload | null,
  subOrderState: HistoryOrderDetailMenuInfoType
): number {
  const itemCode = subOrderState.MenuCode;

  const sourceMenuInfo = getSourceMenuInfo(itemCode, menuInfo, thMenuInfo, phMenuInfo);

  const itemInfo = getDishInfo(sourceMenuInfo, itemCode);
  const itemPrice = (itemInfo && parseInt(itemInfo.price)) || 0;
  let totCost = itemPrice * parseInt(subOrderState.CountInfo.Count);
  const options = subOrderState.MenuInfo;
  options?.forEach((option) => {
    const optionCode = option.MenuCode;
    const optionInfo = getDishInfo(sourceMenuInfo, optionCode);
    const optionPrice = (optionInfo && parseInt(optionInfo.price)) || 0;
    totCost = totCost + optionPrice * parseInt(option.CountInfo.Count);
  });
  return totCost;
}

export function checkHistoryFlag(
  menuInfo: Payload,
  thMenuInfo: Payload | null,
  phMenuInfo: Payload | null,
  orders: HistoryOrderDetailType[]
): HistoryFlagType {
  // check if item with lunch_flg and setmenu_flg exists in orderHistory
  const returnFlag = {
    setmenu_flg: 0,
    lunch_flg: 0,
  };
  orders.some((order) => {
    const orderDetail = order.OrderDetailMenuInfo;
    orderDetail.forEach((subOrder) => {
      const itemCode = subOrder.MenuCode;
      const sourceMenuInfo = getSourceMenuInfo(itemCode, menuInfo, thMenuInfo, phMenuInfo);
      const itemInfo = getDishInfo(sourceMenuInfo, itemCode);
      if (itemInfo) {
        if (itemInfo.lunch_flg === '1') returnFlag.lunch_flg = 1;
        if (itemInfo.setmenu_flg === '1') returnFlag.setmenu_flg = 1;
      }
    });
    if (returnFlag.lunch_flg === 1 && returnFlag.setmenu_flg === 1) return true;
  });
  return returnFlag;
}

export function cancelOrder(
  cancelledData: OrderAPIType,
  showGlobalModal: <T extends GlobalModalComponentType>(modalType: string, modalProps: T) => void,
  appDispatch: AppDispatch
): Promise<void> {
  const { t } = i18n;

  return sendOrder(cancelledData)
    .then((response) => {
      //handle error response from API
      if (response.data.code !== RES_CODE_SUCCESS) {
        dispatchErrorResponse(showGlobalModal, response.data);
      } else {
        showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
          message: t('OrderHistory.cancel_success'),
        });
        appDispatch(mainActions.fetchOrderHistoryData());
      }
    })
    .catch((err) => {
      console.error(err);
      showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
        message: t('OrderHistory.cancel_fail'),
      });
    });
}

export function prepareCancelledData(orderState: HistoryOrderDetailMenuInfoType): OrderAPIType {
  const cancelledCount = parseInt(orderState.CountInfo.Count) * -1;
  const newOrderInfo = {
    MenuID: 1,
    ModeCode: orderState.ModeCode,
    MenuCode: orderState.MenuCode,
    CountInfo: {
      ...orderState.CountInfo,
      Count: cancelledCount,
    },
  };
  const menuInfo: OrderAPIMenuInfoType[] = [];
  orderState.MenuInfo?.forEach((subOrder, idx) => {
    const newSubOrder = {
      MenuID: idx + 2,
      MenuCode: subOrder.MenuCode,
      Segment: 'サブメニュー',
      CountInfo: {
        ...subOrder.CountInfo,
        Count: parseInt(subOrder.CountInfo.Count) * -1,
      },
      GroupNo: subOrder.GroupNo,
    };
    menuInfo.push(newSubOrder);
  });
  if (menuInfo.length > 0)
    Object.assign(newOrderInfo, {
      MenuInfo: menuInfo,
    });
  const orderData = {
    customer_id: getCustomerId().toString(),
    order_info: [newOrderInfo],
  };
  return orderData;
}

export function countCouponUsedInCart(
  menuInfo: Payload,
  thMenuInfo: Payload | null,
  phMenuInfo: Payload | null,
  cartState: CartStateType
): number {
  let total = 0;
  Object.entries(cartState).forEach(([key, value]) => {
    const [modeCode, itemCode] = JSON.parse(key);
    const sourceMenuInfo = getSourceMenuInfoByMode(modeCode, menuInfo, thMenuInfo, phMenuInfo);
    const itemInfo = getDishInfo(sourceMenuInfo, itemCode);
    if (itemInfo && itemInfo.coupon_flg === '1') {
      const itemQuantity = value.state.length;
      total = total + itemQuantity;
    }
  });
  return total;
}

export function countCouponUsedInHistoryOrder(
  menuInfo: Payload,
  thMenuInfo: Payload | null,
  phMenuInfo: Payload | null,
  orderHistory: HistoryOrderDetailType[]
): number {
  let total = 0;

  orderHistory.forEach((historyOrder) => {
    const orderStates = historyOrder.OrderDetailMenuInfo;
    orderStates.forEach((orderState) => {
      const itemCode = orderState.MenuCode;
      const sourceMenuInfo = getSourceMenuInfo(itemCode, menuInfo, thMenuInfo, phMenuInfo);
      const itemInfo = getDishInfo(sourceMenuInfo, itemCode);
      if (itemInfo && itemInfo.coupon_flg === '1') {
        total += parseInt(orderState.CountInfo.Count);
      }
    });
  });

  return total;
}

export function countCouponUsed(
  menuInfo: Payload,
  thMenuInfo: Payload | null,
  phMenuInfo: Payload | null,
  cartState: CartStateType,
  orderHistory: HistoryOrderDetailType[]
): number {
  const totalCouponUsedInCart = countCouponUsedInCart(menuInfo, thMenuInfo, phMenuInfo, cartState);
  const totalCouponUsedInHistoryOrder = countCouponUsedInHistoryOrder(menuInfo, thMenuInfo, phMenuInfo, orderHistory);
  return totalCouponUsedInCart + totalCouponUsedInHistoryOrder;
}

export function countMenuItemInCart(cartState: CartStateType, menuItemCode: string): number {
  let total = 0;

  Object.entries(cartState).forEach(([key, value]) => {
    const [, itemCode] = JSON.parse(key);
    if (itemCode === menuItemCode) {
      total = total + value.state.length;
    }
  });

  return total;
}

export function countTotalOrderInCart(cartState: CartStateType): number {
  let total = 0;

  Object.entries(cartState).forEach(([, value]) => {
    total = total + value.state.length;
  });

  return total;
}

export function countTotalOrderMenuAllYouCanEatInCart(cartState: CartStateType, thMenuInfo: Payload | null): number {
  let total = 0;

  Object.entries(cartState).forEach(([key, value]) => {
    const [, itemCode] = JSON.parse(key);
    if (isMenuOfYouCanEat(thMenuInfo, itemCode)) {
      total = total + value.state.length;
    }
  });

  return total;
}

export function countTotalOrderMenuPitaInCart(cartState: CartStateType, phMenuInfo: Payload | null): number {
  let total = 0;

  Object.entries(cartState).forEach(([key, value]) => {
    const [, itemCode] = JSON.parse(key);
    if (isMenuOfPita(phMenuInfo, itemCode)) {
      total = total + value.state.length;
    }
  });

  return total;
}

export function getErrorMessage(
  modeCode: string,
  timeFrame: number,
  itemInfo: Payload | undefined,
  historyFlag: HistoryFlagType,
  thMenuInfo: Payload | null,
  thCourseMenu: Payload | null,
  allYouCanEatState: AllYouCanEatType,
  phMenuInfo: Payload | null,
  phCourseMenu: Payload | null,
  pitaState: PitaType,
  haveAdult: number,
  outOfStocked: boolean
): string {
  if (!itemInfo) return '';

  const { t } = i18n;
  let message = '';
  if (outOfStocked) {
    message = t('MenuList.out_of_stock');
  } else if (itemInfo.callstaff_flg === '1' || (itemInfo.agelimit_flg === '1' && haveAdult === 0)) {
    message = t('MenuList.call_staff');
  } else {
    const displayCond = itemInfo.dispcond;
    switch (displayCond) {
      case '1': {
        if (historyFlag.lunch_flg === 0) message = t('MenuList.lunch_dish_needed');
        break;
      }
      case '2': {
        if (historyFlag.setmenu_flg === 0) message = t('MenuList.set_dish_needed');
        break;
      }
      case '3': {
        if (timeFrame !== 2) message = t('MenuList.lunch_time_only');
        break;
      }
      case '4': {
        if (timeFrame === 2) message = t('MenuList.lunch_time_excluded');
        break;
      }
      default: {
        break;
      }
    }
  }

  //check if menu in all-you-can-eat
  if (modeCode == ALL_YOU_CAN_EAT_MENU_MODEID) {
    if (isMenuOfYouCanEat(thMenuInfo, itemInfo.poscd)) {
      const isMenuAllYouCanEatAvailable = checkAllYouCanEatMenuAvailable(thMenuInfo, thCourseMenu, itemInfo.poscd);
      if (
        !isMenuAllYouCanEatAvailable ||
        (isMenuAllYouCanEatAvailable && checkAllYouCanEatMenuOver(itemInfo, allYouCanEatState))
      ) {
        message = t('AllYouCanEat.menu_not_available');
      }
    }
  }

  //check if menu in pita mode
  if (modeCode == PITA_MODEID) {
    if (isMenuOfPita(phMenuInfo, itemInfo.poscd)) {
      const isMenuPitaAvailable = checkPitaMenuAvailable(phMenuInfo, phCourseMenu, itemInfo.poscd);
      if (!isMenuPitaAvailable || (isMenuPitaAvailable && checkPitaMenuOver(itemInfo, pitaState))) {
        message = t('Pita.menu_not_available');
      }
    }
  }

  return message;
}

export function convertCartInfoToCartState(menuInfo: Payload, cartInfo: CartInfoType[]): CartStateType {
  const cartState = {} as CartStateType;
  cartInfo.forEach((portionGroupInfo) => {
    const key = JSON.stringify([portionGroupInfo.modecd, portionGroupInfo.poscd]);
    const num = parseInt(portionGroupInfo.num);
    const initialPortionState = initDishState(menuInfo, portionGroupInfo.poscd, false)[0];
    const portionState = converPortionInfoToPortionState(initialPortionState, portionGroupInfo, num);
    const detailDishState = [...Array(num)].map(() => JSON.parse(JSON.stringify(portionState)));
    if (key in cartState) {
      detailDishState.forEach(function (detailDishStateItem) {
        cartState[key].state.push(detailDishStateItem);
      });
    } else {
      const dishState = {
        init: initialPortionState,
        state: detailDishState,
      };
      cartState[key] = dishState;
    }
  });
  return cartState;
}

function converPortionInfoToPortionState(
  initialDishState: PortionStateType,
  portionGroupInfo: CartInfoType,
  quantity: number
): PortionStateType {
  const newDishState = JSON.parse(JSON.stringify(initialDishState));
  const groups = portionGroupInfo.select[0].group;
  groups.forEach((group) => {
    const groupCode = group.gno;
    if (!(groupCode in newDishState)) return undefined;
    const menu = group.menu;
    menu.forEach((option) => {
      const optionCode = option.poscd;
      newDishState[groupCode].detail[optionCode] = {
        checked: true,
        quantity: Math.ceil(parseInt(option.num) / quantity),
      };
      newDishState[groupCode].selected.push(optionCode);
    });
  });
  return newDishState;
}

export function convertPortionStateToPortionInfo(
  dishKey: string,
  portionState: PortionStateType,
  quantity: number
): CartInfoType {
  const [modeCode, dishCode] = JSON.parse(dishKey);
  const selectInfo: CartInfoGroupType[] = [];
  Object.entries(portionState).forEach(([groupCode, groupState]) => {
    const groupMenu: CartInfoMenuType[] = [];
    Object.entries(groupState.detail).forEach(([optionCode, optionState]) => {
      if (!optionState.checked) return undefined;
      const optionInfo: CartInfoMenuType = {
        poscd: optionCode,
        provision_timing_settingflg: '0',
        num: (optionState.quantity * quantity).toString(),
      };
      groupMenu.push(optionInfo);
    });
    const groupInfo: CartInfoGroupType = {
      gno: groupCode.toString(),
      menu: groupMenu,
    };
    selectInfo.push(groupInfo);
  });
  const portionGroupInfo: CartInfoType = {
    modecd: modeCode,
    poscd: dishCode,
    num: quantity.toString(),
    provision_timing_settingflg: '0',
    select: [
      {
        group: selectInfo,
      },
    ],
  };
  return portionGroupInfo;
}

export function checkObjectNotEmpty(obj: Payload): boolean {
  if (Object.keys(obj).length === 0) return false;
  return true;
}

export function checkInvalidItemInCart(
  menuInfo: Payload,
  cartState: CartStateType,
  updatedTimeFrame: number,
  historyFlag: HistoryFlagType,
  thMenuInfo: Payload | null,
  thCourseMenu: Payload | null,
  allYouCanEatState: AllYouCanEatType,
  haveAdult: number,
  phMenuInfo: Payload | null,
  phCourseMenu: Payload | null,
  pitaState: PitaType
) {
  let invalidItemName = '';
  let errorMessage = '';
  Object.keys(cartState).some((dishKey) => {
    const [modeCode, dishCode] = JSON.parse(dishKey);

    const sourceMenuInfo = getSourceMenuInfoByMode(modeCode, menuInfo, thMenuInfo, phMenuInfo);

    const dishInfo = getDishInfo(sourceMenuInfo, dishCode);
    const dishModeCode = getModeCodeFromDishInfo(dishInfo);
    const message = getErrorMessage(
      dishModeCode,
      updatedTimeFrame,
      dishInfo,
      historyFlag,
      thMenuInfo,
      thCourseMenu,
      allYouCanEatState,
      phMenuInfo,
      phCourseMenu,
      pitaState,
      haveAdult,
      false
    );
    if (message !== '') {
      invalidItemName = attrLang(dishInfo, 'lang');
      errorMessage = message;
    }
    return message !== '';
  });
  return {
    name: invalidItemName,
    message: errorMessage,
  };
}

export function checkServiceRequired(
  menuInfo: Payload,
  thMenuInfo: Payload | null,
  phMenuInfo: Payload | null,
  cartState: CartStateType
): string[] {
  const serviceRequiredItems: string[] = [];
  Object.keys(cartState).forEach((dishKey) => {
    // loop through each dish in cart
    const [modeCode, dishCode] = JSON.parse(dishKey);
    let sourceMenuInfo: Payload | null = menuInfo;
    if (isMenuOfYouCanEat(thMenuInfo, dishCode) && modeCode === ALL_YOU_CAN_EAT_MENU_MODEID) {
      sourceMenuInfo = thMenuInfo;
    } else if (isMenuOfPita(phMenuInfo, dishCode)) {
      sourceMenuInfo = phMenuInfo;
    }

    Object.keys(sourceMenuInfo?.category).some((categoryCode: string) => {
      // check if any category contains the dish in cart
      if (sourceMenuInfo?.category[categoryCode].service_flg !== '1') return false;
      const categorySub: Payload[] = sourceMenuInfo.menu_sub[categoryCode];
      const bingo = categorySub.some((genreSub) => {
        const isParentCategory = genreSub.menu.some((itemCode: string) => {
          return itemCode === dishCode;
        });
        if (
          // if the dish is found in a category, check if it's service flag = 1
          // if it is, then push its into to serviceRequiredItems
          isParentCategory &&
          sourceMenuInfo?.category[categoryCode] &&
          sourceMenuInfo?.category[categoryCode].service_flg === '1'
        ) {
          serviceRequiredItems.push(dishCode);
          return true;
        }
      });
      return bingo;
    });
  });
  return serviceRequiredItems;
}

export function isMenuOfYouCanEat(thMenuInfo: Payload | null, itemMenuCode: string): boolean {
  if (thMenuInfo?.menu_detail) {
    const thMenuCodes = Object.keys(thMenuInfo.menu_detail);

    return thMenuCodes.includes(itemMenuCode) && thMenuInfo.menu_detail[itemMenuCode];
  }

  return false;
}

export function isMenuOfPita(phMenuInfo: Payload | null, itemMenuCode: string): boolean {
  if (phMenuInfo?.menu_detail) {
    const thMenuCodes = Object.keys(phMenuInfo.menu_detail);

    return thMenuCodes.includes(itemMenuCode) && phMenuInfo.menu_detail[itemMenuCode];
  }

  return false;
}

export function checkAllYouCanEatMenuAvailable(
  thMenuInfo: Payload | null,
  thCourseMenu: Payload | null,
  itemMenuCode: string
): boolean {
  if (thCourseMenu === null) {
    return false;
  }

  if (!isMenuOfYouCanEat(thMenuInfo, itemMenuCode)) {
    return false;
  }

  if (thCourseMenu.poscd && (thCourseMenu.poscd as string[]).includes(itemMenuCode)) {
    return true;
  }

  return false;
}

export function checkPitaMenuAvailable(
  phMenuInfo: Payload | null,
  phCourseMenu: Payload | null,
  itemMenuCode: string
): boolean {
  if (phCourseMenu === null) {
    return false;
  }

  if (!isMenuOfPita(phMenuInfo, itemMenuCode)) {
    return false;
  }

  if (phCourseMenu.poscd && (phCourseMenu.poscd as string[]).includes(itemMenuCode)) {
    return true;
  }

  return false;
}

export function checkAllYouCanEatMenuOver(
  menuItemInfo: Payload | undefined,
  allYouCanEatState: AllYouCanEatType
): boolean {
  //all you can eat menu
  if (menuItemInfo?.th_bunruicd === '01') {
    return !allYouCanEatState.isShowEatRemainTime;
  }

  //all you can drink menu
  if (menuItemInfo?.th_bunruicd === '02') {
    return !allYouCanEatState.isShowDrinkRemainTime;
  }

  return false;
}

export function checkPitaMenuOver(menuItemInfo: Payload | undefined, pitaState: PitaType): boolean {
  //all you can eat menu
  if (menuItemInfo?.th_bunruicd === '01') {
    return !pitaState.isShowEatRemainTime;
  }

  //all you can drink menu
  if (menuItemInfo?.th_bunruicd === '02') {
    return !pitaState.isShowDrinkRemainTime;
  }

  return false;
}

export function checkAllYouCanEatCategoryAvailable(
  thMenuInfo: Payload | null,
  thCourseMenu: Payload | null,
  categoryCode: string
): boolean {
  if (thMenuInfo == null || thCourseMenu === null) {
    return false;
  }

  let itemMenuCodes: string[] = [];
  const genreMenu: Payload[] = thMenuInfo.menu_sub[categoryCode];
  if (genreMenu) {
    genreMenu.forEach((genreSub) => {
      itemMenuCodes = itemMenuCodes.concat(genreSub.menu);
    });

    const hasSomeMenusAvailable = itemMenuCodes.some((itemMenuCode) => {
      return checkAllYouCanEatMenuAvailable(thMenuInfo, thCourseMenu, itemMenuCode);
    });

    return hasSomeMenusAvailable;
  }

  return false;
}

export function checkPitaCategoryAvailable(
  phMenuInfo: Payload | null,
  phCourseMenu: Payload | null,
  categoryCode: string
): boolean {
  if (phMenuInfo == null || phCourseMenu === null) {
    return false;
  }

  let itemMenuCodes: string[] = [];
  const genreMenu: Payload[] = phMenuInfo.menu_sub[categoryCode];
  if (genreMenu) {
    genreMenu.forEach((genreSub) => {
      itemMenuCodes = itemMenuCodes.concat(genreSub.menu);
    });

    const hasSomeMenusAvailable = itemMenuCodes.some((itemMenuCode) => {
      return checkPitaMenuAvailable(phMenuInfo, phCourseMenu, itemMenuCode);
    });

    return hasSomeMenusAvailable;
  }

  return false;
}

export function getInitalModeCode(
  menuInfo: Payload,
  timeFrame: number,
  thCourseMenu: Payload | null,
  phCourseMenu: Payload | null
): string {
  let initialModeCode: string;
  if (
    menuInfo.mode &&
    menuInfo.mode[PITA_MODEID] &&
    phCourseMenu !== null &&
    ((phCourseMenu?.ph_flg === '1' && phCourseMenu?.ph_limit > 0) ||
      (phCourseMenu?.nh_flg === '1' && phCourseMenu?.nh_limit > 0))
  ) {
    initialModeCode = PITA_MODEID;
  } else if (
    thCourseMenu !== null &&
    ((thCourseMenu?.th_flg === '1' && thCourseMenu?.th_limit > 0) ||
      (thCourseMenu?.nh_flg === '1' && thCourseMenu?.nh_limit > 0))
  ) {
    initialModeCode = ALL_YOU_CAN_EAT_MENU_MODEID;
  } else if (timeFrame === TIME_FRAME_LUNCH) {
    initialModeCode = LUNCH_MENU_MODEID;
  } else {
    initialModeCode = GRAND_MENU_MODEID;
  }

  return initialModeCode;
}

export function getNextCategoryCode(
  currentModeCode: string,
  menuInfo: Payload,
  thMenuInfo: Payload,
  thCourseMenu: Payload,
  modeCategories: string[],
  currentCategoryCode: string,
  direction: string
): string {
  const categoryLength = modeCategories.length;
  const categoryIdx = modeCategories.indexOf(currentCategoryCode);

  let nextCategoryCode = currentCategoryCode;
  if (direction === 'left' && categoryIdx < categoryLength - 1) {
    for (let incrementCategoryIdx = categoryIdx + 1; incrementCategoryIdx < categoryLength; incrementCategoryIdx++) {
      const icategoryCode = modeCategories[incrementCategoryIdx];
      if (currentModeCode === ALL_YOU_CAN_EAT_MENU_MODEID) {
        if (checkAllYouCanEatCategoryAvailable(thMenuInfo, thCourseMenu, icategoryCode)) {
          nextCategoryCode = icategoryCode;
          break;
        }
      } else {
        if (checkCategoryAvailable(menuInfo, icategoryCode)) {
          nextCategoryCode = icategoryCode;
          break;
        }
      }
    }
  } else if (direction === 'right' && categoryIdx > 0) {
    for (let decrementCategoryIdx = categoryIdx - 1; decrementCategoryIdx >= 0; decrementCategoryIdx--) {
      const icategoryCode = modeCategories[decrementCategoryIdx];
      if (currentModeCode === ALL_YOU_CAN_EAT_MENU_MODEID) {
        if (checkAllYouCanEatCategoryAvailable(thMenuInfo, thCourseMenu, icategoryCode)) {
          nextCategoryCode = icategoryCode;
          break;
        }
      } else {
        if (checkCategoryAvailable(menuInfo, icategoryCode)) {
          nextCategoryCode = icategoryCode;
          break;
        }
      }
    }
  }

  return nextCategoryCode;
}

export function getCustomerId(): number {
  const cid = SessionStorage.getItem(SESSION_CUSTOMER_ID_KEY) || '-1';

  return parseInt(cid);
}

export function checkIsMember() {
  const customer_id = getCustomerId();

  return customer_id > 0 && customer_id < MIN_NON_MEMBER_ID;
}

export function getToken(): string {
  return SessionStorage.getItem(SESSION_CUSTOMER_TOKEN_KEY) || '';
}

export function getMaximumOrderQuantityBuffetMenu(customerQuantity: number): number {
  return customerQuantity * 2 >= MAX_QUANTITY_ORDER_BUFFET_ITEM_IN_CART
    ? customerQuantity * 2
    : MAX_QUANTITY_ORDER_BUFFET_ITEM_IN_CART;
}

export function checkIsExceedAllowQuantityOrder(
  modeCode: string,
  cartState: CartStateType,
  thMenuInfo: Payload | null,
  quantity: number,
  customerQuantity: number
) {
  const maximumOrderQuantityBuffetMenu = getMaximumOrderQuantityBuffetMenu(customerQuantity);

  const totalItemInCart = countTotalOrderInCart(cartState);
  if (totalItemInCart + quantity > MAX_QUANTITY_ORDER_ITEM_IN_CART) {
    return true;
  }

  if (modeCode === ALL_YOU_CAN_EAT_MENU_MODEID) {
    const totalItemAllYouCanEatInCart = countTotalOrderMenuAllYouCanEatInCart(cartState, thMenuInfo);
    if (totalItemAllYouCanEatInCart + quantity > maximumOrderQuantityBuffetMenu) {
      return true;
    }
  }

  if (modeCode === PITA_MODEID) {
    const totalItemPitaInCart = countTotalOrderMenuPitaInCart(cartState, thMenuInfo);
    if (totalItemPitaInCart + quantity > maximumOrderQuantityBuffetMenu) {
      return true;
    }
  }

  return false;
}

function convertUint8ArrayToWordArray(u8Array: Uint8Array): WordArray {
  const words = [],
    len = u8Array.length;
  let i = 0;

  while (i < len) {
    words.push((u8Array[i++] << 24) | (u8Array[i++] << 16) | (u8Array[i++] << 8) | u8Array[i++]);
  }

  return new lib.WordArray(words, words.length * 4);
}

export function decrypt(cypherText: string): string {
  let result = '';
  try {
    const pass = DECRYPT_PASSWORD;
    const data = Buffer.from(cypherText, 'base64');
    const salt = data.buffer.slice(0, 16);
    const ct = data.buffer.slice(16);
    const rounds = 3;
    const data00 = Buffer.concat([Buffer.from(pass), new Uint8Array(salt)]);
    const hash = [];
    hash[0] = new sha256.Hash().update(data00).digest();
    let resultBinary = hash[0];
    for (let i = 1; i < rounds; i++) {
      const tmp = Buffer.concat([Buffer.from(hash[i - 1]), new Uint8Array(data00)]);
      hash[i] = new sha256.Hash().update(tmp).digest();
      const mergeHashArr = new Uint8Array(resultBinary.byteLength + hash[i].byteLength);
      mergeHashArr.set(new Uint8Array(resultBinary), 0);
      mergeHashArr.set(new Uint8Array(hash[i]), resultBinary.byteLength);
      resultBinary = mergeHashArr;
    }
    const keyBinary = resultBinary.slice(0, 32);
    const ivBinany = resultBinary.slice(32, 48);
    const key = convertUint8ArrayToWordArray(keyBinary);
    const iv = convertUint8ArrayToWordArray(ivBinany);
    result = AES.decrypt(Buffer.from(ct).toString('base64'), key, {
      iv: iv,
    }).toString(enc.Utf8);
  } catch (e: unknown) {
    // console.log(e);
  }
  return result;
}

export function getQueryParam(variable: string) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

export function getModeCodeFromDishInfo(dishInfo: Payload | undefined): string {
  if (dishInfo === null || dishInfo === undefined) {
    return '';
  }

  if (dishInfo.mode === null || dishInfo.mode.length == 0) {
    return '';
  }

  return dishInfo.mode[dishInfo.mode.length - 1].toString() || '';
}

export function getModeCodeFromCategoryCode(menuInfo: Payload, categoryCode: string) {
  for (const [modeCode, modeInfo] of Object.entries(menuInfo.mode)) {
    if ((modeInfo as Payload).category.includes(categoryCode)) {
      return modeCode;
    }
  }

  return '';
}

export function checkCategoryAvailable(menuInfo: Payload, categoryCode: string): boolean {
  let totalSubMenu = 0;
  if (menuInfo.menu_sub[categoryCode]) {
    const genreList: Payload[] = menuInfo.menu_sub[categoryCode];
    if (genreList.length > 0) {
      for (const genreMenu of genreList) {
        if (genreMenu.menu) {
          totalSubMenu += genreMenu.menu.length;
        }
      }
    }
  }

  return totalSubMenu > 0;
}

export function dispatchErrorResponse(
  showGlobalModal: <T extends GlobalModalComponentType>(modalType: string, modalProps: T) => void,
  response: Payload,
  defaultMessage?: string,
  actionClose?: () => void
) {
  if (response.code !== RES_CODE_SUCCESS) {
    if (response.code === RES_CODE_UNAUTHORIZED) {
      return;
    }

    const { t } = i18n;
    const templateMessage = t('MessageList.credential_error');

    const errorCode = (response.code as string).slice(2, 4);
    const errorMessage = templateMessage.replace(/{error_code}/, response.code as string);
    playSound('error');
    switch (errorCode) {
      case RES_CODE_CREDENTIAL_ERROR:
      case RES_CODE_PARAMETER_ERROR:
      case RES_CODE_DB_CONNECTION_ERROR:
      case RES_CODE_DATA_READ_ERROR:
      case RES_CODE_DATA_WRITE_ERROR:
      case RES_CODE_SERVER_CONNECTION_ERROR:
        showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
          message: errorMessage,
          actionClose: actionClose,
        });
        break;
      default:
        showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
          message: defaultMessage || errorMessage,
          actionClose: actionClose,
        });
        break;
    }
  }
}

export function getTotalOrderItem(cartState: CartStateType): number {
  let totalOrderItem = 0;
  for (const key in cartState) {
    totalOrderItem += cartState[key].state.length;
  }

  return totalOrderItem;
}

export function getCategoryCodeFromDishCode(
  menuInfo: Payload,
  thMenuInfo: Payload | null,
  phMenuInfo: Payload | null,
  dishCode: string
): string {
  let foundCategoryCode = '';
  const sourceMenuInfo = getSourceMenuInfo(dishCode, menuInfo, thMenuInfo, phMenuInfo);
  Object.keys(sourceMenuInfo?.category).some((categoryCode: string) => {
    let founded = false;
    const categorySub: Payload[] = sourceMenuInfo?.menu_sub[categoryCode];
    if (categorySub) {
      founded = categorySub.some((genreSub) => {
        const isParentCategory = genreSub.menu.some((itemCode: string) => {
          return itemCode === dishCode;
        });
        if (
          // if the dish is found in a category, check if it's service flag = 1
          // if it is, then push its into to serviceRequiredItems
          isParentCategory &&
          sourceMenuInfo?.category[categoryCode]
        ) {
          foundCategoryCode = categoryCode;
          return true;
        }
      });
    }
    return founded;
  });

  return foundCategoryCode;
}

export function isDeviceIOS() {
  return /(iPad|iPhone|iPod)/g.test(window.navigator.userAgent);
}

export function stripHtml(html: string) {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}

export function animationRippleClickEvent(this: any, e: MouseEvent) {
  if (e.pageX === 0 && e.pageY === 0) {
    return;
  }

  const pageScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  const targetElem = document.elementFromPoint(e.pageX, e.pageY - pageScrollTop);
  const button = targetElem?.closest('.btn-plus, .btn-minus, .ara-radio, .ara-checkbox, .area-turn-back');
  if (button) {
    return;
  }

  const rippleWidth = 80;
  const rippleHeight = 80;

  const positionX = e.pageX - rippleWidth / 2;
  const positionY = e.pageY - pageScrollTop - rippleHeight / 2;

  // Add the element
  const ripple = document.createElement('span');
  ripple.classList.add('ripple');
  const bodyElem = document.getElementsByTagName('BODY')[0];
  bodyElem.append(ripple);

  ripple.style.width = `${rippleWidth}px`;
  ripple.style.height = `${rippleHeight}px`;
  ripple.style.top = `${positionY}px`;
  ripple.style.left = `${positionX}px`;

  ripple.classList.add('rippleAnimation');

  setTimeout(() => {
    bodyElem.removeChild(ripple);
  }, TAP_ANIMATION_TIME);
}

export function scrollAnimationTo(element: HTMLElement, to: number, duration: number) {
  const start = element.scrollTop,
    change = to - start,
    increment = 20;
  let currentTime = 0;

  const animateScroll = function () {
    currentTime += increment;
    const val = MathEaseInOutQuad(currentTime, start, change, duration);
    element.scrollTop = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
}

export const MathEaseInOutQuad = function (
  currentTime: number,
  startValue: number,
  changeInValue: number,
  duration: number
) {
  currentTime /= duration / 2;
  if (currentTime < 1) return (changeInValue / 2) * currentTime * currentTime + startValue;
  currentTime--;
  return (-changeInValue / 2) * (currentTime * (currentTime - 2) - 1) + startValue;
};

export function resetGenreNameSticky() {
  // reset status of genre-name
  const genreNameSticky = document.getElementById('genre-name-sticky');
  genreNameSticky?.classList.add('d-none');

  const genreNameEntereds = document.querySelectorAll('.genre-name.entered');
  genreNameEntereds.forEach((genreNameEntered) => {
    genreNameEntered.classList.remove('entered');
    (genreNameEntered as HTMLDivElement).innerHTML = (genreNameEntered as HTMLDivElement).dataset.genrename || '';
  });
}

export function getSourceKeywordData(
  modeCode: string,
  menuInfo: Payload,
  thMenuInfo: Payload | null,
  phMenuInfo: Payload | null
) {
  switch (modeCode) {
    case ALL_YOU_CAN_EAT_MENU_MODEID:
      return thMenuInfo?.keyword;
    case PITA_MODEID:
      return phMenuInfo?.keyword;
    default:
      return menuInfo.keyword;
  }
}

export function convertPriceInTaxToNoTax(value: number) {
  return Math.ceil((value * 100) / 110);
}

export function updateMainPageScrollTop() {
  const pageWrapper = document.querySelector('.page-wrapper') as HTMLElement;
  const genreNameSticky = document.querySelector('#genre-name-sticky') as HTMLElement;

  if (pageWrapper && genreNameSticky) {
    let scrollTop = pageWrapper.scrollTop;
    if (genreNameSticky.innerHTML.length > 0) {
      scrollTop -= genreNameSticky.clientHeight;
    }
    document.documentElement.style.setProperty('--main-page-scrolltop', `${scrollTop}`);
  }
}
