import {
  log,
  LOG_OP_TYPE_TOP_MENU_SCREEN_FAIR_DETAILS_TRANSITION,
  LOG_OP_TYPE_TOP_MENU_SCREEN_THUMBNAIL_IMAGE_SELECTION,
} from 'log/Log';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { playSound } from 'sound/Sound';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  attrLang,
  getModeCodeFromCategoryCode,
  getSourceMenuInfoByMode,
  imgFullPath,
  scrollAnimationTo,
} from 'utils/Utils';
import './scss/Top.scss';
import { DetailSetProps } from './types';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/lazy';
import 'swiper/css/pagination';
// import required modules
import { getTimeFrame } from 'apis/Api';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { AlertModalProps } from 'components/AlertModal/AlertModal';
import { GlobalModalContext } from 'components/GlobalModal';
import { selectThMenuInfo } from 'store/allYouCanEatSlice';
import { mainActions, selectCurrentModeCode, selectMenuInfo } from 'store/mainSlice';
import { selectPhMenuInfo } from 'store/pitaSlice';
import { selectTimeFrame, timeFrameActions } from 'store/timeFrameSlice';
import { Autoplay, Lazy, Navigation, Pagination, Swiper as SwiperCore } from 'swiper';
import SessionStorage, { SESSION_CURRENT_CATEGROY_CODE, SESSION_CURRENT_MODE_CODE } from 'utils/sessionStorage';
import {
  CAMPAIGN_BANNER_TRANSITION_TIME,
  LUNCH_MENU_MODEID,
  MODAL_TYPES,
  TAP_ANIMATION_TIME,
  TIME_FRAME_GRAND,
  TIME_FRAME_LUNCH,
} from '../../constants';

export interface TopProps {
  currentCategoryCodes: string[];
  mainSwiper?: SwiperCore;
}

export default function Top({ currentCategoryCodes, mainSwiper }: TopProps) {
  const { t } = useTranslation();
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();
  const timeFrame = useAppSelector(selectTimeFrame);
  const menuInfo = useAppSelector(selectMenuInfo);
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);
  const appDispatch = useAppDispatch();
  const currentModeCode = useAppSelector(selectCurrentModeCode);
  const sourceMenuInfo = getSourceMenuInfoByMode(currentModeCode, menuInfo, thMenuInfo, phMenuInfo);
  const modeInfo = menuInfo.mode[currentModeCode];
  const campaignCodes: string[] = modeInfo.campaign;
  const [currentCampaignCode, setCurrentCampaignCode] = useState(campaignCodes[0]);

  // set campaign code when change mode
  useEffect(() => {
    setCurrentCampaignCode(campaignCodes[0]);
  }, [campaignCodes]);

  const campaignData = sourceMenuInfo?.campaign;

  const genreData = menuInfo.genre;
  const currentCampaign = campaignData[currentCampaignCode];
  const currentGenreName = attrLang(genreData[currentCampaign?.genrecd], 'lang');

  useEffect(() => {
    log({
      op_type: LOG_OP_TYPE_TOP_MENU_SCREEN_THUMBNAIL_IMAGE_SELECTION,
      op_detail: {
        mode: currentModeCode,
        campaign: currentCampaignCode,
      },
    });
  }, [currentCampaignCode, currentModeCode]);

  const handleViewAll = () => {
    appDispatch(mainActions.setCurrentCategoryCode(currentCampaign?.categorycd));
    playSound('page');
    log({
      op_type: LOG_OP_TYPE_TOP_MENU_SCREEN_FAIR_DETAILS_TRANSITION,
      op_detail: {
        mode: currentModeCode,
        campaign: currentCampaignCode,
      },
    });
  };

  const ListOfCampaignThumbnails: JSX.Element[] = [];
  campaignCodes.forEach((campaignCode) => {
    const campaignInfo = campaignData[campaignCode];
    const imgSrc: string = imgFullPath(campaignInfo?.thumb);
    if (imgSrc !== '') {
      const handleClick = async () => {
        const campaignInfo = campaignData[campaignCode];
        const campaignModeCode = getModeCodeFromCategoryCode(menuInfo, campaignInfo?.categorycd);

        // Check The Lunch Time
        // if timeframe == lunch => go to category of mode lunch
        // if timeframe != lunch => show dialog to notify end of lunch time
        if (campaignModeCode == LUNCH_MENU_MODEID) {
          // Call API to check timeframe
          const updatedTimeFrameRes = await getTimeFrame();
          const updatedTimeFrame = parseInt(updatedTimeFrameRes.data.timeframe) || TIME_FRAME_GRAND;
          if (updatedTimeFrame !== timeFrame) {
            appDispatch(timeFrameActions.setTimeFrameData(updatedTimeFrameRes.data));
          }

          if (updatedTimeFrame !== TIME_FRAME_LUNCH) {
            playSound('confirm');
            showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
              message: t('App.lunch_menu_unavailable'),
            });
            return;
          }
        }

        setTimeout(() => {
          playSound('page');
          setCurrentCampaignCode(campaignCode);

          if (campaignModeCode === '') {
            return;
          }

          if (currentModeCode === campaignModeCode) {
            mainSwiper?.slideTo(currentCategoryCodes.indexOf(campaignInfo?.categorycd), 0, false);
            setTimeout(() => {
              if (campaignInfo?.genrecd !== '') {
                const genreMenuSection = document.getElementById(
                  `genre-menu-${campaignInfo?.genrecd}`
                ) as HTMLDivElement;

                if (genreMenuSection) {
                  const scrolltopElem = genreMenuSection.offsetTop - genreMenuSection.scrollTop;
                  scrollAnimationTo(
                    document.querySelector('.page-wrapper') as HTMLElement,
                    scrolltopElem > 0 ? scrolltopElem + 36 : 0,
                    600
                  );
                }

                appDispatch(mainActions.updateCampaignGenrecd(''));
              }
            }, 300);

            log({
              op_type: LOG_OP_TYPE_TOP_MENU_SCREEN_FAIR_DETAILS_TRANSITION,
              op_detail: {
                mode: currentModeCode,
                campaign: currentCampaign?.categorycd,
                genrecd: currentCampaign?.genrecd,
              },
            });
          } else {
            // setTimeout(() => {
            //   appDispatch(mainActions.setCurrentModeCode(campaignModeCode));
            // }, 300);
          }

          appDispatch(
            mainActions.updateCampaign({
              modeCode: campaignModeCode,
              categorycd: campaignInfo?.categorycd,
              genrecd: campaignInfo?.genrecd,
            })
          );

          SessionStorage.setItem(SESSION_CURRENT_MODE_CODE, campaignModeCode);
          SessionStorage.setItem(SESSION_CURRENT_CATEGROY_CODE, campaignInfo?.categorycd);
        }, TAP_ANIMATION_TIME / 2);
      };
      ListOfCampaignThumbnails.push(
        <SwiperSlide key={`campaign-thumb-${campaignCode}`} className="campaign-thumb" onClick={handleClick}>
          <img
            data-src={imgSrc}
            src="data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='600' height='400'><rect style='fill:rgb(255,255,255);' width='100%' height='100%' /></svg>"
            className="swiper-lazy campaign-thumb-image"
          />
          <div className="swiper-lazy-preloader"></div>
        </SwiperSlide>
      );
    }
  });

  return (
    <div id="top-menu">
      {ListOfCampaignThumbnails.length > 0 && (
        <Swiper
          autoHeight={false}
          preloadImages={true}
          loop={ListOfCampaignThumbnails.length !== 1}
          lazy={true}
          pagination={{
            clickable: false,
          }}
          slidesPerView={1}
          spaceBetween={8}
          navigation={false}
          autoplay={{ delay: CAMPAIGN_BANNER_TRANSITION_TIME }}
          modules={[Lazy, Pagination, Navigation, Autoplay]}
          className="campaign-swiper"
          nested
        >
          {ListOfCampaignThumbnails}
        </Swiper>
      )}
      {currentCampaignCode !== '' && (
        <div id="detail-campaign-block" className="d-none">
          <DetailCampaign campaignInfo={currentCampaign} name={currentGenreName} />
          {currentCampaignCode !== '' && (
            <div className="see-all-wrapper">
              <button id="see-all" type="button" className="btn btn-dark rounded-0" onClick={handleViewAll}>
                {t('Top.see_all_product_at_this_fair')}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function DetailCampaign({ campaignInfo, name }: DetailSetProps) {
  const img_src = imgFullPath(campaignInfo?.img);

  if (img_src !== '') {
    return (
      <div>
        {name != '' && <div id="campaign-name">{name}</div>}
        <div id="campaign-image-block">
          <LazyLoadImage wrapperClassName="d-block" alt="campaign_detail_img" effect="opacity" src={img_src} />
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}
