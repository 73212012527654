import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { convertPriceInTaxToNoTax } from 'utils/Utils';
import './scss/DishPrice.scss';

export interface DishPriceType {
  price: number;
}

export default function DishPrice({ price }: DishPriceType) {
  const { t } = useTranslation();
  return (
    <div className="dish-price">
      <span className="price-root">
        {t('OrderHistory.currency')}
        <NumberFormat
          className="price-root-value"
          value={convertPriceInTaxToNoTax(price)}
          displayType={'text'}
          thousandSeparator={true}
        />
      </span>
      <span className="price-tax">
        ({t('OrderHistory.tax_included')}&nbsp;
        {t('OrderHistory.currency')}
        <NumberFormat value={price} displayType={'text'} thousandSeparator={true} />)
      </span>
    </div>
  );
}
