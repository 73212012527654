import { useAppSelector } from 'app/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { selectThMenuInfo } from 'store/allYouCanEatSlice';
import { selectMenuInfo } from 'store/mainSlice';
import { selectPhMenuInfo } from 'store/pitaSlice';
import { Payload } from 'types';
import { attrLang, getDishInfo, getSourceMenuInfo } from 'utils/Utils';
import OptionItem from './OptionItem';

export interface OptionGroupProps {
  modeCode: string;
  itemCode: string;
  orderIdx: number;
  groupState: Payload;
}

function OptionGroup({ modeCode, itemCode, orderIdx, groupState }: OptionGroupProps) {
  const { t } = useTranslation();
  const menuInfo = useAppSelector(selectMenuInfo);
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);

  const { basic: groupBasic, detail: groupDetail } = groupState;
  const ListOfGroupDetail = Object.keys(groupDetail).map((optionCode) => {
    const sourceMenuInfo = getSourceMenuInfo(optionCode, menuInfo, thMenuInfo, phMenuInfo);
    const optionInfo = getDishInfo(sourceMenuInfo, optionCode);
    if (!optionInfo) return null;
    return (
      <OptionItem
        key={optionCode}
        modeCode={modeCode}
        itemCode={itemCode}
        orderIdx={orderIdx}
        groupInfo={groupBasic}
        menuItemInfo={optionInfo}
        menuItemState={groupDetail[optionCode]}
      />
    );
  });

  let kouseumText = t('MenuItemDetail.please_select_an_item').replace('{quantity}', groupBasic.kousenum);

  if (groupBasic.glassflg === '1') {
    kouseumText = t('MenuItemDetail.please_select_required_item');
  }

  return (
    <div id={`option-group-${itemCode}-${orderIdx}-${groupBasic.gno}`} className="menu-option-group">
      <div className="option-group-header">
        <span dangerouslySetInnerHTML={{ __html: attrLang(groupBasic, 'lang') }}></span>
        {groupBasic.kousenum > 1 && groupBasic.hisuflg === '1' && ` (${kouseumText})`}
      </div>
      <ul className="list-option-group">{ListOfGroupDetail}</ul>
    </div>
  );
}

export default OptionGroup;
