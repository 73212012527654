import React from 'react';
import './scss/Error.scss';

interface ErrorPageProps {
  message?: string;
}

export default function ErrorPage({ message = '' }: ErrorPageProps) {
  return (
    <div className="error-page">
      <strong dangerouslySetInnerHTML={{ __html: message }}></strong>
    </div>
  );
}
