/* eslint-disable react/react-in-jsx-scope */
interface AraTimesIconProps {
  size?: number | string;
  color?: string;
}
export const AraTimesIcon = ({ size = 12, color = 'white' }: AraTimesIconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.90625 10C0.523438 10.375 0.515625 11.0625 0.914062 11.4609C1.3125 11.8594 2 11.8438 2.375 11.4688L6 7.84375L9.625 11.4609C10.0156 11.8516 10.6875 11.8594 11.0859 11.4531C11.4844 11.0547 11.4844 10.3828 11.0938 9.99219L7.47656 6.375L11.0938 2.75C11.4844 2.35938 11.4844 1.6875 11.0859 1.28906C10.6875 0.882812 10.0156 0.890625 9.625 1.28125L6 4.89844L2.375 1.27344C2 0.898438 1.3125 0.882812 0.914062 1.28125C0.515625 1.67969 0.523438 2.36719 0.90625 2.75L4.53125 6.375L0.90625 10Z"
        fill={color}
      />
    </svg>
  );
};
