import { useAppDispatch, useAppSelector } from 'app/hooks';
import CouponFormModal from 'components/CouponFormModal/CouponFormModal';
import Header from 'components/Header/Header';
import MenuItemDetail from 'components/MenuDetail/MenuDetail';
import MenuSwipeList from 'components/MenuSwipeList/MenuSwipeList';
import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { mainActions, selectMenuInfo } from 'store/mainSlice';
import SessionStorage, { SESSION_MAIN_PAGE_LOADED } from 'utils/sessionStorage';
import './scss/MainPage.scss';

export interface DishModalContextProps {
  showDishModal?: (itemCode: string) => void;
  showCouponDishModal?: (itemCode: string, modeCode: string) => void;
  showCouponFormModal?: () => void;
}

export const DishModalContext = createContext<DishModalContextProps>({});
DishModalContext.displayName = 'DishModalContext';

export default function MainPage() {
  const appDispatch = useAppDispatch();
  const menuInfo = useAppSelector(selectMenuInfo);
  const cancelButton = useRef<HTMLButtonElement>(null);
  const [dishCode, setDishCode] = useState<string>('');
  const [couponValue, setCouponValue] = useState<{ dishCode: string; modeCode: string }>({
    dishCode: '',
    modeCode: '',
  });
  const [openCouponFormModal, setOpenCouponFormModal] = useState(false);
  const [openDishModal, setOpenDishModal] = useState(false);
  const [openCouponDishModal, setOpenCouponDishModal] = useState(false);

  useEffect(() => {
    appDispatch(mainActions.fetchOrderHistoryData());
  }, []);

  useEffect(() => {
    const pageWrapper = document.querySelector('.page-wrapper') as HTMLElement;
    setTimeout(() => {
      const scrollTop = Number(document.documentElement.style.getPropertyValue('--main-page-scrolltop'));

      if (pageWrapper) {
        pageWrapper.scrollTop = scrollTop;
      }
    }, 50);
  }, []);

  const handleBack = (e: PopStateEvent) => {
    e.preventDefault();

    handleRejectQuitApp();
  };

  useEffect(() => {
    if (SessionStorage.getItem(SESSION_MAIN_PAGE_LOADED) === null) {
      history.pushState(null, '', location.href);
      SessionStorage.setItem(SESSION_MAIN_PAGE_LOADED, 'true');
    }
    window.addEventListener('popstate', handleBack);

    const onBeforeUnload = () => {
      SessionStorage.removeItem(SESSION_MAIN_PAGE_LOADED);
    };
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('popstate', handleBack);
      window.removeEventListener('beforeunload', onBeforeUnload);
      SessionStorage.removeItem(SESSION_MAIN_PAGE_LOADED);
    };
  }, []);

  const handleCancel = () => {
    history.go(history.length * -1);
    // remove handle event popstate
    window.removeEventListener('popstate', handleBack);
    // clear history
    window.location.replace(location.href);
    // process back to previous page
    history.go(-1);
    history.go(-1);
    SessionStorage.removeItem(SESSION_MAIN_PAGE_LOADED);
    return false;
  };

  useEffect(() => {
    cancelButton.current?.addEventListener('click', () => setTimeout(handleCancel, 0));
    return () => cancelButton.current?.removeEventListener('click', () => setTimeout(handleCancel, 0));
  }, [menuInfo]);

  const handleRejectQuitApp = () => {
    history.pushState(null, '', location.href);
  };

  const showDishModal = useCallback(
    (newDishCode: string) => {
      setDishCode(newDishCode);

      // detect position of dish modal to show
      const pageWrapper = document.querySelector('.page-wrapper') as HTMLElement;
      if (pageWrapper) {
        document.documentElement.style.setProperty('--position-modal-top', `${pageWrapper.scrollTop}px`);
        pageWrapper.style.overflowY = 'hidden';
      }

      // show dish modal
      setOpenDishModal(true);
    },
    [dishCode]
  );

  const closeCouponFormModal = useCallback(() => {
    const couponButtonElem = document.querySelector('.coupon-button') as HTMLButtonElement;
    if (couponButtonElem) {
      couponButtonElem.disabled = false;
    }

    setOpenCouponFormModal(false);
  }, []);

  const closeDishModal = useCallback(() => {
    setOpenDishModal(false);
  }, []);

  const closeCouponDishModal = useCallback(() => {
    setOpenCouponDishModal(false);
  }, []);

  const showCouponDishModal = useCallback(
    (dishCode: string, modeCode: string) => {
      setCouponValue({
        dishCode: dishCode,
        modeCode: modeCode,
      });

      // detect position of dish modal to show
      const pageWrapper = document.querySelector('.page-wrapper') as HTMLElement;
      if (pageWrapper) {
        document.documentElement.style.setProperty('--position-modal-top', `${pageWrapper.scrollTop}px`);
        pageWrapper.style.overflowY = 'hidden';
      }

      // show dish modal
      setOpenCouponDishModal(true);
    },
    [couponValue]
  );

  const showCouponFormModal = useCallback(() => {
    // detect position of dish modal to show
    const pageWrapper = document.querySelector('.page-wrapper') as HTMLElement;
    if (pageWrapper) {
      document.documentElement.style.setProperty('--position-modal-top', `${pageWrapper.scrollTop}px`);
      pageWrapper.style.overflowY = 'hidden';
    }

    // show dish modal
    setOpenCouponFormModal(true);
  }, []);

  return (
    <DishModalContext.Provider
      value={{
        showDishModal: showDishModal,
        showCouponDishModal: showCouponDishModal,
        showCouponFormModal: showCouponFormModal,
      }}
    >
      <Header />
      <div className="main-content">
        <MenuSwipeList />
      </div>
      <CouponFormModal open={openCouponFormModal} onClose={closeCouponFormModal} />
      <MenuItemDetail open={openDishModal} itemCode={dishCode} onClose={closeDishModal} />
      <MenuItemDetail
        open={openCouponDishModal}
        modeCode={couponValue.modeCode}
        itemCode={couponValue.dishCode}
        onClose={closeCouponDishModal}
      />
    </DishModalContext.Provider>
  );
}
