import { getPhRemainTm, getTimeFrame } from 'apis/Api';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { AlertModalProps } from 'components/AlertModal/AlertModal';
import { GlobalModalContext } from 'components/GlobalModal';
import RemainButton from 'components/RemainButton/RemainButton';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mainActions, selectMenuInfo } from 'store/mainSlice';
import { pitaActions, selectPhCourseMenu, selectPitaState } from 'store/pitaSlice';
import { timeFrameActions } from 'store/timeFrameSlice';
import { AraAllDrinkIcon, AraAllEatIcon } from 'theme/icons';
import { Payload } from 'types';
import SessionStorage, { SESSION_CURRENT_CATEGROY_CODE, SESSION_CURRENT_MODE_CODE } from 'utils/sessionStorage';
import { getInitalModeCode } from 'utils/Utils';
import {
  CATEGORY_TOP_MENU_ID,
  MODAL_TYPES,
  PH_INTERVAL_TIME_CHECK_REMAIN_TIME,
  RES_CODE_SUCCESS,
  TIME_FRAME_GRAND,
} from '../../constants';
import './scss/PitaBoard.scss';

function PitaBoard() {
  const appDispatch = useAppDispatch();
  const { t } = useTranslation();
  const menuInfo = useAppSelector(selectMenuInfo);
  const phCourseMenu = useAppSelector(selectPhCourseMenu);
  const pitaState = useAppSelector(selectPitaState);
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();
  const timeInterval = useRef<NodeJS.Timer | null>(null);

  const [remainTimeValue, setRemainTimeValue] = useState({
    phRemainTime: phCourseMenu?.ph_limit,
    nhRemainTime: phCourseMenu?.nh_limit,
  });

  const getMessageNearOverTimeByMilestones = (remainTimeData: Payload, milestones: number[]): string => {
    const messages: string[] = [];
    // eat
    if (phCourseMenu?.ph_flg === '1' && milestones.includes(remainTimeData.ph_remain_tm)) {
      let eatMessage = t('Pita.message_near_end_time');
      eatMessage = eatMessage.replace(/{BBB}/g, t('Pita.all_you_can_eat'));
      eatMessage = eatMessage.replace(/{CCC}/g, remainTimeData.ph_remain_tm + '');
      messages.push(eatMessage);
    }

    // drink
    if (phCourseMenu?.nh_flg === '1' && milestones.includes(remainTimeData.nh_remain_tm)) {
      let drinkMessage = t('Pita.message_near_end_time');
      drinkMessage = drinkMessage.replace(/{BBB}/g, t('Pita.all_you_can_drink'));
      drinkMessage = drinkMessage.replace(/{CCC}/g, remainTimeData.nh_remain_tm + '');
      messages.push(drinkMessage);
    }

    if (messages.length > 0) {
      return messages.join('<br />');
    }

    return '';
  };

  const checkRemainTime = () => {
    //todo: check remain time was end
    getPhRemainTm().then(async (response) => {
      if (response.code !== RES_CODE_SUCCESS) {
        return;
      }

      const data = response.data;
      setRemainTimeValue(() => ({
        phRemainTime: data.ph_remain_tm,
        nhRemainTime: data.nh_remain_tm,
      }));

      const isShowEatRemainTime =
        phCourseMenu?.ph_flg === '0' ||
        (phCourseMenu?.ph_flg === '1' && (data.ph_remain_tm === 0 || data.ph_sts === '0'))
          ? false
          : true;
      const isShowDrinkRemainTime =
        phCourseMenu?.nh_flg === '0' ||
        (phCourseMenu?.nh_flg === '1' && (data.nh_remain_tm === 0 || data.nh_sts === '0'))
          ? false
          : true;

      appDispatch(pitaActions.updateStatus({ isShowEatRemainTime, isShowDrinkRemainTime }));

      // show dialog at 5, 10 minutes
      const messageFirstNear = getMessageNearOverTimeByMilestones(data, [5, 10]);
      if (messageFirstNear != '') {
        showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
          message: messageFirstNear,
        });
      }

      if (!isShowEatRemainTime && isShowDrinkRemainTime) {
        //if eat was over, drink still remain
        if (phCourseMenu?.ph_limit > 0) {
          //update ph_limit = 0 of phCourseMenu
          let newPhCourseMenu = phCourseMenu;
          if (newPhCourseMenu) {
            newPhCourseMenu = {
              ...phCourseMenu,
              ph_limit: 0,
            };
            appDispatch(pitaActions.setPhCourseMenu(newPhCourseMenu));
          }

          let message = t('Pita.message_one_of_them_over_time');
          message = message.replace(/{AAA}/g, t('Pita.all_you_can_eat'));
          message = message.replace(/{BBB}/g, t('Pita.all_you_can_drink'));
          message = message.replace(/{CCC}/g, data.nh_remain_tm + '');

          //show message
          showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
            message: message,
          });
        }
      } else if (isShowEatRemainTime && !isShowDrinkRemainTime) {
        //if drink was over, eat still remain
        if (phCourseMenu?.nh_limit > 0) {
          //update nh_limit = 0 of phCourseMenu
          let newPhCourseMenu = phCourseMenu;
          if (newPhCourseMenu) {
            newPhCourseMenu = {
              ...phCourseMenu,
              nh_limit: 0,
            };
            appDispatch(pitaActions.setPhCourseMenu(newPhCourseMenu));
          }

          let message = t('Pita.message_one_of_them_over_time');
          message = message.replace(/{AAA}/g, t('Pita.all_you_can_drink'));
          message = message.replace(/{BBB}/g, t('Pita.all_you_can_eat'));
          message = message.replace(/{CCC}/g, data.ph_remain_tm + '');

          //show message
          showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
            message: message,
          });
        }
      } else if (!isShowEatRemainTime && !isShowDrinkRemainTime) {
        // eat and drink were over
        let message = t('Pita.message_over_time');
        if (phCourseMenu?.ph_limit > 0 && phCourseMenu?.nh_limit > 0) {
          message = message.replace(/{AAA}/g, '');
        } else {
          if (phCourseMenu?.ph_limit === 0) {
            message = message.replace(/{AAA}/g, t('Pita.all_you_can_drink'));
          }

          if (phCourseMenu?.nh_limit === 0) {
            message = message.replace(/{AAA}/g, t('Pita.all_you_can_eat'));
          }
        }

        //show message
        showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
          message: message,
        });

        //update ph_limit = 0, nh_limit = 0 of phCourseMenu
        let newPhCourseMenu = phCourseMenu;
        if (newPhCourseMenu) {
          newPhCourseMenu = {
            ...phCourseMenu,
            ph_limit: 0,
            nh_limit: 0,
          };
          appDispatch(pitaActions.setPhCourseMenu(newPhCourseMenu));
        }

        //change mode
        const timeFrameRes = await getTimeFrame();

        const timeFrame = parseInt(timeFrameRes.data.timeframe) || TIME_FRAME_GRAND;
        const initialModeCode = getInitalModeCode(menuInfo, timeFrame, null, newPhCourseMenu);
        appDispatch(timeFrameActions.setTimeFrameData(timeFrameRes.data));

        // save modeCode and categoryCode to SessionStorage and main store
        appDispatch(mainActions.setCurrentModeCode(initialModeCode));
        SessionStorage.setItem(SESSION_CURRENT_MODE_CODE, initialModeCode);
        SessionStorage.setItem(SESSION_CURRENT_CATEGROY_CODE, CATEGORY_TOP_MENU_ID);
      }
    });
  };

  useEffect(() => {
    timeInterval.current = setInterval(() => {
      checkRemainTime();
    }, PH_INTERVAL_TIME_CHECK_REMAIN_TIME);

    return () => {
      clearInterval(timeInterval.current as NodeJS.Timer);
    };
  }, []);

  useEffect(() => {
    checkRemainTime();
  }, []);

  return (
    <div className="remain-button-list">
      {pitaState.isShowEatRemainTime && remainTimeValue.phRemainTime > 0 && (
        <div className="remain-button-item">
          <RemainButton
            text={t('Pita.all_you_can_eat')}
            value={remainTimeValue.phRemainTime}
            icon={<AraAllEatIcon size={36} />}
          />
        </div>
      )}

      {pitaState.isShowDrinkRemainTime && remainTimeValue.nhRemainTime > 0 && (
        <div className="remain-button-item">
          <RemainButton
            text={t('Pita.all_you_can_drink')}
            value={remainTimeValue.nhRemainTime}
            icon={<AraAllDrinkIcon size={36} />}
          />
        </div>
      )}
    </div>
  );
}

export default PitaBoard;
