/* eslint-disable react/react-in-jsx-scope */
interface AraHistoryIconProps {
  size?: number | string;
  color?: string;
}
export const AraHistoryIcon = ({ size = 24, color = '#F27900' }: AraHistoryIconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 2C7.5 1.44772 7.94772 1 8.5 1H15.5C16.0523 1 16.5 1.44772 16.5 2V2.5H19C20.1046 2.5 21 3.39544 21 4.5V21C21 22.1046 20.1046 23 19 23H5C3.89544 23 3 22.1046 3 21V4.5C3 3.39543 3.89543 2.5 5 2.5H7.5V2ZM9.5 4V3H14.5V4H9.5ZM16.5 4.5V5C16.5 5.55228 16.0523 6 15.5 6H8.5C7.94772 6 7.5 5.55228 7.5 5V4.5H5V21H19V4.5H16.5Z"
        fill={color}
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.5 4V3H14.5V4H9.5Z" fill={color} />
      <line x1="7.75" y1="9.25" x2="16.25" y2="9.25" stroke="#F27900" strokeWidth="1.5" strokeLinecap="round" />
      <line x1="7.75" y1="13.25" x2="16.25" y2="13.25" stroke="#F27900" strokeWidth="1.5" strokeLinecap="round" />
      <line x1="7.75" y1="17.25" x2="16.25" y2="17.25" stroke="#F27900" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
