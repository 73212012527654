import { AraLogoIcon } from 'theme/icons';

export interface AppLoadingProps {
  isLoadingApp?: boolean;
}

function AppLoading({ isLoadingApp = false }: AppLoadingProps) {
  return (
    <div
      className={`loading-app ${
        isLoadingApp ? 'main' : ''
      } container-fluid d-flex align-items-center justify-content-center`}
    >
      <div className="row text-center">
        <div className="col d-flex flex-column text-center align-items-center align-self-center">
          {isLoadingApp && (
            <div className="mb-3">
              <AraLogoIcon width={178.44} height={56.59} />
            </div>
          )}
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppLoading;
