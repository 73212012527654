import { Payload } from 'types';
import { nowStr } from '../utils/Utils';

export const LOG_OP_TYPE_APP_START_COMPLETION = '1';
export const LOG_OP_TYPE_MODE_SWITCHING = '2';
export const LOG_OP_TYPE_CATEGORY_SWITCHING = '3';
export const LOG_OP_TYPE_MENU_LIST_MENU_SELECTION = '4';
export const LOG_OP_TYPE_MENU_LIST_ADD_CANCEL_FAVORITES = '5';
export const LOG_OP_TYPE_MENU_DETAILS_CLOSE = '6';
export const LOG_OP_TYPE_MENU_DETAILS_QUANTITY_CHANGE = '7';
export const LOG_OP_TYPE_MENU_DETAILS_ADDITIONAL_CART = '8';
export const LOG_OP_TYPE_MENU_DETAILS_SUBMENU_SELECTION = '9';
export const LOG_OP_TYPE_MENU_DETAILS_CHANGE_THE_QUANTITY_OF_SUBMENUS = '10';
export const LOG_OP_TYPE_MENU_DETAILS_SWITCHING_BETWEEN_MULTIPLE_SUBMENUS = '11';
export const LOG_OP_TYPE_MENU_DETAILS_DELETE_MULTIPLE_SUBMENUS = '12';
export const LOG_OP_TYPE_CART_SCREEN_EDIT = '13';
export const LOG_OP_TYPE_CART_SCREEN_QUANTITY_CHANGE = '14';
export const LOG_OP_TYPE_CART_SCREEN_BACK = '15';
export const LOG_OP_TYPE_CART_SCREEN_SEND = '16';
export const LOG_OP_TYPE_ORDER_HISTORY_SCREEN_REORDER = '17';
export const LOG_OP_TYPE_ORDER_HISTORY_SCREEN_CLOSE = '18';
export const LOG_OP_TYPE_ORDER_HISTORY_SCREEN_ACCOUNTING = '19';
export const LOG_OP_TYPE_HEADER_MENU_SEARCH = '20';
export const LOG_OP_TYPE_HEADER_FREQUENTLY_ORDERED_MENU_DISPLAY = '21';
export const LOG_OP_TYPE_HEADER_ORDER_HISTORY_DISPLAY = '22';
export const LOG_OP_TYPE_TOP_MENU_SCREEN_THUMBNAIL_IMAGE_SELECTION = '23';
export const LOG_OP_TYPE_TOP_MENU_SCREEN_SELECT_MENU_FOR_CAMPAIGN_DETAILS = '24';
export const LOG_OP_TYPE_TOP_MENU_SCREEN_FAIR_DETAILS_TRANSITION = '25';
export const LOG_OP_TYPE_FLOATING_WINDOW_CART_TRANSITION = '26';

export const ADD_FAVORITES = '1';
export const CANCEL_FAVORITES = '0';

export function log(inLogData: Payload): void {
  const logData = inLogData;
  logData.op_dt = nowStr();

  const storageLogs = JSON.parse(localStorage.getItem('logs') || '[]');
  storageLogs.push(logData);
  localStorage.setItem('logs', JSON.stringify(storageLogs));
}
