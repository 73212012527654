import { AxiosError } from 'axios';
import { all, put } from 'redux-saga/effects';
import customerQuantitySaga from 'store/customerQuantitySaga';
import { errorNotificationActions } from 'store/errorNotificationSlice';
import mainSaga from 'store/mainSaga';
import timeFrameSaga from 'store/timeFrameSaga';
import { Payload } from 'types';

export function* handleHttpError(e: AxiosError) {
  yield put(errorNotificationActions.setError(e));
}

export function* handleResponseError(data: Payload) {
  yield put(errorNotificationActions.setResponse(data));
}

export default function* rootSaga() {
  yield all([mainSaga(), timeFrameSaga(), customerQuantitySaga()]);
}
