/* eslint-disable react/react-in-jsx-scope */
interface AraAllDrinkconProps {
  size?: number | string;
}
export const AraAllDrinkIcon = ({ size = 40 }: AraAllDrinkconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="18" stroke="#F27900" strokeWidth="3" />
      <rect x="14" y="14" width="11" height="15" rx="1" stroke="white" strokeWidth="2" />
      <path
        d="M14 13C14 11.8954 14.8954 11 16 11H23C24.1046 11 25 11.8954 25 13V14H14V13Z"
        fill="white"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M26 15.5H28.5C29.0523 15.5 29.5 15.9477 29.5 16.5V21.4116C29.5 21.7748 29.3031 22.1094 28.9856 22.2858L25 24.5"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};
