/* eslint-disable react/react-in-jsx-scope */
import { log, LOG_OP_TYPE_HEADER_ORDER_HISTORY_DISPLAY } from 'log/Log';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { playSound } from 'sound/Sound';
import { AraHistoryIcon } from 'theme/icons';
import { updateMainPageScrollTop } from 'utils/Utils';
import { TAP_ANIMATION_TIME } from '../../constants';
import './scss/HistoryButton.scss';

export default function HistoryButton() {
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    // check button has disabled status, prevent click
    if (buttonRef.current) {
      if (buttonRef.current.disabled) {
        return;
      }
    }

    // change status of button to disabled
    if (buttonRef.current) buttonRef.current.disabled = true;

    setTimeout(() => {
      log({
        op_type: LOG_OP_TYPE_HEADER_ORDER_HISTORY_DISPLAY,
        op_detail: {},
      });
      playSound('page');
      updateMainPageScrollTop();
      navigate('/order-history', { replace: true });
    }, TAP_ANIMATION_TIME / 2);
  };

  return (
    <div className="history-button">
      <button ref={buttonRef} onClick={handleClick}>
        <AraHistoryIcon />
      </button>
    </div>
  );
}
