import { checkUserCheckout } from 'apis/Api';
import { CHECK_SLIP_STATUS_INTERVAL, RES_CODE_SUCCESS } from '../constants';
import { useEffect, useRef, useState } from 'react';
import { dispatchErrorResponse } from 'utils/Utils';
import { GlobalModalContext } from 'components/GlobalModal';

function useCheckSlipStatus() {
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();
  const [slipStatus, setSlipStatus] = useState<number | undefined>(1);
  const interval = useRef<NodeJS.Timer | null>(null);

  function handleCheckClipStatus() {
    checkUserCheckout()
      .then((slipStatusRes) => {
        if (slipStatusRes.code !== RES_CODE_SUCCESS) {
          dispatchErrorResponse(showGlobalModal, slipStatusRes);
        } else {
          if (slipStatus !== Number(slipStatusRes.slip_sts)) {
            setSlipStatus(Number(slipStatusRes.slip_sts));
          }
        }
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    handleCheckClipStatus();

    interval.current = setInterval(() => {
      handleCheckClipStatus();
    }, CHECK_SLIP_STATUS_INTERVAL);

    return () => clearInterval(interval.current as NodeJS.Timer);
  }, []);

  return slipStatus;
}

export default useCheckSlipStatus;
