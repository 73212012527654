import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { Payload } from 'types';

export interface CustomerQuantityState {
  loading: boolean;
  data: number;
}

const initialState: CustomerQuantityState = {
  loading: false,
  data: 0,
};

const customerQuantitySlice = createSlice({
  name: 'customer_quantity',
  initialState,
  reducers: {
    setData(state, action: PayloadAction<number>) {
      state.data = action.payload;
    },
    fetchCustomerQuantityData(state) {
      state.loading = true;
    },
    fetchCustomerQuantityDataSuccess(state, action: PayloadAction<Payload>) {
      state.data = Number(action.payload.data);
      state.loading = false;
    },
    fetchCustomerQuantityDataFailed(state) {
      state.loading = false;
      state.data = 0;
    },
  },
});

// Actions
export const customerQuantityActions = customerQuantitySlice.actions;

// Selectors
export const selectCustomerQuantityLoading = (state: RootState) => state.customerQuantity.loading;
export const selectCutomerQuantity = (state: RootState) => state.customerQuantity.data;

// Reducer
export const customerQuantityReducer = customerQuantitySlice.reducer;
