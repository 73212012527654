import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { Payload, PitaType } from 'types';

export interface PitaState {
  loading: boolean;
  phMenuInfo: Payload | null;
  phCourseMenu: Payload | null;
  pitaState: PitaType;
}

const initialState: PitaState = {
  loading: true,
  phMenuInfo: null,
  phCourseMenu: null,
  pitaState: {
    isShowEatRemainTime: false,
    isShowDrinkRemainTime: false,
  },
};

const pitaSlice = createSlice({
  name: 'pita',
  initialState,
  reducers: {
    initData(state, action: PayloadAction<Payload>) {
      state.phMenuInfo = action.payload.phMenuInfo;
      state.phCourseMenu = action.payload.phCourseMenu;
      state.pitaState.isShowEatRemainTime = state.phCourseMenu?.ph_flg === '1' && state.phCourseMenu?.ph_limit > 0;
      state.pitaState.isShowDrinkRemainTime = state.phCourseMenu?.nh_flg === '1' && state.phCourseMenu?.nh_limit > 0;
      state.loading = false;
    },
    setPhCourseMenu(state, action: PayloadAction<Payload>) {
      state.phCourseMenu = action.payload;
      state.pitaState.isShowEatRemainTime = state.phCourseMenu?.ph_flg === '1' && state.phCourseMenu?.ph_limit > 0;
      state.pitaState.isShowDrinkRemainTime = state.phCourseMenu?.nh_flg === '1' && state.phCourseMenu?.nh_limit > 0;
    },
    updateStatus(state, action: PayloadAction<{ isShowEatRemainTime: boolean; isShowDrinkRemainTime: boolean }>) {
      state.pitaState.isShowEatRemainTime = action.payload.isShowEatRemainTime;
      state.pitaState.isShowDrinkRemainTime = action.payload.isShowDrinkRemainTime;
    },
  },
});

// Actions
export const pitaActions = pitaSlice.actions;

// Selectors
export const selectPitaLoading = (state: RootState) => state.pita.loading;
export const selectPhMenuInfo = (state: RootState) => state.pita.phMenuInfo;
export const selectPhCourseMenu = (state: RootState) => state.pita.phCourseMenu;
export const selectIsShowRemainPita = (state: RootState) =>
  state.pita.phCourseMenu !== null &&
  (state.pita.pitaState.isShowEatRemainTime || state.pita.pitaState.isShowDrinkRemainTime);
export const selectPitaState = (state: RootState) => state.pita.pitaState;

// Reducer
export const pitaReducer = pitaSlice.reducer;
