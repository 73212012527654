import ReactDOM from 'react-dom';
import App from './App';
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import "bootstrap/js/dist/collapse";
// import 'bootstrap/js/dist/modal';
import GlobalModal from 'components/GlobalModal';
import { Provider } from 'react-redux';
import { store } from './app/store';
import './theme/styles/index.scss';

ReactDOM.render(
  <Provider store={store}>
    <GlobalModal>
      <App />
    </GlobalModal>
  </Provider>,
  document.getElementById('root')
);
