import ErrorPage from 'pages/ErrorPage/ErrorPage';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCustomerId, getToken } from 'utils/Utils';

interface ProtectedPageProps {
  children: React.ReactNode;
}

function ProtectedPage({ children }: ProtectedPageProps) {
  const [active, setActive] = useState(true);
  const { t } = useTranslation();

  const checkAppActive = () => {
    const customer_id = getCustomerId();
    const token = getToken();

    if (customer_id === -1 || token === '') {
      setActive(false);
    }
  };

  useEffect(() => {
    window.onload = function () {
      checkAppActive();
    };

    window.addEventListener('storage', checkAppActive);

    return () => {
      window.removeEventListener('storage', checkAppActive);
    };
  }, []);

  return active ? <>{children}</> : <ErrorPage message={t('App.app_not_launch')} />;
}

export default ProtectedPage;
