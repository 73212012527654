import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AraArrowLeftIcon, AraLogoIcon } from 'theme/icons';
import './scss/PageHeader.scss';

type PageHeaderProps = {
  handleGoBack: () => void;
};

export default function PageHeader({ handleGoBack }: PageHeaderProps) {
  const { t } = useTranslation();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleBack = () => {
    // check button has disabled status, prevent click
    if (buttonRef.current) {
      if (buttonRef.current.disabled) {
        return;
      }
    }

    // change status of button to disabled
    if (buttonRef.current) buttonRef.current.disabled = true;

    handleGoBack();
  };

  return (
    <div className="cart-header">
      <button className="area-turn-back" ref={buttonRef} onClick={handleBack}>
        <AraArrowLeftIcon />
        <span className="text">{t('Cart.turn_back')}</span>
      </button>
      <div className="logo">
        <AraLogoIcon />
      </div>
    </div>
  );
}
