/* eslint-disable react/react-in-jsx-scope */
interface AraCartIconProps {
  size?: number | string;
  color?: string;
}
export const AraCartIcon = ({ size = 24, color = '#FFEF40' }: AraCartIconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 3C0.5 2.44772 0.947715 2 1.5 2H3.25C3.70887 2 4.10885 2.3123 4.22014 2.75746L4.78078 5H22C22.3079 5 22.5987 5.14187 22.7882 5.38459C22.9777 5.6273 23.0448 5.94379 22.9701 6.24254L20.4701 16.2425C20.3589 16.6877 19.9589 17 19.5 17C20.8807 17 22 18.1193 22 19.5C22 20.8807 20.8807 22 19.5 22C18.1193 22 17 20.8807 17 19.5C17 18.1193 18.1193 17 19.5 17H6.5C7.88071 17 9 18.1193 9 19.5C9 20.8807 7.88071 22 6.5 22C5.11929 22 4 20.8807 4 19.5C4 18.1193 5.11929 17 6.5 17C6.04113 17 5.64115 16.6877 5.52986 16.2425L2.46922 4H1.5C0.947715 4 0.5 3.55228 0.5 3Z"
        fill={color}
      />
    </svg>
  );
};
