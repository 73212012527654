//import { push } from 'connected-react-router';
import { getTimeFrame } from 'apis/Api';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Payload } from 'types';
import { timeFrameActions } from './timeFrameSlice';

function* fetchTimeFrameData() {
  try {
    const response: Payload = yield call(getTimeFrame);
    yield put(timeFrameActions.fetchTimeFrameDataSuccess(response));
  } catch (e) {
    yield put(timeFrameActions.fetchTimeFrameDataFailed());
  }
}

export default function* timeFrameSaga() {
  yield takeLatest(timeFrameActions.fetchTimeFrameData.type, fetchTimeFrameData);
}
