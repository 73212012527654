import { useAppSelector } from 'app/hooks';
import DishPrice from 'components/DishPrice/DishPrice';
import { PortionStateType } from 'components/MenuDetail/types';
import { log, LOG_OP_TYPE_CART_SCREEN_EDIT } from 'log/Log';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { playSound } from 'sound/Sound';
import { selectThMenuInfo } from 'store/allYouCanEatSlice';
import { selectMenuInfo } from 'store/mainSlice';
import { selectPhMenuInfo } from 'store/pitaSlice';
import {
  attrLang,
  getDishInfo,
  getOrderCost,
  getSourceMenuInfo,
  getSourceMenuInfoByMode,
  imgFullPath,
  stripHtml,
  summarizeOrderState,
} from 'utils/Utils';
import { CartDishModalContext } from './DishGroup';
import { DishQuantitySelector } from './DishQuantitySelector';
import 'react-lazy-load-image-component/src/effects/blur.css';

export interface DishProps {
  editable: boolean;
  dishKey: string;
  state: PortionStateType;
  quantity: number;
  updateSelectedDishState: (dishState: PortionStateType) => void;
}

export default function Dish({ editable, dishKey, state, quantity, updateSelectedDishState }: DishProps) {
  const { showDishModal } = useContext(CartDishModalContext);
  const menuInfo = useAppSelector(selectMenuInfo);
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);
  const [modeCode, code] = JSON.parse(dishKey);
  const { t } = useTranslation();

  const sourceMenuInfo = getSourceMenuInfoByMode(modeCode, menuInfo, thMenuInfo, phMenuInfo);
  const compactState = summarizeOrderState(state);
  const dishInfo = getDishInfo(sourceMenuInfo, code);
  const orderName = stripHtml(attrLang(dishInfo, 'lang'));
  const imgSrc = imgFullPath(dishInfo?.img);
  const dishPrice = getOrderCost(state, sourceMenuInfo, code);

  const handleShowModal = () => {
    updateSelectedDishState(state);
    showDishModal && showDishModal();
    log({
      op_type: LOG_OP_TYPE_CART_SCREEN_EDIT,
      op_detail: {
        poscd: code,
      },
    });

    playSound('page');
  };

  const ListOfSelectedOption = Object.entries(compactState).map(([optionCode, optionQuantity]) => {
    const sourceMenuOptionInfo = getSourceMenuInfo(optionCode, menuInfo, thMenuInfo, phMenuInfo);
    const optionInfo = getDishInfo(sourceMenuOptionInfo, optionCode);
    const optionName = attrLang(optionInfo, 'lang');
    return <li key={optionCode}>{`${optionName} (${optionQuantity * quantity})`}</li>;
  });

  return (
    <div className="card dish-card">
      <div className="row g-0">
        <div className="col-6">
          <div className="img-cover" onClick={editable ? handleShowModal : undefined}>
            <LazyLoadImage
              wrapperClassName="d-block"
              className="img-fluid rounded-start"
              alt="..."
              effect="opacity"
              src={imgSrc}
            />
          </div>
        </div>
        <div className="col-6 d-flex flex-column justify-content-between">
          <div className="card-content">
            <div onClick={editable ? handleShowModal : undefined}>
              <h3>{orderName}</h3>
              {ListOfSelectedOption.length > 0 && <ul>{ListOfSelectedOption}</ul>}
              <DishPrice price={dishPrice} />
            </div>
          </div>
          <div className="card-quantity d-flex">
            <div className="align-self-center text-quantity">{t('Cart.quantity')}</div>
            <div>
              <DishQuantitySelector dishInfo={dishInfo} dishKey={dishKey} addedState={state} quantity={quantity} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
