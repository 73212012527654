import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import SessionStorage, { SESSION_LANGUAGE_CODE } from 'utils/sessionStorage';
// import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    lng: SessionStorage.getItem(SESSION_LANGUAGE_CODE) || 'jp',
    fallbackLng: 'jp',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      us: {
        translation: {
          App: {
            language: 'Language',
            max_coupon_num_exceeded: 'You have exceeded the limit for the number of usable coupons.',
            max_coupon_exceed_limit: 'You have exceeded the limit for the number of usable coupons. \n No more coupons can be used.',
            agree: 'Yes',
            no: 'No',
            close_modal: 'Close',
            lunch_menu_unavailable: 'Lunch menu time has ended. Please continue enjoying the Grand Menu.',
            invalid_item_exist: 'Your order includes invalid items.',
            app_not_launch: 'The ordering app failed to start.',
            confirm_quit_app: 'You are exiting smartphone ordering. <br/> OK to proceed?',
            confirm_delete_item: 'OK to delete <br/> this item?',
            warning_age_limit: 'Alcohol is not served to under 20-year olds, expectant mothers, and drivers.',
            genre_freq_ordered_menu: 'Frequently ordered items',
            genre_search_menu: 'Search result',
          },
          Top: {
            see_all_product_at_this_fair: 'See all items in this Fair.',
          },
          Header: {
            freq_ordered_menu: 'Frequently ordered items',
            search_menu: 'Menu search',
            order_history: 'Order history',
            use_counpon: 'Coupon',
            search: 'Search',
          },
          MenuList: {
            call_staff: 'Please call a waiter to place an order.',
            lunch_dish_needed: 'Usable by ordering from the Lunch Menu.',
            set_dish_needed: 'Usable by ordering a set meal.',
            lunch_time_only: 'Can only be ordered during lunch time.',
            lunch_time_excluded: 'Please order from the lunch prices menu during lunch time.',
            search_no_result: 'No search results found. <br/> Please check your search terms.',
            out_of_stock: 'Sold out',
          },
          MenuItemDetail: {
            add_to_cart: 'Add to list of orders.',
            delete_portion: 'Cancel (A).',
            portion_name: 'Choose individual items',
            please_select_an_item: 'Please choose {quantity} items.',
            please_select_required_item: 'Please choose the quantity. ',
            pay_menu_in_you_can_eat_time_confirm_message: 'Additional charge(s) will be added as item(s) outside the all-you-can-eat menu are included. <br/> OK to proceed?',
            exceed_quantity_warning: 'The number of items you can order at one time has been exceeded. <br/> Please reselect them after ordering the items in the cart.',
            select_more_items_message: 'Please select {1} {0}. <br/>',
            kind: 'Item(s)',
          },
          OrderHistory: {
            title: 'Order history',
            load_fail: 'An error occurred when acquiring the order history.',
            order_number: 'Order quantity',
            order_name: 'Name of item',
            order_type: 'Category',
            quantity: 'Quantity',
            cost: 'Price (tax excluded)',
            status: 'Situation',
            re_order: 'Reorder',
            total_price: 'Total amount (tax included)',
            cancel: 'Cancel',
            confirm_cancel: 'Do you want to cancel {name}?',
            cancel_success: 'Canceled',
            cancel_fail: 'Orders cannot be canceled after 3 minutes.',
            currency: '￥',
            tax_included: 'Tax included',
            cancel_within_3_m: 'This order can be canceled within 3 minutes.',
            done_preparing: 'Cooking completed',
            served: 'Served',
            cooking: 'Cooking',
            treasurer: 'Pay',
            account_message: 'Thank you for dining with us. <br/><br/> Please bring your check to the cash register <br/> for payment.',
          },
          Cart: {
            title: 'Cart',
            order: 'Order',
            go_back: 'Return to the menu.',
            turn_back: 'Go back',
            quantity: 'Quantity',
            order_success: 'Your order has been received.',
            order_fail: 'Your order could not be sent. <br/> Please try again later.',
            out_of_stock: 'The following item(s) are now sold out. <br/>',
            unordered_data: 'Orders for {0} items remain unsent.',
            order_menu: 'Order the items in the cart.',
            exceed_coupon_limit: 'You have exceeded the limit for the number of usable coupons. <br/> Please modify your order.',
          },
          AllYouCanEat: {
            minutes: 'min',
            time_left: 'Time left',
            all_you_can_eat: 'All-you-can-eat',
            all_you_can_drink: 'All-you-can-drink',
            message_near_end_time: '{CCC} minutes remaining <br/> for last orders for {BBB}.',
            message_one_of_them_over_time: '{AAA} time has ended. <br/> {CCC} minutes remain <br/> for last orders for {BBB}.',
            message_over_time: 'Order time for {AAA} has ended. We look forward to your next visit. <br/> Your time can be extended at an attractive additional charge. Please ask a waiter to extend.',
            menu_not_available: 'Time for last orders has ended.',
            warning_paid_menu: '【For a charge】Additional charge(s) will be added.<br/> OK to proceed?',
          },
          ThankPage: {
            thank_you_very_much: 'Thank you very much.',
            we_look_forward_to_seeing_you_again: 'We look forward to your next visit.',
          },
          MessageList: {
            credential_error: 'An error occurred. <br/> (Code: {error_code})',
          },
          CouponForm: {
            headtitle: 'Please enter the coupon number.',
            title: 'Number of usable coupons : Maximum number of usable coupons',
            coupon_number: 'Coupon number',
            description1: 'Use of coupons is limited to two per person per visit.',
            description2: 'Coupons cannot be used in combination with other discount coupons.',
            description3: 'Please show unnumbered coupons to a waiter.',
            cancel: 'Cancel',
            degree: 'Confirm',
            warn_not_found_coupon_menu: 'No such coupon exists. <br/> Please check the coupon number and re-enter.',
            placeholder: 'Please enter the coupon number.',
          },
          Pita: {
            minutes: 'min',
            time_left: 'Time left',
            all_you_can_eat: 'All-you-can-eat',
            all_you_can_drink: 'All-you-can-drink',
            message_near_end_time: '{CCC} minutes remaining <br/> for last orders for {BBB}.',
            message_one_of_them_over_time: '{AAA} time has ended. <br/> {CCC} minutes remain <br/> for last orders for {BBB}.',
            message_over_time: 'Order time for {AAA} has ended. We look forward to your next visit. <br/> Your time can be extended at an attractive additional charge. Please ask a waiter to extend.',
            menu_not_available: 'Time for last orders has ended.',
          },
        },
      },
      jp: {
        translation: {
          App: {
            language: 'Language',
            max_coupon_num_exceeded: 'クーポン利用枚数の上限を超えました。',
            max_coupon_exceed_limit: 'クーポンの使用枚数が上限を超ているためご注文いただけません。',
            agree: 'はい',
            no: 'いいえ',
            close_modal: '閉じる',
            lunch_menu_unavailable:
              'ランチメニューの提供時間が終了しました。引き続き、グランドメニューをお楽しみください。',
            invalid_item_exist: '無効な商品が含まれています',
            app_not_launch: 'オーダーアプリを起動できませんでした。',
            confirm_quit_app: 'スマホオーダーを離れますが<br/>よろしいですか？',
            confirm_delete_item: 'こちらの商品を<br/>削除してよろしいでしょうか？',
            warning_age_limit: '20歳未満の方やお車を運転される方へのお酒の販売はお断りしております。',
            genre_freq_ordered_menu: 'よく注文するメニュー',
            genre_search_menu: '検索結果',
          },
          Top: {
            see_all_product_at_this_fair: 'このフェアのすべての商品を見る',
          },
          Header: {
            freq_ordered_menu: 'よく注文するメニュー',
            search_menu: 'メニュー検索',
            order_history: '注文履歴',
            use_counpon: 'クーポン',
            search: '検索',
          },
          MenuList: {
            call_staff: 'ご注文の際は、店員をお呼びください',
            lunch_dish_needed: 'ランチメニュー注文で、ご利用可能となります',
            set_dish_needed: '定食をご注文で、ご利用可能となります',
            lunch_time_only: 'ランチタイムのみご注文いただけます',
            lunch_time_excluded: 'ランチタイムは、ランチ価格のメニューをご注文ください',
            search_no_result: '検索結果はありません<br />キーワードをご確認ください',
            out_of_stock: '品切れ中です',
          },
          MenuItemDetail: {
            add_to_cart: '注文リストに追加',
            delete_portion: 'を取り消す',
            portion_name: 'お好みメニュー選択',
            please_select_an_item: '{quantity}品選択してください',
            please_select_required_item: '必要な数を選択してください',
            pay_menu_in_you_can_eat_time_confirm_message:
              '食べ放題メニュー以外のメニューが含まれるため、追加料金が発生します。<br/>よろしいですか？',
            exceed_quantity_warning:
              '一度に注文できる注文数の上限を超えました。<br />カート内の商品を注文後に再度選択してください。',
            select_more_items_message: '{0}を{1}お選びください。<br />',
            kind: '品',
          },
          OrderHistory: {
            title: '注文履歴',
            load_fail: '注文履歴を取得時にエラーが発生しました',
            order_number: '注文数',
            order_name: '商品名',
            order_type: 'オーダー種別',
            quantity: '数量',
            cost: '値段',
            status: '状況',
            re_order: '再注文',
            total_price: '合計金額',
            cancel: '取り消し',
            confirm_cancel: '{name}をキャンセルしますか？',
            cancel_success: '取り消し済',
            cancel_fail: '3分が経過すると、注文はキャンセルできなくなります',
            currency: '￥',
            tax_included: '税込',
            cancel_within_3_m: 'この注文は3分以内にキャンセルできます',
            done_preparing: '調理完了',
            served: '提供済み',
            cooking: '調理中',
            treasurer: '会計する',
            account_message:
              'ご利用ありがとうございました。<br /><br />伝票をお持ちいただき、<br />レジにてお会計をお願いいたします。',
          },
          Cart: {
            title: 'カート',
            order: '注文する',
            go_back: 'メニューに戻る',
            turn_back: '戻る',
            quantity: '数量',
            order_success: '注文を承りました。',
            order_fail: '注文の送信に失敗しました。<br />しばらくしてから、再度お試しください。',
            out_of_stock: '下記の商品が、品切れになりました。<br />',
            unordered_data: '未送信の注文が{0}品有ります',
            order_menu: 'カートのメニューを注文する',
            exceed_coupon_limit: 'クーポン利用枚数の上限を超えました。<br />注文内容の変更をお願いします。',
          },
          AllYouCanEat: {
            minutes: '分',
            time_left: '残り',
            all_you_can_eat: '食べ放題',
            all_you_can_drink: '飲み放題',
            message_near_end_time: '{BBB}ラストオーダーまで<br />あと{CCC}分です。',
            message_one_of_them_over_time:
              '{AAA}の終了時間となりました。<br />{BBB}はラストオーダーまで<br />あと{CCC}分です。',
            message_over_time:
              '{AAA}オーダー終了時間となりました。またのご利用をお待ちしております。<br />また、お得な料金で延長を受け付けております。ご利用の際はお近くの従業員にお声かけください。',
            menu_not_available: 'ラストオーダー終了しました',
            warning_paid_menu: '【有料】別途追加料金がかかります。<br />よろしいですか？',
          },
          ThankPage: {
            thank_you_very_much: 'ありがとうございました。',
            we_look_forward_to_seeing_you_again: 'またのご来店をお待ちしております。',
          },
          MessageList: {
            credential_error: '処理に失敗しました。<br />(コード：{error_code})',
          },
          CouponForm: {
            headtitle: 'クーポン番号を入力してください',
            title: '使用可能枚数 ： 使用上限枚数',
            coupon_number: 'クーポン番号',
            description1: 'クーポン券のご利用は、１回のご来店でお１人様２枚までとなります。',
            description2: '各種割引券との併用はできません。',
            description3: '番号が記載されていないクーポンは従業員にご提示ください。',
            cancel: 'キャンセル',
            degree: '決定',
            warn_not_found_coupon_menu:
              '該当のクーポンが存在しません。<br />クーポンコードを確認の上、再度入力してください。',
            placeholder: 'こちらに番号を入力してください',
          },
          Pita: {
            minutes: '分',
            time_left: '残り',
            all_you_can_eat: '食べ放題定食',
            all_you_can_drink: '飲み放題',
            message_near_end_time: '{BBB}ラストオーダーまで<br />あと{CCC}分です。',
            message_one_of_them_over_time:
              '{AAA}の終了時間となりました。<br />{BBB}はラストオーダーまで<br />あと{CCC}分です。',
            message_over_time:
              '{AAA}オーダー終了時間となりました。またのご利用をお待ちしております。<br />また、お得な料金で延長を受け付けております。ご利用の際はお近くの従業員にお声かけください。',
            menu_not_available: 'ラストオーダー終了しました',
          },
        },
      },
      cn: {
        translation: {
          App: {
            language: 'Language',
            max_coupon_num_exceeded: '已超出可使用优惠券上限。',
            max_coupon_exceed_limit: '已达到可使用优惠券上限。\n超出限定不可使用。',
            agree: '是',
            no: '否',
            close_modal: '关闭',
            lunch_menu_unavailable: '午餐提供时间已结束。请选择通常菜单。',
            invalid_item_exist: '包含有无效的商品。',
            app_not_launch: '订单程序无法成功启动。',
            confirm_quit_app: '离开订单程序吗？',
            confirm_delete_item: '消除此商品吗？',
            warning_age_limit: '20岁以下，孕妇，开车人员不予提供酒精饮料。',
            genre_freq_ordered_menu: 'よく注文するメニュー',
            genre_search_menu: '検索結果',
          },
          Top: {
            see_all_product_at_this_fair: '显示此展销菜单的全部商品',
          },
          Header: {
            freq_ordered_menu: '常点菜单',
            search_menu: '检索菜单',
            order_history: '订单履历',
            use_counpon: '优惠券',
            search: '检索',
          },
          MenuList: {
            call_staff: '点单时请叫店员',
            lunch_dish_needed: '可在订购午餐时使用',
            set_dish_needed: '可在订购套餐时使用',
            lunch_time_only: '仅限午餐时间订购',
            lunch_time_excluded: '午餐时间请选用午餐菜单',
            search_no_result: '检索无结果  请确认关键词',
            out_of_stock: '现在已经脱销。',
          },
          MenuItemDetail: {
            add_to_cart: '加入订单',
            delete_portion: '取消（A）',
            portion_name: '请选择您喜欢的配套副菜',
            please_select_an_item: '请选择{quantity}品',
            please_select_required_item: '请选择必要的数量',
            pay_menu_in_you_can_eat_time_confirm_message: '点单超出自助餐范围，会产生追加费用。<br/>是否确定？',
            exceed_quantity_warning: '超出一次可点单数量。<br />请将购物车内商品下单后再选择。',
            select_more_items_message: '请选择{0}{1}品。<br />',
            kind: '品',
          },
          OrderHistory: {
            title: '订单履历',
            load_fail: '显示订单履历时发生错误',
            order_number: '订购数量',
            order_name: '商品名',
            order_type: '订单类别',
            quantity: '数量',
            cost: '价格（不含税）',
            status: '状況',
            re_order: '重新订购',
            total_price: '合计金额（含税）',
            cancel: '取消',
            confirm_cancel: '取消{name}吗？',
            cancel_success: '已取消',
            cancel_fail: '下单超过3分钟不可取消',
            currency: '￥',
            tax_included: '含税',
            cancel_within_3_m: '此订单可在3分钟内取消',
            done_preparing: '料理结束',
            served: '已提供',
            cooking: '料理中',
            treasurer: '结账',
            account_message: '感谢您的用餐。<br /><br />请拿账单去收银台结账。',
          },
          Cart: {
            title: '购物车',
            order: '点单',
            go_back: '返回菜单',
            turn_back: '返回',
            quantity: '数量',
            order_success: '已接受下单',
            order_fail: '下单失败请稍后重试。',
            out_of_stock: '以下商品已脱销',
            unordered_data: '{0}品没有下单',
            order_menu: '购物车内商品全部下单',
            exceed_coupon_limit: '已超出可使用优惠券上限。<br />请更换订单内容。',
          },
          AllYouCanEat: {
            minutes: '分',
            time_left: '剩余',
            all_you_can_eat: '自助餐',
            all_you_can_drink: '无限畅饮',
            message_near_end_time: '{BBB}最后点单<br />剩{CCC}分钟。',
            message_one_of_them_over_time: '{AAA}已到结束时间。<br />{BBB}最后点单<br />剩{CCC}分钟。',
            message_over_time: '{AAA}可下单时间已结束。期待您的下次使用。<br />另有优惠的时间延长服务，请询问店员。',
            menu_not_available: '最后点单已结束',
            warning_paid_menu: '【有料】別途追加料金がかかります。<br />よろしいですか？',
          },
          ThankPage: {
            thank_you_very_much: '谢谢惠顾',
            we_look_forward_to_seeing_you_again: '期待您的下次使用。',
          },
          MessageList: {
            credential_error: '处理失败<br />(コード：{error_code})',
          },
          CouponForm: {
            headtitle: '请输入优惠券号码',
            title: '可使用优惠券数量',
            coupon_number: 'クーポン番号',
            description1: '优惠券的使用，每位顾客上限2张。',
            description2: '各种优惠券不可同时使用。',
            description3: '没有号码的优惠券请向店员出示。',
            cancel: '取消',
            degree: '决定',
            warn_not_found_coupon_menu: '此优惠券不存在。<br />请确认优惠券号码重试。',
            placeholder: '请输入优惠券号码',
          },
          Pita: {
            minutes: '分',
            time_left: '剩',
            all_you_can_eat: '定量自主',
            all_you_can_drink: '无限畅饮',
            message_near_end_time: '{BBB}最后点单<br />剩{CCC}分钟。',
            message_one_of_them_over_time: '{AAA}已到结束时间。<br />{BBB}最后点单<br />剩{CCC}分钟。',
            message_over_time: '{AAA}可下单时间已结束。期待您的下次使用。<br />另有优惠的时间延长服务，请询问店员。',
            menu_not_available: '最后点单已结束',
          },
        },
      },
      tw: {
        translation: {
          App: {
            language: 'Language',
            max_coupon_num_exceeded: 'クーポン利用枚数の上限を超えました。',
            max_coupon_exceed_limit: 'クーポンの使用枚数が上限を超ているためご注文いただけません。',
            agree: 'はい',
            no: 'いいえ',
            close_modal: '閉じる',
            lunch_menu_unavailable:
              'ランチメニューの提供時間が終了しました。引き続き、グランドメニューをお楽しみください。',
            invalid_item_exist: '無効な商品が含まれています',
            app_not_launch: 'オーダーアプリを起動できませんでした。',
            confirm_quit_app: 'スマホオーダーを離れますが<br/>よろしいですか？',
            confirm_delete_item: 'こちらの商品を<br/>削除してよろしいでしょうか？',
            warning_age_limit: '20歳未満、妊婦、お車等を運転される方へのアルコールのご提供はお断りいたします。',
            genre_freq_ordered_menu: 'よく注文するメニュー',
            genre_search_menu: '検索結果',
          },
          Top: {
            see_all_product_at_this_fair: 'このフェアのすべての商品を見る',
          },
          Header: {
            freq_ordered_menu: 'よく注文するメニュー',
            search_menu: 'メニュー検索',
            order_history: '注文履歴',
            use_counpon: 'クーポン',
            search: '検索',
          },
          MenuList: {
            call_staff: 'ご注文の際は、店員をお呼びください',
            lunch_dish_needed: 'ランチメニュー注文で、ご利用可能となります',
            set_dish_needed: '定食をご注文で、ご利用可能となります',
            lunch_time_only: 'ランチタイムのみご注文いただけます',
            lunch_time_excluded: 'ランチタイムは、ランチ価格のメニューをご注文ください',
            search_no_result: '検索結果はありません<br />キーワードをご確認ください',
            out_of_stock: '品切れ中です',
          },
          MenuItemDetail: {
            add_to_cart: '注文リストに追加',
            delete_portion: 'を取り消す',
            portion_name: 'お好みメニュー選択',
            please_select_an_item: '{quantity}品選択してください',
            please_select_required_item: '必要な数を選択してください',
            pay_menu_in_you_can_eat_time_confirm_message:
              '食べ放題メニュー以外のメニューが含まれるため、追加料金が発生します。<br/>よろしいですか？',
            exceed_quantity_warning:
              '一度に注文できる注文数の上限を超えました。<br />カート内の商品を注文後に再度選択してください。',
            select_more_items_message: '{0}を{1}お選びください。<br />',
            kind: '品',
          },
          OrderHistory: {
            title: '注文履歴',
            load_fail: '注文履歴を取得時にエラーが発生しました',
            order_number: '注文数',
            order_name: '商品名',
            order_type: 'オーダー種別',
            quantity: '数量',
            cost: '値段',
            status: '状況',
            re_order: '再注文',
            total_price: '合計金額',
            cancel: '取り消し',
            confirm_cancel: '{name}をキャンセルしますか？',
            cancel_success: '取り消し済',
            cancel_fail: '3分が経過すると、注文はキャンセルできなくなります',
            currency: '￥',
            tax_included: '税込',
            cancel_within_3_m: 'この注文は3分以内にキャンセルできます',
            done_preparing: '調理完了',
            served: '提供済み',
            cooking: '調理中',
            treasurer: '会計する',
            account_message:
              'ご利用ありがとうございました。<br /><br />伝票をお持ちいただき、<br />レジにてお会計をお願いいたします。',
          },
          Cart: {
            title: 'カート',
            order: '注文する',
            go_back: 'メニューに戻る',
            turn_back: '戻る',
            quantity: '数量',
            order_success: '注文を承りました。',
            order_fail: '注文の送信に失敗しました。<br />しばらくしてから、再度お試しください。',
            out_of_stock: '下記の商品が、品切れになりました。<br />',
            unordered_data: '未送信の注文が{0}品有ります',
            order_menu: 'カートのメニューを注文する',
            exceed_coupon_limit: 'クーポン利用枚数の上限を超えました。<br />注文内容の変更をお願いします。',
          },
          AllYouCanEat: {
            minutes: '分',
            time_left: '残り',
            all_you_can_eat: '食べ放題',
            all_you_can_drink: '飲み放題',
            message_near_end_time: '{BBB}ラストオーダーまで<br />あと{CCC}分です。',
            message_one_of_them_over_time:
              '{AAA}の終了時間となりました。<br />{BBB}はラストオーダーまで<br />あと{CCC}分です。',
            message_over_time:
              '{AAA}オーダー終了時間となりました。またのご利用をお待ちしております。<br />また、お得な料金で延長を受け付けております。ご利用の際はお近くの従業員にお声かけください。',
            menu_not_available: 'ラストオーダー終了しました',
          },
          ThankPage: {
            thank_you_very_much: 'ありがとうございました。',
            we_look_forward_to_seeing_you_again: 'またのご来店をお待ちしております。',
          },
          MessageList: {
            credential_error: '処理に失敗しました。<br />(コード：{error_code})',
          },
          CouponForm: {
            headtitle: 'クーポン番号を入力してください',
            title: '使用可能枚数 ： 使用上限枚数',
            coupon_number: 'クーポン番号',
            description1: 'クーポン券のご利用は、１回のご来店でお１人様２枚までとなります。',
            description2: '各種割引券との併用はできません。',
            description3: '番号が記載されていないクーポンは従業員にご提示ください。',
            cancel: 'キャンセル',
            degree: '決定',
            warn_not_found_coupon_menu:
              '該当のクーポンが存在しません。<br />クーポンコードを確認の上、再度入力してください。',
            placeholder: 'クーポン番号を入力してください',
          },
          Pita: {
            minutes: '分',
            time_left: '残り',
            all_you_can_eat: '食べ放題定食',
            all_you_can_drink: '飲み放題',
            message_near_end_time: '{BBB}ラストオーダーまで<br />あと{CCC}分です。',
            message_one_of_them_over_time:
              '{AAA}の終了時間となりました。<br />{BBB}はラストオーダーまで<br />あと{CCC}分です。',
            message_over_time:
              '{AAA}オーダー終了時間となりました。またのご利用をお待ちしております。<br />また、お得な料金で延長を受け付けております。ご利用の際はお近くの従業員にお声かけください。',
            menu_not_available: 'ラストオーダー終了しました',
          },
        },
      },
      kr: {
        translation: {
          App: {
            language: 'Language',
            max_coupon_num_exceeded: '쿠폰 이용 매수 상한을 초과했습니다.',
            max_coupon_exceed_limit: '쿠폰 사용 상한에 도달했습니다. \n 이상은 사용할 수 없습니다.',
            agree: '예',
            no: '아니요',
            close_modal: '닫기',
            lunch_menu_unavailable: '런치 메뉴 제공 시간이 종료되었습니다. 이어서 그랜드 메뉴를 즐겨 주십시오.',
            invalid_item_exist: '무효 상품이 포함되어 있습니다',
            app_not_launch: '주문 앱을 실행하지 못했습니다.',
            confirm_quit_app: '스마트폰 주문에서 벗어납니다만<br/>계속하시겠습니까?',
            confirm_delete_item: '이 상품을<br/>삭제하시겠습니까?',
            warning_age_limit: '20세 미만, 임부, 자동차 등을 운전하는 분에게는 주류를 제공하지 않습니다.',
            genre_freq_ordered_menu: '자주 주문하는 메뉴',
            genre_search_menu: '검색 결과',
          },
          Top: {
            see_all_product_at_this_fair: '이 페어 전체 상품 보기',
          },
          Header: {
            freq_ordered_menu: '자주 주문하는 메뉴',
            search_menu: '메뉴 검색',
            order_history: '주문 이력',
            use_counpon: '쿠폰',
            search: '검색',
          },
          MenuList: {
            call_staff: '주문할 때는 점원을 불러 주십시오',
            lunch_dish_needed: '런치 메뉴 주문에서 이용할 수 있습니다',
            set_dish_needed: '정식 주문에서 이용할 수 있습니다',
            lunch_time_only: '런치 타임만 주문할 수 있습니다',
            lunch_time_excluded: '런치 타임은 런치 가격 메뉴를 주문해 주십시오',
            search_no_result: '검색 결과가 없습니다<br />키워드를 확인해 주십시오',
            out_of_stock: '품절 중입니다',
          },
          MenuItemDetail: {
            add_to_cart: '주문 리스트에 추가',
            delete_portion: '(A) 취소하기',
            portion_name: '원하는 메뉴 선택',
            please_select_an_item: '{quantity}품목을 선택해 주십시오',
            please_select_required_item: '필요한 수량을 선택해 주십시오',
            pay_menu_in_you_can_eat_time_confirm_message: '무한 리필 메뉴 이외의 메뉴가 포함되어 있으므로 추가 요금이 발생합니다. <br/>계속하시겠습니까?',
            exceed_quantity_warning: '한 번에 주문할 수 있는 주문 수 상한을 초과했습니다. <br />장바구니 내의 상품을 주문하신 후에 다시 선택해 주십시오.',
            select_more_items_message: '{0}을 {1} 선택해 주십시오. <br />',
            kind: '품목',
          },
          OrderHistory: {
            title: '주문 이력',
            load_fail: '주문 이력을 취득할 때 에러가 발생했습니다',
            order_number: '주문 수',
            order_name: '상품명',
            order_type: '주문 종류별',
            quantity: '수량',
            cost: '가격(세금 별도)',
            status: '상황',
            re_order: '재주문',
            total_price: '총금액(세금 포함)',
            cancel: '취소',
            confirm_cancel: '{name}을 취소하시겠습니까?',
            cancel_success: '취소 완료',
            cancel_fail: '3분이 경과하면 주문은 취소할 수 없게 됩니다',
            currency: '￥',
            tax_included: '세금 포함',
            cancel_within_3_m: '이 주문은 3분 이내에 취소할 수 있습니다',
            done_preparing: '조리 완료',
            served: '제공 완료',
            cooking: '조리 중',
            treasurer: '결제하기',
            account_message: '이용해 주셔서 감사합니다. <br /><br />전표를 가지고 <br />계산대에서 결제해 주십시오.',
          },
          Cart: {
            title: '장바구니',
            order: '주문하기',
            go_back: '메뉴로 돌아가기',
            turn_back: '뒤로가기',
            quantity: '수량',
            order_success: '주문을 접수했습니다.',
            order_fail: '주문 송신에 실패했습니다. <br />잠시 기다린 후 다시 시도해 주십시오.',
            out_of_stock: '아래 상품이 품절되었습니다. <br />',
            unordered_data: '미송신 주문이 {0}품목 있습니다',
            order_menu: '장바구니 메뉴 주문하기',
            exceed_coupon_limit: '쿠폰 이용 매수 상한을 초과했습니다. <br />주문 내용을 변경해 주십시오.',
          },
          AllYouCanEat: {
            minutes: '분',
            time_left: '잔량',
            all_you_can_eat: '무한 리필',
            all_you_can_drink: '음료 무한 리필',
            message_near_end_time: '{BBB} 주문 마감까지<br /> {CCC}분 남았습니다.',
            message_one_of_them_over_time: '{AAA}의 종료 시간이 되었습니다. <br />{BBB}는 주문 마감까지<br /> {CCC}분 남았습니다.',
            message_over_time: '{AAA} 주문 종료 시간이 되었습니다. 또 이용해 주시기 바랍니다. <br />또한 저렴한 요금으로 연장을 접수하고 있습니다. 이용하실 때는 근처에 있는 종업원에게 말씀해 주십시오.',
            menu_not_available: '주문 마감을 종료했습니다',
            warning_paid_menu: '【유료】별도 추가 요금이 발생합니다.  <br/>계속하시겠습니까?',
          },
          ThankPage: {
            thank_you_very_much: '감사합니다.',
            we_look_forward_to_seeing_you_again: '또 방문해 주시기 바랍니다.',
          },
          MessageList: {
            credential_error: '처리에 실패했습니다.<br />(코드: {error_code})',
          },
          CouponForm: {
            headtitle: '쿠폰 번호를 입력해 주십시오',
            title: '사용 가능 매수 : 사용 상한 매수',
            coupon_number: '쿠폰 번호',
            description1: '쿠폰권은 1회 방문 시 1명당 2매까지 이용할 수 있습니다.',
            description2: '각종 할인권과 함께 사용할 수 없습니다.',
            description3: '번호가 기재되지 않은 쿠폰은 종업원에게 제시해 주십시오.',
            cancel: '취소',
            degree: '결정',
            warn_not_found_coupon_menu: '해당 쿠폰이 존재하지 않습니다. <br />쿠폰 코드를 확인한 후 다시 입력해 주십시오.',
            placeholder: '쿠폰 번호를 입력해 주십시오',
          },
          Pita: {
            minutes: '분',
            time_left: '잔량',
            all_you_can_eat: '피타 무한 리필',
            all_you_can_drink: '음료 무한 리필',
            message_near_end_time: '{BBB} 주문 마감까지<br /> {CCC}분 남았습니다.',
            message_one_of_them_over_time: '{AAA}의 종료 시간이 되었습니다. <br />{BBB}는 주문 마감까지<br /> {CCC}분 남았습니다.',
            message_over_time: '{AAA} 주문 종료 시간이 되었습니다. 또 이용해 주시기 바랍니다. <br />또한 저렴한 요금으로 연장을 접수하고 있습니다. 이용하실 때는 근처에 있는 종업원에게 말씀해 주십시오.',
            menu_not_available: '주문 마감을 종료했습니다',
          },
        },
      },
      vn: {
        translation: {
          App: {
            language: 'Language',
            max_coupon_num_exceeded: 'Đã vượt quá số Coupon (Phiếu giảm giá) cho phép',
            max_coupon_exceed_limit: 'Đã sử dụng đủ số Coupon (Phiếu giảm giá) cho phép, Không thể sử dụng nhiều hơn.',
            agree: 'yes',
            no: 'No',
            close_modal: 'Đóng',
            lunch_menu_unavailable: 'Đã hết thời gian phục vụ Luch Menu xin Vui lòng sử dụng GrandMenu',
            invalid_item_exist: 'Chứa các sản phẩm không hợp lệ',
            app_not_launch: 'Chưa khởi động ứng dụng oder',
            confirm_quit_app: 'Sẽ rời khỏi chế độ Mobile Oder, <br/>Bạn chắc chắn chứ?',
            confirm_delete_item: 'Bạn  muốn xóa món ăn này ?',
            warning_age_limit: 'Từ chối phục vụ đồ có cồn cho vị thành niên dưới 20 tuổi, người đang mang thai, người sẽ lái xe …vv',
            genre_freq_ordered_menu: 'よく注文するメニュー',
            genre_search_menu: '検索結果',
          },
          Top: {
            see_all_product_at_this_fair: 'Xem toàn bộ món của menu theo mùa',
          },
          Header: {
            freq_ordered_menu: 'Menu được ưa chuộng nhất',
            search_menu: 'Tìm kiếm Menu',
            order_history: 'Lịch sử đặt món',
            use_counpon: 'Coupon (Phiếu Giảm Giá)',
            search: 'Tìm kiếm',
          },
          MenuList: {
            call_staff: 'Vui lòng gọi nhân viên cửa hàng để được gọi món',
            lunch_dish_needed: 'Có thể sử dụng khi gọi menu luch',
            set_dish_needed: 'Có thể sử dụng khi gọi menu set',
            lunch_time_only: 'Chỉ có thể gọi món trong Luch Time',
            lunch_time_excluded: 'Vui lòng chọn giá Menu luch trong khung giờ Luch Time',
            search_no_result: 'Không có kết quả tìm kiếm <br /> vui lòng xác nhận lại từ khóa',
            out_of_stock: 'Hết hàng',
          },
          MenuItemDetail: {
            add_to_cart: 'Gọi thêm món',
            delete_portion: 'Xóa （A）',
            portion_name: 'Chọn thực đơn Okonomi',
            please_select_an_item: 'Hãy chọn món{quantity}',
            please_select_required_item: 'Hãy chọn số lượng cần thiết',
            pay_menu_in_you_can_eat_time_confirm_message: 'Món ăn này không nằm trong vé Buffet, Vì vậy sẽ phát sinh chi phí cho món ăn này, Bạn đồng ý chứ?',
            exceed_quantity_warning: 'Đã vượt quá số lượng tối đa có thể đặt cùng một lúc. <br /> Vui lòng chọn lại trong giỏ hàng sau khi đặt hàng.',
            select_more_items_message: 'Vui lòng chọn {1}',
            kind: 'sản phẩm',
          },
          OrderHistory: {
            title: 'Lịch sử đặt món',
            load_fail: 'Đã xảy ra lỗi khi truy xuất lịch sử đơn đặt hàng',
            order_number: 'Số lượng đặt món',
            order_name: 'Tên Món Ăn',
            order_type: 'Theo loại đơn đặt hàng',
            quantity: 'Số Lượng',
            cost: 'Thành tiền (Chưa bao gồm thuế)',
            status: 'Tình trạng',
            re_order: 'Đặt hàng lại',
            total_price: 'Tồng cộng tiền thành toán (Đã bao gồm thuế)',
            cancel: 'Xóa',
            confirm_cancel: 'Hủy',
            cancel_success: 'Đã Xóa',
            cancel_fail: 'Sau 3 phút, Bạn không thể hủy đơn đặt hàng',
            currency: '￥',
            tax_included: 'Đã bao gồm thuế',
            cancel_within_3_m: 'Có thể hủy đơn hàng này trong vòng 3 phút',
            done_preparing: 'Đã chế biến xong',
            served: 'Đã lên món',
            cooking: 'Đang chế biến',
            treasurer: 'Thanh Toán',
            account_message: 'Cám ơn quý khách, Vui lòng mang phiếu tạm tính tới quầy thu ngân để được thanh toán.',
          },
          Cart: {
            title: 'Giỏ hàng',
            order: 'Đặt món',
            go_back: 'Quay lại Menu',
            turn_back: 'Quay lại',
            quantity: 'Số lượng',
            order_success: 'Đã xác nhận yêu cầu đặt món',
            order_fail: 'Không gửi được đơn đặt hàng. <br /> Vui lòng thử lại sau.',
            out_of_stock: 'Mặt hàng sau đây đã hết hàng. <br />',
            unordered_data: 'Bạn có {0} mặt hàng trong đơn đặt hàng chưa gửi của bạn',
            order_menu: 'Đặt món trong giỏ hàng',
            exceed_coupon_limit: 'Đã vượt quá số Voucher cho phép<br />Vui lòng thay đổi nội dung gọi món',
          },
          AllYouCanEat: {
            minutes: 'Phút',
            time_left: 'Còn lại',
            all_you_can_eat: 'Buffet',
            all_you_can_drink: 'Nước Buffet',
            message_near_end_time: '{BBB}Thời gian gọi món Buffet<br />Còn lại{CCC}Phút',
            message_one_of_them_over_time: '{AAA}Đã hết Thời gian gọi món<br />{BBB}Thời gian gọi món <br />Còn lại{CCC}Phút',
            message_over_time: '{AAA} Đã kết thúc thời gian gọi món  <br /> Chúng tôi cũng chấp nhận gia hạn thêm thời gian gọi món với mức giá hợp lý. Vui lòng gọi nhân viên gần đó để được sử dụng dịch vụ.',
            menu_not_available: 'Đã kết thúc thời gian gọi món',
            warning_paid_menu: '【有料】別途追加料金がかかります。<br />よろしいですか？',
          },
          ThankPage: {
            thank_you_very_much: 'Cám ơn quý khách.',
            we_look_forward_to_seeing_you_again: 'Rất hân hạnh được tiếp đón quý khách ở những lần tiếp theo.',
          },
          MessageList: {
            credential_error: 'Quá trình thực hiện bị lỗi. <br /> (Mã: {error_code})',
          },
          CouponForm: {
            headtitle: 'Vui lòng nhập mã voucher',
            title: 'Số tờ có thể sử dụng: Số tờ tối đa có thể được sử dụng',
            coupon_number: 'クーポン番号',
            description1: 'Mỗi người có thể sử dụng tối đa 2 phiếu giảm giá mỗi lần.',
            description2: 'Không thể kết hợp với các phiếu giảm giá khác.',
            description3: 'Vui lòng cho nhân viên xem voucher không có mã.',
            cancel: 'Hủy',
            degree: 'Xác Nhận',
            warn_not_found_coupon_menu: 'Phiếu giảm giá tương ứng không tồn tại. <br /> Vui lòng kiểm tra mã phiếu giảm giá và nhập lại.',
            placeholder: 'Vui lòng nhập mã giảm giá',
          },
          Pita: {
            minutes: 'Phút',
            time_left: 'Còn lại',
            all_you_can_eat: 'PiTa Buffet',
            all_you_can_drink: 'Đồ uống Buffet',
            message_near_end_time: '{BBB}Thời gian gọi món<br />Còn lại{CCC}Phút',
            message_one_of_them_over_time: '{AAA}Đã hết Thời gian gọi món<br />{BBB}Thời gian gọi món <br />Còn lại{CCC}Phút',
            message_over_time: '{AAA} Đã kết thúc thời gian gọi món  <br /> Chúng tôi cũng chấp nhận gia hạn thêm thời gian gọi món với mức giá hợp lý. Vui lòng gọi nhân viên gần đó để được sử dụng dịch vụ.',
            menu_not_available: 'Đã kết thúc thời gian gọi món',
          },
        },
      },
    },
  });

export default i18n;
