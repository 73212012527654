import { useAppSelector } from 'app/hooks';
import DishModal from 'components/DishModal/DishModal';
import React from 'react';
import { selectThMenuInfo } from 'store/allYouCanEatSlice';
import { selectCurrentModeCode, selectMenuInfo } from 'store/mainSlice';
import { selectPhMenuInfo } from 'store/pitaSlice';
import { Payload } from 'types';
import { getDishInfo, getSourceMenuInfoByMode } from 'utils/Utils';
import './scss/MenuDetail.scss';
import { DishOptionGroupType, PortionStateType } from './types';

export interface MenuItemDetailProps {
  open?: boolean;
  modeCode?: string;
  itemCode: string;
  onClose?: () => void;
}

export function initDishState(
  sourceMenuInfo: Payload | null,
  dishCode: string,
  addDefaultValue = true
): PortionStateType[] {
  const itemInfo = getDishInfo(sourceMenuInfo, dishCode);
  const optionGroupData: Payload = sourceMenuInfo?.group;
  const groupInfoData: Payload = sourceMenuInfo?.group_menu;
  const groupCodes: string[] = (itemInfo && itemInfo.group) || [];
  const initOrderState: Payload = {};

  const validGroupCodes = groupCodes.filter(
    (groupCode) => groupCode in optionGroupData && groupCode in groupInfoData && 'lang' in optionGroupData[groupCode]
  );
  validGroupCodes.forEach((groupCode) => {
    const groupInfo = optionGroupData[groupCode];
    const groupDetailInfo: Payload = groupInfoData[groupCode];
    const optionGroupDetail: Payload = {};
    const noselectflg: string = optionGroupData[groupCode].noselectflg;

    const selectedMenuCodes: string[] = [];
    Object.values(groupDetailInfo).forEach((menuItem) => {
      const optionCode: string = menuItem.poscd;
      const defaultFlg: string = menuItem.defaultflg;
      const isChecked: boolean = addDefaultValue
        ? groupInfo.glassflg === '0' && (defaultFlg === '1' || noselectflg === '1')
          ? true
          : false
        : false;
      const initOptionState = {
        checked: isChecked,
        quantity: 1,
      };
      optionGroupDetail[optionCode] = initOptionState;

      if (isChecked) {
        selectedMenuCodes.push(optionCode);
      }
    });
    const optionGroup: DishOptionGroupType = {
      basic: groupInfo,
      detail: optionGroupDetail,
      selected: selectedMenuCodes,
    };
    initOrderState[groupCode] = optionGroup;
  });

  return [initOrderState];
}

export default function MenuItemDetail({ open, modeCode, itemCode, onClose }: MenuItemDetailProps) {
  const currentModeCode = modeCode !== undefined ? modeCode : useAppSelector(selectCurrentModeCode);
  const menuInfo = useAppSelector(selectMenuInfo);
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);
  const sourceMenuInfo = getSourceMenuInfoByMode(currentModeCode, menuInfo, thMenuInfo, phMenuInfo);
  const initState = React.useMemo(() => initDishState(sourceMenuInfo, itemCode), [itemCode]);

  return (
    <DishModal
      open={open}
      type="add"
      modeCode={currentModeCode}
      itemCode={itemCode}
      initState={initState}
      addedState={initState[0]}
      onClose={onClose}
    />
  );
}
