import { TAP_ANIMATION_TIME } from '../../constants';
import React from 'react';
import { playSound } from 'sound/Sound';
import './scss/AlertModal.scss';
import { GlobalModalContext } from 'components/GlobalModal';
import { useTranslation } from 'react-i18next';

export interface AlertModalProps {
  message: string;
  actionClose?: () => void;
}

function AlertModal({ message, actionClose }: AlertModalProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const { closeGlobalModal } = GlobalModalContext.useGlobalModalContext();

  const handleClose = () => {
    setTimeout(() => {
      playSound('close');
      actionClose && actionClose();
      closeGlobalModal();
    }, TAP_ANIMATION_TIME / 2);
  };

  return (
    <div className="modal alert-modal" data-bs-backdrop="static" tabIndex={-1} style={{ display: 'block' }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <div className="modal-message" dangerouslySetInnerHTML={{ __html: message }}></div>
          </div>
          <div className="dialog-footer d-flex justify-content-center">
            <button className="ara-btn ara-btn-outline single" onClick={handleClose}>
              <div>{t('App.close_modal')}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(AlertModal);
