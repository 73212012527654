import { useAppSelector } from 'app/hooks';
import { AlertModalProps } from 'components/AlertModal/AlertModal';
import { GlobalModalContext } from 'components/GlobalModal';
import { DishModalContext } from 'pages/MainPage/MainPage';
import React, { FormEvent, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { playSound } from 'sound/Sound';
import { selectThMenuInfo } from 'store/allYouCanEatSlice';
import { selectCartState } from 'store/cartSlice';
import { selectCutomerQuantity } from 'store/customerQuantitySlice';
import { selectMenuInfo, selectOrderHistoryData } from 'store/mainSlice';
import { selectPhMenuInfo } from 'store/pitaSlice';
import { Payload } from 'types';
import { countCouponUsed, getModeCodeFromDishInfo } from 'utils/Utils';
import { GRAND_MENU_MODEID, MODAL_TYPES, TAP_ANIMATION_TIME } from '../../constants';
import './scss/CouponFormModal.scss';

export interface CouponFormModalProps {
  open?: boolean;
  onClose: () => void;
}

function CouponFormModal({ open = false, onClose }: CouponFormModalProps) {
  const { showCouponDishModal } = useContext(DishModalContext);
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const menuInfo = useAppSelector(selectMenuInfo);
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);
  const customerQuantity = useAppSelector(selectCutomerQuantity);
  const cartState = useAppSelector(selectCartState);
  const orderHistory = useAppSelector(selectOrderHistoryData);
  const totalCouponUsed = countCouponUsed(menuInfo, thMenuInfo, phMenuInfo, cartState, orderHistory.OrderDetail);
  const buttonCancelRef = useRef<HTMLButtonElement>(null);
  const buttonConfirmRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (open) {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      if (buttonConfirmRef.current) buttonConfirmRef.current.disabled = true;
    }
  }, [open]);

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.validity.valid ? e.target.value.trim().slice(0, 14) : e.target.value;
    if (buttonConfirmRef.current) buttonConfirmRef.current.disabled = e.target.value.length === 0;
  };

  const handleClose = () => {
    setTimeout(() => {
      playSound('cancel');

      const pageWrapper = document.querySelector('.page-wrapper') as HTMLElement;
      if (pageWrapper) {
        document.documentElement.style.setProperty('--position-modal-top', `0px`);
        pageWrapper.removeAttribute('style');
      }

      if (inputRef.current) inputRef.current.value = '';
      onClose();
      if (buttonCancelRef.current) buttonCancelRef.current.disabled = false;
      if (buttonConfirmRef.current) buttonConfirmRef.current.disabled = true;
    }, TAP_ANIMATION_TIME / 2);
  };

  const handleSubmit = (e: FormEvent) => {
    // check button has disabled status, prevent click
    if (buttonConfirmRef.current) {
      if (buttonConfirmRef.current.disabled) {
        return;
      }
    }

    // change status of button to disabled
    if (buttonConfirmRef.current) buttonConfirmRef.current.disabled = true;
    e.preventDefault();

    setTimeout(() => {
      if (inputRef.current && inputRef.current.value.length > 0) {
        playSound('ok');

        let foundMenuDetail = undefined;
        // find menu in thMenuInfo
        if (thMenuInfo?.menu_detail) {
          foundMenuDetail = Object.values(thMenuInfo.menu_detail).find((menuDetail) => {
            const intPoscd = parseInt((menuDetail as Payload).poscd);
            const intCouponCode = parseInt((inputRef.current && inputRef.current.value) || '0');
            return intPoscd === intCouponCode && (menuDetail as Payload).coupon_flg === '1';
          });
        }

        // find menu in menuInfo
        if (foundMenuDetail === undefined) {
          foundMenuDetail = Object.values(menuInfo.menu_detail).find((menuDetail) => {
            const intPoscd = parseInt((menuDetail as Payload).poscd);
            const intCouponCode = parseInt((inputRef.current && inputRef.current.value) || '0');
            return intPoscd === intCouponCode && (menuDetail as Payload).coupon_flg === '1';
          });
        }

        if (foundMenuDetail === undefined) {
          //warn_not_found_coupon_menu
          playSound('error');
          showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
            message: t('CouponForm.warn_not_found_coupon_menu'),
            actionClose: () => {
              if (buttonConfirmRef.current) buttonConfirmRef.current.disabled = false;
            },
          });
        } else {
          const modeCode = getModeCodeFromDishInfo(foundMenuDetail as Payload);

          // close dialog
          if (inputRef.current) inputRef.current.value = '';
          onClose();
          if (buttonCancelRef.current) buttonCancelRef.current.disabled = false;
          if (buttonConfirmRef.current) buttonConfirmRef.current.disabled = true;

          showCouponDishModal &&
            showCouponDishModal((foundMenuDetail as Payload).poscd, modeCode === '' ? GRAND_MENU_MODEID : modeCode);
        }
      }
    }, TAP_ANIMATION_TIME / 2);
  };

  if (!open) return null;

  return (
    <div className="modal coupon-modal" tabIndex={-1} data-bs-backdrop="static" style={{ display: 'block' }}>
      <div className="modal-dialog modal-dialog-centered modal-sm coupon-form-modal">
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="modal-header border-bottom-0 px-0">
              <div className="coupon-form-modal__headtitle">{t('CouponForm.headtitle')}</div>
            </div>
            <div className="modal-body">
              <div className="coupon-form-modal__lables">{t('CouponForm.coupon_number')}</div>
              <div className="coupon-form-modal__input-wrapper mb-3">
                <input
                  id="coupon-code"
                  type="number"
                  ref={inputRef}
                  pattern="[0-9]*"
                  className="coupon-form-modal__input-field"
                  maxLength={6}
                  onInput={handleCodeChange}
                  placeholder={t('CouponForm.placeholder')}
                />
              </div>
              <div className="coupon-form-modal__content">
                <div className="text-center fw-bold">{t('CouponForm.title')}</div>
                <div className="coupon-form-modal__promovalue text-center">
                  {`${customerQuantity * 2 - totalCouponUsed}:${customerQuantity * 2}`}
                </div>
                <div className="coupon-form-modal__description">
                  <ul>
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: t('CouponForm.description1') }}></span>
                    </li>
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: t('CouponForm.description2') }}></span>
                    </li>
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: t('CouponForm.description3') }}></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="coupon-form-modal__footer group-button border-top-0">
              <div className="d-flex w-100">
                <button
                  type="button"
                  className="ara-btn ara-btn-outline w-100"
                  ref={buttonCancelRef}
                  onClick={handleClose}
                >
                  <div>{t('CouponForm.cancel')}</div>
                </button>
                <button type="submit" className="ara-btn ara-btn-primary w-100 p-10" ref={buttonConfirmRef}>
                  <div>{t('CouponForm.degree')}</div>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CouponFormModal;
