/* eslint-disable react/react-in-jsx-scope */
interface AraAngleDownIconProps {
  size?: number | string;
  color?: string;
}
export const AraAngleDownIcon = ({ size = 24, color = 'white' }: AraAngleDownIconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 11.3999L12 14.3999L9 11.3999"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
