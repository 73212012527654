import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { AllYouCanEatType, Payload } from 'types';

export interface AllYouCanEatState {
  loading: boolean;
  thMenuInfo: Payload | null;
  thCourseMenu: Payload | null;
  allYouCanEatState: AllYouCanEatType;
}

const initialState: AllYouCanEatState = {
  loading: true,
  thMenuInfo: null,
  thCourseMenu: null,
  allYouCanEatState: {
    isShowEatRemainTime: false,
    isShowDrinkRemainTime: false,
  },
};

const allYouCanEatSlice = createSlice({
  name: 'all_you_can_eat',
  initialState,
  reducers: {
    initData(state, action: PayloadAction<Payload>) {
      state.thMenuInfo = action.payload.thMenuInfo;
      state.thCourseMenu = action.payload.thCourseMenu;
      state.allYouCanEatState.isShowEatRemainTime =
        state.thCourseMenu?.th_flg === '1' && state.thCourseMenu?.th_limit > 0;
      state.allYouCanEatState.isShowDrinkRemainTime =
        state.thCourseMenu?.nh_flg === '1' && state.thCourseMenu?.nh_limit > 0;
      state.loading = false;
    },
    setThCourseMenu(state, action: PayloadAction<Payload>) {
      state.thCourseMenu = action.payload;
      state.allYouCanEatState.isShowEatRemainTime =
        state.thCourseMenu?.th_flg === '1' && state.thCourseMenu?.th_limit > 0;
      state.allYouCanEatState.isShowDrinkRemainTime =
        state.thCourseMenu?.nh_flg === '1' && state.thCourseMenu?.nh_limit > 0;
    },
    updateStatus(state, action: PayloadAction<{ isShowEatRemainTime: boolean; isShowDrinkRemainTime: boolean }>) {
      state.allYouCanEatState.isShowEatRemainTime = action.payload.isShowEatRemainTime;
      state.allYouCanEatState.isShowDrinkRemainTime = action.payload.isShowDrinkRemainTime;
    },
  },
});

// Actions
export const allYouCanEatActions = allYouCanEatSlice.actions;

// Selectors
export const selectAllYouCanEatLoading = (state: RootState) => state.allYouCanEat.loading;
export const selectThMenuInfo = (state: RootState) => state.allYouCanEat.thMenuInfo;
export const selectThCourseMenu = (state: RootState) => state.allYouCanEat.thCourseMenu;
export const selectIsShowRemainAllYouCanEat = (state: RootState) =>
  state.allYouCanEat.thCourseMenu !== null &&
  (state.allYouCanEat.allYouCanEatState.isShowEatRemainTime ||
    state.allYouCanEat.allYouCanEatState.isShowDrinkRemainTime);
export const selectAllYouCanEatState = (state: RootState) => state.allYouCanEat.allYouCanEatState;

// Reducer
export const allYouCanEatReducer = allYouCanEatSlice.reducer;
