import { AlertModalProps } from 'components/AlertModal/AlertModal';
import { dishDispatchContext } from 'components/DishModal/DishModal';
import { QuantityButtonGroup } from 'components/QuantityButtonGroup/QuantityButtonGroup';
import { MODAL_TYPES } from '../../constants';
import { log, LOG_OP_TYPE_MENU_DETAILS_QUANTITY_CHANGE } from 'log/Log';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { playSound } from 'sound/Sound';
import {
  checkIsExceedAllowQuantityOrder,
  countCouponUsed,
  getCategoryCodeFromDishCode,
  getDishInfo,
  getSourceMenuInfoByMode,
} from 'utils/Utils';
import './scss/PortionQuantitySelector.scss';
import { PortionQuantitySelectorProps } from './types';
import { GlobalModalContext } from 'components/GlobalModal';
import { useAppSelector } from 'app/hooks';
import { selectMenuInfo, selectOrderHistoryData } from 'store/mainSlice';
import { selectCutomerQuantity } from 'store/customerQuantitySlice';
import { selectThMenuInfo } from 'store/allYouCanEatSlice';
import { selectPhMenuInfo } from 'store/pitaSlice';
import { selectCartState } from 'store/cartSlice';

export default function PortionQuantitySelector({
  modeCode,
  itemCode,
  addedState,
  initialQuantity,
  quantity,
  type,
}: PortionQuantitySelectorProps) {
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();
  const menuInfo = useAppSelector(selectMenuInfo);
  const orderHistory = useAppSelector(selectOrderHistoryData);
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);
  const sourceMenuInfo = getSourceMenuInfoByMode(modeCode, menuInfo, thMenuInfo, phMenuInfo);
  const itemInfo = getDishInfo(sourceMenuInfo, itemCode);
  const coupon_flg = itemInfo?.coupon_flg;
  const dishDispatch = useContext(dishDispatchContext);
  const cartState = useAppSelector(selectCartState);
  const customerQuantity = useAppSelector(selectCutomerQuantity);
  const { t } = useTranslation();

  const categoryCode = getCategoryCodeFromDishCode(menuInfo, thMenuInfo, phMenuInfo, itemCode);

  const handleDecrease = () => {
    if (quantity > 1) {
      log({
        op_type: LOG_OP_TYPE_MENU_DETAILS_QUANTITY_CHANGE,
        op_detail: {
          mode: modeCode,
          category: categoryCode,
          poscd: itemCode,
          plus_minus: '0',
          num: quantity > 0 ? '1' : '0',
        },
      });

      dishDispatch({ type: 'decrease' });
    }
  };

  const handleIncrease = () => {
    if (checkIsExceedAllowQuantityOrder(modeCode, cartState, thMenuInfo, quantity + 1, customerQuantity)) {
      showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
        message: t('MenuItemDetail.exceed_quantity_warning'),
      });
      return undefined;
    }

    if (coupon_flg === '1') {
      const couponUsed = countCouponUsed(menuInfo, thMenuInfo, phMenuInfo, cartState, orderHistory.OrderDetail);
      const maxCouponQuantity = customerQuantity * 2;
      if (type === 'add') {
        if (couponUsed + quantity + 1 > maxCouponQuantity) {
          playSound('error');
          showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
            message: t('App.max_coupon_num_exceeded'),
          });
          return undefined;
        }
      } else {
        // if type === "replace"
        if (couponUsed - initialQuantity + quantity + 1 > maxCouponQuantity) {
          playSound('error');
          showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
            message: t('App.max_coupon_num_exceeded'),
          });
          return undefined;
        }
      }
    }
    log({
      op_type: LOG_OP_TYPE_MENU_DETAILS_QUANTITY_CHANGE,
      op_detail: {
        mode: modeCode,
        category: categoryCode,
        poscd: itemCode,
        plus_minus: '1',
        num: '1',
      },
    });
    dishDispatch({ type: 'increase', payload: [addedState] });
  };

  return (
    <QuantityButtonGroup
      handleDecrease={handleDecrease}
      handleIncrease={handleIncrease}
      quantity={quantity}
      min={1}
      disableMin
    />
  );
}
