import { useAppDispatch, useAppSelector } from 'app/hooks';
import AppLoading from 'components/AppLoading';
import useCheckSlipStatus from 'hooks/useCheckSlipStatus';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { customerQuantityActions } from 'store/customerQuantitySlice';
import { mainActions, selectMainLoading } from 'store/mainSlice';
import { checkIsMember } from 'utils/Utils';
interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  const appDispatch = useAppDispatch();
  const slipStatus = useCheckSlipStatus();
  const navigate = useNavigate();
  const startLoading = useAppSelector(selectMainLoading);

  // redirect to "thank-you page" when API return 0
  useEffect(() => {
    if (slipStatus === 0) {
      history.go(history.length * -1);
      navigate('/thanks-page', { replace: true });
    }
  }, [slipStatus]);

  useEffect(() => {
    if (slipStatus === 1 && startLoading) {
      appDispatch(mainActions.fetchStartData());
      appDispatch(customerQuantityActions.fetchCustomerQuantityData());
    }
  }, [slipStatus]);

  return !startLoading ? (
    <div className="layout-page">
      {checkIsMember() && <div className="app-padding-bar"></div>}
      {children}
    </div>
  ) : (
    <AppLoading isLoadingApp />
  );
}

export default Layout;
