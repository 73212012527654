import React, { useRef } from 'react';
import { Payload } from 'types';
import GlassOptionGroup from './GlassOptionGroup';

export interface GlassPortionProps {
  modeCode: string;
  itemCode: string;
  type: 'add' | 'replace';
  orderIdx: number;
  orderState: Payload;
}

function GlassPortion({ modeCode, itemCode, type, orderIdx, orderState }: GlassPortionProps) {
  const defaultRef = useRef<HTMLDivElement>(null);
  const ListOfGroups: Array<JSX.Element> = [];
  Object.entries(orderState).forEach(([groupCode, groupState]) => {
    if (groupState.basic.noselectflg !== '1' && groupState.basic.glassflg !== '0') {
      ListOfGroups.push(
        <GlassOptionGroup
          modeCode={modeCode}
          itemCode={itemCode}
          type={type}
          key={groupCode}
          orderIdx={orderIdx}
          groupState={groupState}
        />
      );
    }
  });

  return (
    <div className="collapse show" ref={defaultRef}>
      <div className={`list-group-wrapper ${ListOfGroups.length > 0 ? 'pt-4' : ''}`}>{ListOfGroups}</div>
    </div>
  );
}

export default GlassPortion;
