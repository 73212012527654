import { topMenuCategory } from 'apis/Api';
import Category from 'components/Category/Category';
import CategoryButton from 'components/CategoryButton/CategoryButton';
import MenuList from 'components/MenuList/MenuList';
import StickyContainer from 'components/StickyContainer/StickyContainer';
import Top from 'components/Top/Top';
import { log, LOG_OP_TYPE_CATEGORY_SWITCHING, LOG_OP_TYPE_TOP_MENU_SCREEN_FAIR_DETAILS_TRANSITION } from 'log/Log';
import { useEffect, useState } from 'react';
import { playSound } from 'sound/Sound';
import SwiperCore, { Lazy, Thumbs, Virtual } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import {
  attrLang,
  checkAllYouCanEatCategoryAvailable,
  checkCategoryAvailable,
  checkPitaCategoryAvailable,
  getSourceMenuInfoByMode,
  resetGenreNameSticky,
  scrollAnimationTo,
} from 'utils/Utils';
import {
  ALL_YOU_CAN_EAT_MENU_MODEID,
  CATEGORY_FREQUENTLY_ORDERED_MENU_ID,
  CATEGORY_SEARCH_MENU_ID,
  CATEGORY_TOP_MENU_ID,
  FREQUENTLY_ORDERED_MENU_MODEID,
  GENRE_STICKY_NAME_HEIGHT_DEFAULT,
  GRAND_MENU_MODEID,
  LUNCH_MENU_MODEID,
  PITA_MODEID,
  SEARCH_MENU_MODEID,
} from '../../constants';

import './scss/MenuSwipeList.scss';

// Import Swiper styles
import { useAppDispatch, useAppSelector } from 'app/hooks';
import HistoryButton from 'components/HistoryButton/HistoryButton';
import ModeBar from 'components/ModeBar/ModeBar';
import ViewCartButton from 'components/ViewCartButton/ViewCartButton';
import { useTranslation } from 'react-i18next';
import { selectThCourseMenu, selectThMenuInfo } from 'store/allYouCanEatSlice';
import {
  mainActions,
  selectCampaignGenrecd,
  selectCurrentCategoryCode,
  selectCurrentModeCode,
  selectMenuInfo,
  selectSearchPOSCD,
} from 'store/mainSlice';
import { selectPhCourseMenu, selectPhMenuInfo } from 'store/pitaSlice';
import 'swiper/css';
import 'swiper/css/lazy';
import 'swiper/css/pagination';
import 'swiper/css/virtual';
import SessionStorage, { SESSION_CURRENT_CATEGROY_CODE, SESSION_CURRENT_MODE_CODE } from 'utils/sessionStorage';

export interface MenuSwipeListProps {
  modeCode?: string;
}

function MenuSwipeList({ modeCode }: MenuSwipeListProps) {
  const { i18n } = useTranslation();
  const currentModeCode = modeCode !== undefined ? modeCode : (useAppSelector(selectCurrentModeCode) as string);
  const currentCategoryCode = useAppSelector(selectCurrentCategoryCode);
  const [isSearchMode, setIsSearchMode] = useState(currentCategoryCode === CATEGORY_SEARCH_MENU_ID);
  // store swiper instances
  const [mainSwiper, setMainSwiper] = useState<SwiperCore>();
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore>();
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const thCourseMenu = useAppSelector(selectThCourseMenu);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);
  const phCourseMenu = useAppSelector(selectPhCourseMenu);
  const searchPOSCD = useAppSelector(selectSearchPOSCD);
  const menuInfo = useAppSelector(selectMenuInfo);
  const currentModeInfo = menuInfo.mode[currentModeCode];

  const campaignGenrecd = useAppSelector(selectCampaignGenrecd);
  const appDispatch = useAppDispatch();

  // 0 is for top menu
  let currentCategoryCodes: string[] = [];
  if (currentModeCode !== SEARCH_MENU_MODEID && currentModeCode !== FREQUENTLY_ORDERED_MENU_MODEID) {
    currentCategoryCodes = [CATEGORY_TOP_MENU_ID].concat(
      currentModeInfo.category.filter((categoryCode: string) => {
        let isShow = true;
        if (currentModeCode === GRAND_MENU_MODEID || currentModeCode === LUNCH_MENU_MODEID) {
          isShow = checkCategoryAvailable(menuInfo, categoryCode);
        }

        return isShow;
      })
    );
  }

  // if mode All-you-can-eat
  // collect category is not available in mode All-you-can-eat
  const categoryNotAvailable: string[] = [];
  if (currentModeCode === ALL_YOU_CAN_EAT_MENU_MODEID) {
    currentCategoryCodes.forEach((categoryCode) => {
      if (categoryCode !== '0' && !checkAllYouCanEatCategoryAvailable(thMenuInfo, thCourseMenu, categoryCode)) {
        categoryNotAvailable.push(categoryCode);
      }
    });
  }
  // if mode Pita
  // collect category is not available in mode Pita
  if (currentModeCode === PITA_MODEID) {
    currentCategoryCodes.forEach((categoryCode) => {
      if (categoryCode !== '0' && !checkPitaCategoryAvailable(phMenuInfo, phCourseMenu, categoryCode)) {
        categoryNotAvailable.push(categoryCode);
      }
    });
  }

  const currentCategoryCodeAvailables = currentCategoryCodes.filter((x) => !categoryNotAvailable.includes(x));
  const sourceMenuInfo = getSourceMenuInfoByMode(currentModeCode, menuInfo, thMenuInfo, phMenuInfo);

  const appendedCategoryData = {
    ...sourceMenuInfo?.category,
    ...topMenuCategory,
  };

  const ListOfCategories = currentCategoryCodes.map((categoryCode, index) => {
    const categoryName = attrLang(appendedCategoryData[categoryCode], 'lang');

    const categoryButton: JSX.Element | null =
      appendedCategoryData[categoryCode] !== undefined ? (
        <SwiperSlide
          key={`CategoryButton-${currentModeCode}-${categoryCode}-${index}`}
          style={{ width: 'auto' }}
          className={categoryNotAvailable.includes(categoryCode) ? 'disabled' : ''}
          onClick={() => {
            (document.querySelector('.form-search-close-hide') as HTMLElement)?.click();
          }}
        >
          <CategoryButton
            isSearchMode={isSearchMode}
            setIsSearchMode={setIsSearchMode}
            modeCode={currentModeCode}
            categoryCode={categoryCode}
            categoryName={categoryName}
          />
        </SwiperSlide>
      ) : null;

    return categoryButton;
  });

  function resetStatusTopCategoryBar(categoryCode: string) {
    const currentCategoryButton = document.querySelector(`#category-btn-${categoryCode}`);
    if (!currentCategoryButton?.parentElement?.classList.contains('disabled')) {
      //set thumb active
      const categoryButtonActive = document.querySelector('#category-button-block .category-button.active');
      if (categoryButtonActive) {
        categoryButtonActive.classList.remove('active');
      }

      currentCategoryButton?.classList.add('active');
    }
  }

  const mainInitialSlideIndex =
    currentCategoryCodeAvailables.indexOf(currentCategoryCode) >= 0
      ? currentCategoryCodeAvailables.indexOf(currentCategoryCode)
      : 0;

  const menuListSwipeProps: SwiperProps = {
    initialSlide: mainInitialSlideIndex >= 0 ? mainInitialSlideIndex : 0,
    autoHeight: true,
    spaceBetween: 0,
    navigation: false,
    lazy: true,
    modules: [Lazy, Virtual],
    virtual: true,
    // thumbs: { swiper: thumbsSwiper },
    onSwiper: setMainSwiper,
    onUpdate: () => {
      setTimeout(() => {
        const modeThumbButtons = document.querySelectorAll('.mode-thumb-button');
        if (modeThumbButtons) {
          modeThumbButtons.forEach((modeThumbButton) => {
            (modeThumbButton as HTMLButtonElement).disabled = false;
          });
        }
      }, 300);
    },
    onSlideChange: (swiper) => {
      setTimeout(() => {
        const categoryThumbnail = document.querySelector('.category-thumbnail-' + swiper.activeIndex);
        if (categoryThumbnail) {
          (categoryThumbnail as HTMLButtonElement).disabled = false;
        }
      }, 300);

      const categoryCodeActive = currentCategoryCodeAvailables[swiper.activeIndex];
      const thumbIndex = currentCategoryCodes.findIndex((x) => x === categoryCodeActive);
      if (thumbIndex >= 0) {
        thumbsSwiper?.slideTo(thumbIndex);
        resetStatusTopCategoryBar(categoryCodeActive);

        // save categoryCode to SessionStorage
        appDispatch(mainActions.setCurrentCategoryCode(categoryCodeActive));
        SessionStorage.setItem(SESSION_CURRENT_MODE_CODE, currentModeCode);
        SessionStorage.setItem(SESSION_CURRENT_CATEGROY_CODE, categoryCodeActive);

        setIsSearchMode(false);

        log({
          op_type: LOG_OP_TYPE_CATEGORY_SWITCHING,
          op_detail: {
            mode: currentModeCode,
            category: categoryCodeActive,
          },
        });

        resetGenreNameSticky();
      }
    },
    onSlideChangeTransitionStart: () => {
      (document.querySelector('.page-wrapper') as HTMLElement).scrollTop = 0;
    },
  };

  let searchResultList = null;
  switch (currentCategoryCode) {
    case CATEGORY_SEARCH_MENU_ID:
    case CATEGORY_FREQUENTLY_ORDERED_MENU_ID:
      searchResultList = (
        <div className="menu-list search">
          <MenuList currentModeCode={currentModeCode} categoryCode={currentCategoryCode} searchPOSCD={searchPOSCD} />
        </div>
      );
      break;
    default:
      searchResultList = null;
      break;
  }

  useEffect(() => {
    if (searchPOSCD === null) {
      setIsSearchMode(false);
    } else {
      setIsSearchMode(true);
      if (searchPOSCD.length > 0) {
        const menuListSearchElem = document.querySelector(
          '#category-button-block .swiper-slide.swiper-slide-thumb-active'
        ) as HTMLDivElement;
        if (menuListSearchElem) {
          menuListSearchElem.classList.remove('swiper-slide-thumb-active');
        }
      }
    }
  }, [searchPOSCD]);

  useEffect(() => {
    if (currentCategoryCode !== CATEGORY_SEARCH_MENU_ID) {
      if (currentCategoryCode == CATEGORY_TOP_MENU_ID) {
        resetStatusTopCategoryBar(CATEGORY_TOP_MENU_ID);
      }

      setIsSearchMode(false);
      if (campaignGenrecd === '') {
        thumbsSwiper?.slideTo(0);
        mainSwiper?.slideTo(0);
      } else {
        const slideNextIndex = currentCategoryCodeAvailables.indexOf(currentCategoryCode);
        mainSwiper?.slideTo(slideNextIndex < 0 ? 0 : slideNextIndex);

        setTimeout(() => {
          if (slideNextIndex > 0 && campaignGenrecd !== '') {
            const genreMenuSection = document.getElementById(`genre-menu-${campaignGenrecd}`) as HTMLDivElement;

            if (genreMenuSection) {
              const scrolltopElem = genreMenuSection.offsetTop - genreMenuSection.scrollTop;
              scrollAnimationTo(
                document.querySelector('.page-wrapper') as HTMLElement,
                scrolltopElem > 0 ? scrolltopElem + GENRE_STICKY_NAME_HEIGHT_DEFAULT : 0,
                600
              );
            }

            appDispatch(mainActions.updateCampaignGenrecd(''));
          }
        }, 300);

        log({
          op_type: LOG_OP_TYPE_TOP_MENU_SCREEN_FAIR_DETAILS_TRANSITION,
          op_detail: {
            mode: currentModeCode,
            campaign: currentCategoryCode,
            genrecd: campaignGenrecd,
          },
        });
      }
    }
  }, [currentModeCode]);

  useEffect(() => {
    if (thumbsSwiper) {
      thumbsSwiper.update();
      mainSwiper?.virtual.update(true);
    }
  }, [i18n.language]);

  const thumbInitialSlideIndex = currentCategoryCodes.indexOf(currentCategoryCode);

  return (
    <>
      <StickyContainer
        scrollElement=".page-wrapper"
        stickySelector=".menu-list.main .swiper-slide-active .genre-name"
        isSearchMode={isSearchMode}
      >
        <Swiper
          id="category-button-block"
          className={`${isSearchMode ? 'search' : ''}`}
          modules={[Thumbs]}
          onSwiper={setThumbsSwiper}
          spaceBetween={0}
          watchSlidesProgress={true}
          slideToClickedSlide={false}
          slidesPerView={'auto'}
          centeredSlides={true}
          centeredSlidesBounds={true}
          initialSlide={thumbInitialSlideIndex >= 0 ? thumbInitialSlideIndex : 0}
          onInit={() => {
            if (!currentCategoryCodes.includes(currentCategoryCode)) {
              resetStatusTopCategoryBar(CATEGORY_TOP_MENU_ID);
            }
          }}
          onClick={(swiper) => {
            if (swiper.clickedIndex !== undefined) {
              const categoryCodeActive = currentCategoryCodes[swiper.clickedIndex];
              resetStatusTopCategoryBar(categoryCodeActive);

              const currentCategoryButton = document.querySelector(`#category-btn-${categoryCodeActive}`);
              const isDisabledSlide = currentCategoryButton?.parentElement?.classList.contains('disabled');
              if (!isDisabledSlide) {
                playSound('page');

                const mainSlideIndex = currentCategoryCodeAvailables.findIndex((x) => x === categoryCodeActive);
                if (mainSlideIndex >= 0) {
                  mainSwiper?.slideTo(mainSlideIndex);

                  // save categoryCode to SessionStorage and main store
                  appDispatch(mainActions.setCurrentCategoryCode(categoryCodeActive));
                  SessionStorage.setItem(SESSION_CURRENT_MODE_CODE, currentModeCode);
                  SessionStorage.setItem(SESSION_CURRENT_CATEGROY_CODE, categoryCodeActive);
                  setIsSearchMode(false);

                  log({
                    op_type: LOG_OP_TYPE_CATEGORY_SWITCHING,
                    op_detail: {
                      mode: currentModeCode,
                      category: categoryCodeActive,
                    },
                  });

                  resetGenreNameSticky();
                }
              }
            }
          }}
        >
          {ListOfCategories}
        </Swiper>

        <div className="genre-name sticky d-none" data-height={GENRE_STICKY_NAME_HEIGHT_DEFAULT} id="genre-name-sticky">
          .
        </div>

        {isSearchMode && searchResultList}
        <Swiper
          className="menu-list main"
          {...menuListSwipeProps}
          style={{ display: `${isSearchMode ? 'none' : 'block'}` }}
        >
          {currentCategoryCodeAvailables.map((categoryCode, indexCategoryCode) => {
            return categoryCode === '0' ? (
              <SwiperSlide key={`MenuList-${currentModeCode}-0`} data-categoryid={0} virtualIndex={0}>
                <Top currentCategoryCodes={currentCategoryCodes} mainSwiper={mainSwiper} />
                <Category mainSwiper={mainSwiper} currentCategoryCodeAvailables={currentCategoryCodeAvailables} />
              </SwiperSlide>
            ) : (
              <SwiperSlide
                className="menu-list-slide"
                key={`MenuList-${currentModeCode}-${categoryCode}`}
                virtualIndex={indexCategoryCode}
                data-categoryid={categoryCode}
              >
                <MenuList currentModeCode={currentModeCode} categoryCode={categoryCode} searchPOSCD={searchPOSCD} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </StickyContainer>
      <HistoryButton />
      <ViewCartButton />
      <ModeBar mainSwiper={mainSwiper} />
    </>
  );
}

export default MenuSwipeList;
