import React from 'react';
import { useTranslation } from 'react-i18next';
import { AraLogoIcon } from 'theme/icons';
import './scss/ThankPage.scss';

export default function ThankPage() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  return (
    <div className="thanks-page">
      <div className="logo">
        <AraLogoIcon width={214.38} height={68} color={'#fff'} />
        <div className="content">
          <strong>{t('ThankPage.thank_you_very_much')}</strong>
          <br />
          <strong>{t('ThankPage.we_look_forward_to_seeing_you_again')}</strong>
        </div>
      </div>
    </div>
  );
}
