import { GlobalModalContext } from 'components/GlobalModal';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { playSound } from 'sound/Sound';
import { TAP_ANIMATION_TIME } from '../../constants';
import './scss/ConfirmModal.scss';

export interface ConfirmModalProps {
  message: string;
  textCancel?: string;
  textOk?: string;
  actionClose?: () => void;
  actionAgree?: () => void;
  confirmSound?: string;
}

export default function ConfirmModal({
  message,
  textCancel,
  textOk,
  actionClose,
  actionAgree,
  confirmSound = 'ok',
}: ConfirmModalProps) {
  const { t } = useTranslation();
  const { closeGlobalModal } = GlobalModalContext.useGlobalModalContext();
  const buttonAgreeRef = useRef<HTMLButtonElement>(null);

  const handleClose = () => {
    setTimeout(() => {
      playSound('cancel');
      actionClose && actionClose();
      closeGlobalModal();
    }, TAP_ANIMATION_TIME / 2);
  };

  const handleAgree = () => {
    // check button has disabled status, prevent click
    if (buttonAgreeRef.current) {
      if (buttonAgreeRef.current.disabled) {
        return;
      }
    }

    // change status of button to disabled
    if (buttonAgreeRef.current) buttonAgreeRef.current.disabled = true;

    setTimeout(() => {
      playSound(confirmSound);
      actionAgree && actionAgree();
      closeGlobalModal();
    }, TAP_ANIMATION_TIME / 2);
  };

  return (
    <div className="modal alert-modal ara-dialog" data-bs-backdrop="static" tabIndex={-1} style={{ display: 'block' }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <div className="modal-message" dangerouslySetInnerHTML={{ __html: message }}></div>
          </div>

          <div className="dialog-footer d-flex flex-row">
            <div className="col pe-1">
              <button className="ara-btn ara-btn-outline w-100" onClick={handleClose}>
                <div>{textCancel ?? t('App.no')}</div>
              </button>
            </div>
            <div className="col ps-1">
              <button className="ara-btn ara-btn-primary w-100" onClick={handleAgree}>
                {textOk ?? t('App.agree')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
