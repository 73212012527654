import { useAppSelector } from 'app/hooks';
import { AlertModalProps } from 'components/AlertModal/AlertModal';
import { GlobalModalContext } from 'components/GlobalModal';
import { DishModalContext } from 'pages/MainPage/MainPage';
import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { playSound } from 'sound/Sound';
import { selectThMenuInfo } from 'store/allYouCanEatSlice';
import { selectCartState } from 'store/cartSlice';
import { selectCutomerQuantity } from 'store/customerQuantitySlice';
import { selectMenuInfo, selectOrderHistoryData } from 'store/mainSlice';
import { selectPhMenuInfo } from 'store/pitaSlice';
import { AraTicketIcon } from 'theme/icons';
import { countCouponUsed } from 'utils/Utils';
import { MODAL_TYPES, TAP_ANIMATION_TIME } from '../../../constants';

function CouponIcon() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();
  const { showCouponFormModal } = useContext(DishModalContext);
  const cartState = useAppSelector(selectCartState);
  const menuInfo = useAppSelector(selectMenuInfo);
  const orderHistory = useAppSelector(selectOrderHistoryData);
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);

  const customerQuantity = useAppSelector(selectCutomerQuantity);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    // check button has disabled status, prevent click
    if (buttonRef.current) {
      if (buttonRef.current.disabled) {
        return;
      }
    }

    // change status of button to disabled
    if (buttonRef.current) buttonRef.current.disabled = true;

    setTimeout(() => {
      // check exceed maximum number of coupon
      const totalCouponUsed = countCouponUsed(menuInfo, thMenuInfo, phMenuInfo, cartState, orderHistory.OrderDetail);
      const maxCouponQuantity = customerQuantity * 2;
      if (totalCouponUsed >= maxCouponQuantity) {
        showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
          message: t('App.max_coupon_exceed_limit'),
          actionClose: () => {
            if (buttonRef.current) buttonRef.current.disabled = false;
          },
        });
      } else {
        playSound('page');
        showCouponFormModal && showCouponFormModal();
      }
    }, TAP_ANIMATION_TIME / 2);
  };

  return (
    <>
      <button
        className="coupon-button ara-btn-link align-items-center d-inline-flex"
        ref={buttonRef}
        onClick={handleClick}
      >
        <span className="coupon-button__text">{t('Header.use_counpon')}</span>
        <AraTicketIcon />
      </button>
    </>
  );
}

export default React.memo(CouponIcon);
