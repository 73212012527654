import React from 'react';
import { playSound } from 'sound/Sound';
import { AraMinusIcon, AraPlusIcon } from 'theme/icons';
import './scss/QuantityButtonGroup.scss';
import { QuantityButtonGroupProps } from './types';

export function QuantityButtonGroup({
  handleDecrease,
  quantity,
  handleIncrease,
  min,
  disableMin = false,
}: QuantityButtonGroupProps) {
  const addedHandleDecrease = () => {
    if (min && quantity === min && disableMin) {
      return;
    }

    handleDecrease();
    playSound('minus');
  };

  const addedHandleIncrease = () => {
    handleIncrease();
    playSound('plus');
  };

  return (
    <div className="btn-group quantity-control" role="group" aria-label="Basic outlined example">
      <a
        className={`btn-minus ${min && quantity === min && disableMin ? 'disabled' : ''}`}
        onClick={addedHandleDecrease}
      >
        <AraMinusIcon />
      </a>
      <span className="">{quantity}</span>
      <a className="btn-plus" onClick={addedHandleIncrease}>
        <AraPlusIcon />
      </a>
    </div>
  );
}
