/* eslint-disable react/react-in-jsx-scope */
interface AraArrowLeftIconProps {
  size?: number | string;
  color?: string;
}
export const AraArrowLeftIcon = ({ size = 24, color = '#D94800' }: AraArrowLeftIconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.3672 19.0078C14.625 19.25 14.9141 19.375 15.2734 19.375C15.9922 19.375 16.5859 18.7891 16.5859 18.0781C16.5859 17.7188 16.4297 17.3828 16.1641 17.1172L11.0625 12.1797L16.1641 7.25781C16.4375 6.98438 16.5859 6.64844 16.5859 6.29688C16.5859 5.58594 15.9922 5 15.2734 5C14.9141 5 14.6172 5.11719 14.3672 5.36719L8.49219 11.1094C8.15625 11.4375 8.00781 11.7734 8 12.1875C8 12.5938 8.14844 12.9297 8.49219 13.2656L14.3672 19.0078Z"
        fill={color}
      />
    </svg>
  );
};
