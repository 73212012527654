//import { push } from 'connected-react-router';
import { getData, getOrderHistory } from 'apis/Api';
import { CartInfoType } from 'apis/types';
import { handleHttpError, handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Payload } from 'types';
import { convertCartInfoToCartState } from 'utils/Utils';
import { RES_CODE_SUCCESS } from '../constants';
import { allYouCanEatActions } from './allYouCanEatSlice';
import { cartActions } from './cartSlice';
import { mainActions } from './mainSlice';
import { pitaActions } from './pitaSlice';
import { timeFrameActions } from './timeFrameSlice';

function* fetchStartData() {
  try {
    const response: Payload = yield call(getData);
    if (response.code !== RES_CODE_SUCCESS) {
      yield handleResponseError(response);
    } else {
      // initial TimeFrame Store
      yield put(timeFrameActions.setTimeFrameData(response.data.start_tm));

      // initial AllYouCanEat Store
      const thCourseMenu = response.data.th_course_menu === undefined ? null : response.data.th_course_menu;
      thCourseMenu.nh_limit = Number(thCourseMenu.nh_limit);
      thCourseMenu.th_limit = Number(thCourseMenu.th_limit);
      yield put(allYouCanEatActions.initData({ thMenuInfo: response.data.th_menu_info, thCourseMenu: thCourseMenu }));

      // initial Pita Store
      const phCourseMenu = response.data.ph_course_menu === undefined ? null : response.data.ph_course_menu;
      phCourseMenu.nh_limit = Number(phCourseMenu.nh_limit);
      phCourseMenu.ph_limit = Number(phCourseMenu.ph_limit);
      yield put(pitaActions.initData({ phMenuInfo: response.data.ph_menu_info, phCourseMenu: phCourseMenu }));

      // initial Cart Store
      const cartInfo: CartInfoType[] = response.data.order_list;
      const menuInfo: Payload = response.data.menu_info;
      const cartState = convertCartInfoToCartState(menuInfo, cartInfo);
      yield put(cartActions.initData(cartState));

      // initial Main Store
      yield put(mainActions.fetchStartDataSuccess(response));
    }
  } catch (e) {
    yield put(mainActions.fetchStartDataFailed());
    yield handleHttpError(e as AxiosError);
  }
}

function* fetchOrderHistoryData() {
  // order history doesn't need to show error dialog when calling API with error
  try {
    const response: Payload = yield call(getOrderHistory);
    yield put(mainActions.fetchOrderHistoryDataSuccess(response));
  } catch (e) {
    yield put(mainActions.fetchOrderHistoryDataFailed());
  }
}

export default function* mainSaga() {
  yield takeLatest(mainActions.fetchStartData.type, fetchStartData);
  yield takeLatest(mainActions.fetchOrderHistoryData.type, fetchOrderHistoryData);
}
