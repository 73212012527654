import Thumbnail from 'components/Thumbnail/Thumbnail';
import {
  ALL_YOU_CAN_EAT_MENU_MODEID,
  GRAND_MENU_MODEID,
  LUNCH_MENU_MODEID,
  PITA_MODEID,
  TAP_ANIMATION_TIME,
} from '../../../constants';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { LOG_OP_TYPE_CATEGORY_SWITCHING, log } from 'log/Log';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { playSound } from 'sound/Sound';
import { selectThCourseMenu, selectThMenuInfo } from 'store/allYouCanEatSlice';
import { mainActions, selectMenuInfo } from 'store/mainSlice';
import { selectPhCourseMenu, selectPhMenuInfo } from 'store/pitaSlice';
import { Swiper as SwiperCore } from 'swiper';
import { Payload } from 'types';
import {
  attrLang,
  checkAllYouCanEatCategoryAvailable,
  checkCategoryAvailable,
  checkPitaCategoryAvailable,
  imgFullPath,
} from 'utils/Utils';
import SessionStorage, { SESSION_CURRENT_CATEGROY_CODE } from 'utils/sessionStorage';

export interface CategoryThumbnailProps {
  mainSwiper?: SwiperCore;
  slideIndex: number;
  modeCode: string;
  categoryCode: string;
  categoryInfo: Payload;
}

function CategoryThumbnail({ mainSwiper, slideIndex, modeCode, categoryCode, categoryInfo }: CategoryThumbnailProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { i18n } = useTranslation();
  const appDispatch = useAppDispatch();
  const menuInfo = useAppSelector(selectMenuInfo);
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const thCourseMenu = useAppSelector(selectThCourseMenu);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);
  const phCourseMenu = useAppSelector(selectPhCourseMenu);
  const name = attrLang(categoryInfo, 'lang');
  const imgSrc = imgFullPath(categoryInfo?.img);

  const isDisabled =
    (modeCode === ALL_YOU_CAN_EAT_MENU_MODEID &&
      !checkAllYouCanEatCategoryAvailable(thMenuInfo, thCourseMenu, categoryCode)) ||
    (modeCode === PITA_MODEID && !checkPitaCategoryAvailable(phMenuInfo, phCourseMenu, categoryCode));

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!isDisabled && !(e.currentTarget as HTMLButtonElement).disabled) {
        (e.currentTarget as HTMLButtonElement).disabled = true;
        setTimeout(() => {
          log({
            op_type: LOG_OP_TYPE_CATEGORY_SWITCHING,
            op_detail: {
              mode: modeCode,
              category: categoryCode,
            },
          });
          playSound('page');
          mainSwiper && mainSwiper.slideTo(slideIndex);

          // save categoryCode to SessionStorage and main store
          appDispatch(mainActions.setCurrentCategoryCode(categoryCode));
          SessionStorage.setItem(SESSION_CURRENT_CATEGROY_CODE, categoryCode);
        }, TAP_ANIMATION_TIME / 2);
      }
    },
    [mainSwiper]
  );

  let isShow = true;
  if (modeCode === GRAND_MENU_MODEID || modeCode === LUNCH_MENU_MODEID) {
    isShow = checkCategoryAvailable(menuInfo, categoryCode);
  }

  return isShow ? (
    <Thumbnail
      thumbType="category"
      categorySlideIndex={slideIndex}
      modeCode={modeCode}
      name={name}
      imgSrc={imgSrc}
      handleClick={handleClick}
      disable={isDisabled}
      lazy={true}
    />
  ) : null;
}

export default React.memo(CategoryThumbnail);
