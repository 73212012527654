import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ConfirmModalProps } from 'components/ComfirmModal/ConfirmModal';
import { GlobalModalContext } from 'components/GlobalModal';
import { PortionStateType } from 'components/MenuDetail/types';
import { MODAL_TYPES } from '../../../constants';
import { log, LOG_OP_TYPE_CART_SCREEN_QUANTITY_CHANGE } from 'log/Log';
import { useTranslation } from 'react-i18next';
import { selectThMenuInfo } from 'store/allYouCanEatSlice';
import { cartActions, selectCartState } from 'store/cartSlice';
import { selectCutomerQuantity } from 'store/customerQuantitySlice';
import { selectMenuInfo, selectOrderHistoryData } from 'store/mainSlice';
import { selectPhMenuInfo } from 'store/pitaSlice';
import { Payload } from 'types';
import { attrLang, checkIsExceedAllowQuantityOrder, countCouponUsed, imgFullPath, stripHtml } from 'utils/Utils';
import { playSound } from 'sound/Sound';
import { AlertModalProps } from 'components/AlertModal/AlertModal';
import { QuantityButtonGroup } from 'components/QuantityButtonGroup/QuantityButtonGroup';

export interface DishQuantitySelectorProps {
  dishInfo: Payload | undefined;
  dishKey: string;
  addedState: PortionStateType;
  quantity: number;
}

export function DishQuantitySelector({ dishInfo, dishKey, addedState, quantity }: DishQuantitySelectorProps) {
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();
  const appDispatch = useAppDispatch();
  const customerQuantity = useAppSelector(selectCutomerQuantity);
  const cartState = useAppSelector(selectCartState);
  const orderHistory = useAppSelector(selectOrderHistoryData);
  const menuInfo = useAppSelector(selectMenuInfo);
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);
  const [modeCode, dishCode] = JSON.parse(dishKey);
  const { t } = useTranslation();
  const orderName = stripHtml(attrLang(dishInfo, 'lang'));
  const imgSrc = imgFullPath(dishInfo?.img);

  const handleAgree = () => {
    const dishesElem = document.getElementById('dishes');
    if (dishesElem) {
      dishesElem.classList.remove('openModal');
    }

    log({
      op_type: LOG_OP_TYPE_CART_SCREEN_QUANTITY_CHANGE,
      op_detail: {
        poscd: dishCode,
        plus_minus: '0',
        num: '1',
      },
    });

    return appDispatch(
      cartActions.decrease({
        key: dishKey,
        payload: addedState,
      })
    );
  };

  const handleDecrease = () => {
    if (quantity === 1) {
      const html = `<div class="mb-3 w-100">
        <div class="mb-3">
          <div class="d-flex">
            <div class="col-5"><img src="${imgSrc}" /></div>
            <div class="col-7 ps-2 text-start">${orderName}</div>
          </div>
        </div>
        <div>${t('App.confirm_delete_item')}</div>
      </div>`;
      showGlobalModal<ConfirmModalProps>(MODAL_TYPES.CONFIRM_MODAL, {
        message: html,
        actionAgree: handleAgree,
        confirmSound: 'delete',
      });
    } else {
      log({
        op_type: LOG_OP_TYPE_CART_SCREEN_QUANTITY_CHANGE,
        op_detail: {
          poscd: dishCode,
          plus_minus: '0',
          num: '1',
        },
      });

      return appDispatch(
        cartActions.decrease({
          key: dishKey,
          payload: addedState,
        })
      );
    }
  };

  const handleIncrease = () => {
    // check exceed coupon
    if (dishInfo?.coupon_flg === '1') {
      const maxCouponQuantity = customerQuantity * 2;
      const usedCouponQuantity = countCouponUsed(menuInfo, thMenuInfo, phMenuInfo, cartState, orderHistory.OrderDetail);
      if (usedCouponQuantity + 1 > maxCouponQuantity) {
        playSound('error');
        showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
          message: t('App.max_coupon_num_exceeded'),
        });
        return undefined;
      }
    }

    // check exceed Allow Quantity Order
    const isExceedAllowQuantityOrder = checkIsExceedAllowQuantityOrder(
      modeCode,
      cartState,
      thMenuInfo,
      1,
      customerQuantity
    );
    if (isExceedAllowQuantityOrder) {
      showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
        message: t('MenuItemDetail.exceed_quantity_warning'),
      });
      return undefined;
    }

    log({
      op_type: LOG_OP_TYPE_CART_SCREEN_QUANTITY_CHANGE,
      op_detail: {
        poscd: dishCode,
        plus_minus: '1',
        num: '1',
      },
    });

    return appDispatch(
      cartActions.increase({
        key: dishKey,
        payload: [addedState],
      })
    );
  };

  return <QuantityButtonGroup handleDecrease={handleDecrease} handleIncrease={handleIncrease} quantity={quantity} />;
}
