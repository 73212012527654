import { Payload } from 'types';
import { getCustomerId } from 'utils/Utils';
import { PH_INFO_EMPTY_INFO_CODE, RES_CODE_UNAUTHORIZED, TH_INFO_EMPTY_INFO_CODE } from '../constants';
import axiosClient from './axiosClient';
import { CartInfoType, OrderHistoryAPIType } from './types';

export const topMenuCategory: Payload = {
  '0': {
    categorycd: '0',
    lang: {
      jp: 'TOP',
      us: 'TOP',
      cn: 'TOP',
      kr: 'TOP',
      vn: 'TOP',
    },
    img: { jp: null },
  },
};

export const imgDB = process.env.REACT_APP_S3_ENDPOINT;

export async function sendLog(): Promise<Payload | undefined> {
  const storageLogs = localStorage.getItem('logs');
  if (!storageLogs) return undefined;

  const response = await axiosClient.post(
    `post_op_log.php`,
    JSON.stringify({
      customer_id: getCustomerId(),
      op_log: JSON.parse(storageLogs),
    })
  );

  if (response.status === 200) {
    localStorage.setItem('logs', '[]');
  }

  return response.data;
}

export async function getData(): Promise<Payload> {
  const response = await axiosClient({
    method: 'post',
    url: `start.php`,
    data: JSON.stringify({
      customer_id: getCustomerId(),
    }),
  });

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = '/thanks-page';
  }

  return response.data;
}

export async function getFreqMenu(): Promise<Payload> {
  const response = await axiosClient.get(`get_frequently_ordered_menu.php`);

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = '/thanks-page';
  }

  return response.data.data;
}

export async function getFavMenu(): Promise<Payload> {
  const response = await axiosClient.get(`get_favorite.php`);

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = '/thanks-page';
  }

  return response.data.data;
}

export async function sendOrder(orderInfo: Payload): Promise<Payload> {
  const response = await axiosClient({
    method: 'post',
    url: `order.php`,
    data: JSON.stringify(orderInfo),
  });

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = '/thanks-page';
  }

  return response;
}

export async function updateFav(itemCode: string, modeCode: string, type: string): Promise<Payload> {
  const modDirection: number = type === 'add' ? 1 : 0;
  const response = await axiosClient({
    method: 'post',
    url: `upd_favorite.php`,
    data: JSON.stringify({
      customer_id: getCustomerId(),
      mode: modeCode,
      poscd: itemCode,
      type: modDirection,
    }),
  });

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = '/thanks-page';
  }

  return response.data;
}

export async function getOrderHistory(): Promise<OrderHistoryAPIType> {
  const response = await axiosClient({
    method: 'post',
    url: `get_order_history.php`,
    data: JSON.stringify({
      customer_id: getCustomerId(),
    }),
  });

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = '/thanks-page';
  }

  return response.data;
}

export async function getCustomerQuantity(): Promise<Payload> {
  const response = await axiosClient({
    method: 'post',
    url: `get_customer_num.php`,
    data: JSON.stringify({
      customer_id: getCustomerId(),
    }),
  });

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = '/thanks-page';
  }

  return response.data;
}

export async function updateCartInfo(orderList: CartInfoType[]): Promise<Payload> {
  const response = await axiosClient({
    method: 'post',
    url: `upd_orderlist.php`,
    data: JSON.stringify({
      customer_id: getCustomerId(),
      order_list: orderList,
    }),
  });

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = '/thanks-page';
  }

  return response;
}

export async function getTimeFrame(): Promise<Payload> {
  const response = await axiosClient({
    method: 'post',
    url: `get_timeframe.php`,
    data: JSON.stringify({
      customer_id: getCustomerId(),
    }),
  });

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = '/thanks-page';
  }

  return response.data;
}

export async function getThInfo(): Promise<Payload> {
  const response = await axiosClient({
    method: 'post',
    url: `get_th_info.php`,
    data: JSON.stringify({
      customer_id: getCustomerId(),
    }),
  });

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = '/thanks-page';
    return {};
  }

  if (response.data.code === TH_INFO_EMPTY_INFO_CODE) {
    response.data.data = {};
  }
  return response.data;
}

export async function getThRemainTm(): Promise<Payload> {
  const response = await axiosClient({
    method: 'post',
    url: `get_th_remain_tm.php`,
    data: JSON.stringify({
      customer_id: getCustomerId(),
    }),
  });

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = '/thanks-page';
  }

  return response.data;
}

export async function callStaff(serviceRequiredItems: string[]): Promise<Payload> {
  const response = await axiosClient({
    method: 'post',
    url: `call_staff.php`,
    data: JSON.stringify({
      customer_id: getCustomerId(),
      callkbn: '05',
      poscds: serviceRequiredItems,
    }),
  });

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = '/thanks-page';
  }

  return response.data;
}

export async function checkUserCheckout(): Promise<Payload> {
  const response = await axiosClient({
    method: 'post',
    url: `chk_slip_status.php`,
    data: JSON.stringify({
      customer_id: getCustomerId(),
    }),
  });

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = '/thanks-page';
  }

  return response.data;
}

export async function getPhInfo(): Promise<Payload> {
  const response = await axiosClient({
    method: 'post',
    url: `get_ph_info.php`,
    data: JSON.stringify({
      customer_id: getCustomerId(),
    }),
  });

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = '/thanks-page';
    return {};
  }

  if (response.data.code === PH_INFO_EMPTY_INFO_CODE) {
    response.data.data = {};
  }
  return response.data;
}

export async function getPhRemainTm(): Promise<Payload> {
  const response = await axiosClient({
    method: 'post',
    url: `get_ph_remain_tm.php`,
    data: JSON.stringify({
      customer_id: getCustomerId(),
    }),
  });

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = '/thanks-page';
  }

  return response.data;
}
