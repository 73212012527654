import { getPhInfo, getThInfo, getTimeFrame } from 'apis/Api';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { AlertModalProps } from 'components/AlertModal/AlertModal';
import { GlobalModalContext } from 'components/GlobalModal';
import { LOG_OP_TYPE_MODE_SWITCHING, log } from 'log/Log';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { playSound } from 'sound/Sound';
import { allYouCanEatActions } from 'store/allYouCanEatSlice';
import { mainActions } from 'store/mainSlice';
import { pitaActions } from 'store/pitaSlice';
import { selectTimeFrame, timeFrameActions } from 'store/timeFrameSlice';
import { Swiper as SwiperCore } from 'swiper';
import { Payload } from 'types';
import { attrLang, dispatchErrorResponse, scrollAnimationTo } from 'utils/Utils';
import SessionStorage, { SESSION_CURRENT_CATEGROY_CODE, SESSION_CURRENT_MODE_CODE } from 'utils/sessionStorage';
import {
  ALL_YOU_CAN_EAT_MENU_MODEID,
  CATEGORY_TOP_MENU_ID,
  GRAND_MENU_MODEID,
  LUNCH_MENU_MODEID,
  MODAL_TYPES,
  PH_INFO_EMPTY_INFO_CODE,
  PITA_MODEID,
  RES_CODE_SUCCESS,
  TAP_ANIMATION_TIME,
  TH_INFO_EMPTY_INFO_CODE,
  TIME_FRAME_GRAND,
  TIME_FRAME_LUNCH,
} from '../../constants';
import './scss/ModeThumbnail.scss';

export interface ModeThumbnailProps {
  mainSwiper?: SwiperCore;
  modeInfo: Payload;
  active: boolean;
  showCourseIntroduce: (show: boolean, type: string) => void;
}

function ModeThumbnail({ mainSwiper, modeInfo, active, showCourseIntroduce }: ModeThumbnailProps) {
  const appDispatch = useAppDispatch();

  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();
  const code: string = modeInfo.modecd;
  const name = attrLang(modeInfo, 'lang');
  const timeFrame = useAppSelector(selectTimeFrame);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (e: React.MouseEvent) => {
    const modeButton = e.target as HTMLButtonElement;
    modeButton.disabled = true;
    if (code === LUNCH_MENU_MODEID) {
      setIsLoading(true);
      const updatedTimeFrameRes = await getTimeFrame();
      setIsLoading(false);
      const updatedTimeFrame = parseInt(updatedTimeFrameRes.data.timeframe) || TIME_FRAME_GRAND;
      if (updatedTimeFrame !== timeFrame) {
        appDispatch(timeFrameActions.setTimeFrameData(updatedTimeFrameRes.data));
      }

      if (updatedTimeFrame !== TIME_FRAME_LUNCH) {
        playSound('confirm');
        showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
          message: t('App.lunch_menu_unavailable'),
          actionClose: () => {
            modeButton.disabled = false;
          },
        });
        return undefined;
      }
    }

    // all you can eat mode
    if (code === ALL_YOU_CAN_EAT_MENU_MODEID) {
      //get new th_course_menu from get_th_info.php and update menuState
      setIsLoading(true);
      const responseNewThCourseMenuRes = await getThInfo();
      setIsLoading(false);
      if (responseNewThCourseMenuRes.code !== RES_CODE_SUCCESS) {
        if (responseNewThCourseMenuRes.code !== TH_INFO_EMPTY_INFO_CODE) {
          dispatchErrorResponse(showGlobalModal, responseNewThCourseMenuRes, undefined, () => {
            modeButton.disabled = false;
          });
        }
      }
      const responseNewThCourseMenu = responseNewThCourseMenuRes.data;

      //update th_course_menu into menuState
      //if data is empty, show dialog with image from introduction of th_menu_info
      const newThCourseMenu =
        responseNewThCourseMenu === undefined || Object.keys(responseNewThCourseMenu).length === 0
          ? null
          : responseNewThCourseMenu;

      appDispatch(allYouCanEatActions.setThCourseMenu(newThCourseMenu));

      const isShowEatRemainTime =
        newThCourseMenu?.th_flg === '1' && newThCourseMenu?.th_limit !== undefined && newThCourseMenu?.th_limit > 0;
      const isShowDrinkRemainTime =
        newThCourseMenu?.nh_flg === '1' && newThCourseMenu?.nh_limit !== undefined && newThCourseMenu?.nh_limit > 0;

      appDispatch(
        allYouCanEatActions.updateStatus({
          isShowEatRemainTime,
          isShowDrinkRemainTime,
        })
      );

      if (newThCourseMenu === null) {
        //show dialog to introduce course
        showCourseIntroduce(true, 'all-you-can-eat');
        modeButton.disabled = false;
        return undefined;
      }
    }

    // pita mode
    if (code === PITA_MODEID) {
      //get new ph_course_menu from get_ph_info.php and update menuState
      setIsLoading(true);
      const responseNewPhCourseMenuRes = await getPhInfo();
      setIsLoading(false);
      if (responseNewPhCourseMenuRes.code !== RES_CODE_SUCCESS) {
        if (responseNewPhCourseMenuRes.code !== PH_INFO_EMPTY_INFO_CODE) {
          dispatchErrorResponse(showGlobalModal, responseNewPhCourseMenuRes, undefined, () => {
            modeButton.disabled = false;
          });
        }
      }
      const responseNewPhCourseMenu = responseNewPhCourseMenuRes.data;

      //update ph_course_menu into menuState
      //if data is empty, show dialog with image from introduction of ph_menu_info
      const newPhCourseMenu =
        responseNewPhCourseMenu === undefined || Object.keys(responseNewPhCourseMenu).length === 0
          ? null
          : responseNewPhCourseMenu;

      appDispatch(pitaActions.setPhCourseMenu(newPhCourseMenu));

      const isShowEatRemainTime =
        newPhCourseMenu?.ph_flg === '1' && newPhCourseMenu?.ph_limit !== undefined && newPhCourseMenu?.ph_limit > 0;
      const isShowDrinkRemainTime =
        newPhCourseMenu?.nh_flg === '1' && newPhCourseMenu?.nh_limit !== undefined && newPhCourseMenu?.nh_limit > 0;

      appDispatch(pitaActions.updateStatus({ isShowEatRemainTime, isShowDrinkRemainTime }));

      if (newPhCourseMenu === null) {
        //show dialog to introduce course
        showCourseIntroduce(true, 'pita');
        modeButton.disabled = false;
        return undefined;
      }
    }

    if (code === GRAND_MENU_MODEID) {
      setIsLoading(true);
      await (() => new Promise((resolve) => setTimeout(() => resolve(true), TAP_ANIMATION_TIME / 2)))();
      setIsLoading(false);
    }

    // save modeCode and categoryCode to SessionStorage and main store
    appDispatch(mainActions.setCurrentModeCode(code));
    SessionStorage.setItem(SESSION_CURRENT_MODE_CODE, code);
    SessionStorage.setItem(SESSION_CURRENT_CATEGROY_CODE, CATEGORY_TOP_MENU_ID);
    (document.querySelector('.form-search-close-hide') as HTMLElement)?.click();

    log({
      op_type: LOG_OP_TYPE_MODE_SWITCHING,
      op_detail: {
        mode: code,
      },
    });

    playSound('mode');

    scrollAnimationTo(document.querySelector('.page-wrapper') as HTMLElement, 0, 300);

    if (active) {
      mainSwiper?.slideTo(0);
      setTimeout(() => {
        const modeThumbButtons = document.querySelectorAll('.mode-thumb-button');
        if (modeThumbButtons) {
          modeThumbButtons.forEach((modeThumbButton) => {
            (modeThumbButton as HTMLButtonElement).disabled = false;
          });
        }
      }, 300);
    }
  };

  return (
    <button className={`mode-thumb-button ${active ? 'active' : ''}`} onClick={!isLoading ? handleClick : undefined}>
      {isLoading ? (
        <div className="spinner-border mode-thumb-button-loading" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        name
      )}
    </button>
  );
}

export default ModeThumbnail;
