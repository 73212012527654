import DishModal from 'components/DishModal/DishModal';
import { PortionStateType } from 'components/MenuDetail/types';
import { createContext, useCallback, useState } from 'react';
import { groupOrderBySimilarity } from 'utils/Utils';
import { SubOrderType } from '../types';
import Dish from './Dish';

export interface CartDishModalContextProps {
  showDishModal?: () => void;
}

export const CartDishModalContext = createContext<CartDishModalContextProps>({});
CartDishModalContext.displayName = 'CartDishModalContext';

export interface DishGroupProps {
  dishKey: string;
  dishValue: SubOrderType;
}

export default function DishGroup({ dishKey, dishValue }: DishGroupProps) {
  const { init: dishInit, state: dishState } = dishValue;
  const [modeCode, dishCode] = JSON.parse(dishKey);
  const uniquePortionQuantityPairs = groupOrderBySimilarity(dishState);
  // const modalRef = useRef<HTMLDivElement>(null);
  const [selectedDishState, setSelectedDishState] = useState<PortionStateType>(dishInit);
  const [openDishModal, setOpenDishModal] = useState(false);

  const updateSelectedDishState = (dishState: PortionStateType) => {
    setSelectedDishState(dishState);
  };

  const ListOfOrder = Object.entries(uniquePortionQuantityPairs).map(([portionStateString, quantity], orderIdx) => {
    const portionGroupState = JSON.parse(portionStateString);
    const hasEditValue = Object.values(dishInit).some((item) => {
      return item.basic.noselectflg !== '1';
    });
    const editable = Object.keys(dishInit).length > 0 && hasEditValue;

    return (
      <Dish
        key={orderIdx}
        editable={editable}
        dishKey={dishKey}
        state={portionGroupState}
        quantity={quantity}
        updateSelectedDishState={updateSelectedDishState}
      />
    );
  });

  const showDishModal = useCallback(() => {
    // detect position of dish modal to show
    const pageWrapper = document.querySelector('.page-wrapper') as HTMLElement;
    if (pageWrapper) {
      document.documentElement.style.setProperty('--position-modal-top', `${pageWrapper.scrollTop}px`);
      pageWrapper.style.overflowY = 'hidden';
    }

    setOpenDishModal(true);
  }, []);

  const closeDishModal = useCallback(() => {
    setOpenDishModal(false);
  }, []);

  return (
    <CartDishModalContext.Provider
      value={{
        showDishModal: showDishModal,
      }}
    >
      {ListOfOrder}
      <DishModal
        open={openDishModal}
        type={'replace'}
        modeCode={modeCode}
        itemCode={dishCode}
        initState={dishState}
        addedState={selectedDishState}
        onClose={closeDishModal}
      />
    </CartDishModalContext.Provider>
  );
}
