import { getThRemainTm } from 'apis/Api';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ConfirmModalProps } from 'components/ComfirmModal/ConfirmModal';
import DishPrice from 'components/DishPrice/DishPrice';
import { GlobalModalContext } from 'components/GlobalModal';
import { initDishState } from 'components/MenuDetail/MenuDetail';
import PageHeader from 'components/PageHeader/PageHeader';
import cartImage from 'images/cart.png';
import { log, LOG_OP_TYPE_ORDER_HISTORY_SCREEN_CLOSE, LOG_OP_TYPE_ORDER_HISTORY_SCREEN_REORDER } from 'log/Log';
import { CartStateType } from 'pages/Cart/types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import { playSound } from 'sound/Sound';
import {
  allYouCanEatActions,
  selectAllYouCanEatState,
  selectThCourseMenu,
  selectThMenuInfo,
} from 'store/allYouCanEatSlice';
import { cartActions } from 'store/cartSlice';
import {
  mainActions,
  selectHaveAdult,
  selectHistoryFlag,
  selectMenuInfo,
  selectOrderHistoryData,
} from 'store/mainSlice';
import { selectPhCourseMenu, selectPhMenuInfo, selectPitaState } from 'store/pitaSlice';
import { selectTimeFrame } from 'store/timeFrameSlice';
import {
  addHistoryOrderToCart,
  attrLang,
  cancelOrder,
  checkAllYouCanEatMenuOver,
  getDishInfo,
  getErrorMessage,
  getHistoryRowCost,
  getModeCodeFromDishInfo,
  getSourceMenuInfo,
  imgFullPath,
  prepareCancelledData,
  stripHtml,
} from 'utils/Utils';
import {
  ALL_YOU_CAN_EAT_MENU_MODEID,
  GRAND_MENU_MODEID,
  LUNCH_MENU_MODEID,
  MODAL_TYPES,
  RES_CODE_SUCCESS,
  TH_INTERVAL_TIME_CHECK_REMAIN_TIME,
  TIME_FRAME_LUNCH,
} from '../../constants';
import './scss/OrderHistory.scss';
import {
  CancelOrderIconProps,
  DishDescriptionProps,
  DishStatusProps,
  OrderAPIType,
  ReorderIconProps,
  RowProps,
} from './types';

export default function OrderHistory() {
  const appDispatch = useAppDispatch();
  const orderHistory = useAppSelector(selectOrderHistoryData);
  const menuInfo = useAppSelector(selectMenuInfo);
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const thCourseMenu = useAppSelector(selectThCourseMenu);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);
  const navigate = useNavigate();
  const closeButtonRef = useRef<HTMLAnchorElement>(null);
  const { t } = useTranslation();
  const totCost = orderHistory.OrderHeader.TotalAmount || 0;
  const isMounted = useRef<boolean>(true);
  const timeInterval = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    const handleClose = () => {
      log({
        op_type: LOG_OP_TYPE_ORDER_HISTORY_SCREEN_CLOSE,
        op_detail: {},
      });
    };
    closeButtonRef.current?.addEventListener('click', handleClose);
    return () => {
      closeButtonRef.current?.removeEventListener('click', handleClose);
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      appDispatch(mainActions.fetchOrderHistoryData());
    }
  }, []);

  const Rows: JSX.Element[] = [];
  orderHistory.OrderDetail.forEach((historyOrder, idx) => {
    const orderNumber =
      historyOrder.OrderDetailHeader.ClientInfo.Type === 'OTHER' ? historyOrder.OrderDetailHeader.ClientInfo.No : '';
    const orderStates = historyOrder.OrderDetailMenuInfo;
    orderStates.forEach((orderState, jdx) => {
      const orderCost = getHistoryRowCost(menuInfo, thMenuInfo, phMenuInfo, orderState);
      Rows.push(
        <Row
          key={idx + '_' + jdx}
          orderType={historyOrder.OrderDetailHeader.ClientInfo.Type}
          orderNumber={orderNumber}
          orderState={orderState}
          cost={orderCost}
        />
      );
    });
  });

  const handleGoBack = () => {
    log({
      op_type: LOG_OP_TYPE_ORDER_HISTORY_SCREEN_CLOSE,
      op_detail: {},
    });
    playSound('page');
    navigate(-1);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const checkRemainTime = () => {
    getThRemainTm().then(async (response) => {
      if (response.code !== RES_CODE_SUCCESS || !isMounted.current) {
        return;
      }

      const data = response.data;
      const isShowEatRemainTime =
        thCourseMenu?.th_flg === '0' ||
        (thCourseMenu?.th_flg === '1' && (data.th_remain_tm === 0 || data.th_sts === '0'))
          ? false
          : true;
      const isShowDrinkRemainTime =
        thCourseMenu?.nh_flg === '0' ||
        (thCourseMenu?.nh_flg === '1' && (data.nh_remain_tm === 0 || data.nh_sts === '0'))
          ? false
          : true;

      appDispatch(
        allYouCanEatActions.updateStatus({
          isShowEatRemainTime,
          isShowDrinkRemainTime,
        })
      );

      if (!isShowEatRemainTime && isShowDrinkRemainTime) {
        //if eat was over, drink still remain
        if (thCourseMenu?.th_limit > 0) {
          //update th_limit = 0 of thCourseMenu
          let newThCourseMenu = thCourseMenu;
          if (newThCourseMenu) {
            newThCourseMenu = {
              ...thCourseMenu,
              th_limit: 0,
            };
            appDispatch(allYouCanEatActions.setThCourseMenu(newThCourseMenu));
          }
        }
      } else if (isShowEatRemainTime && !isShowDrinkRemainTime) {
        //if drink was over, eat still remain
        if (thCourseMenu?.nh_limit > 0) {
          //update nh_limit = 0 of thCourseMenu
          let newThCourseMenu = thCourseMenu;
          if (newThCourseMenu) {
            newThCourseMenu = {
              ...thCourseMenu,
              nh_limit: 0,
            };
            appDispatch(allYouCanEatActions.setThCourseMenu(newThCourseMenu));
          }
        }
      } else if (!isShowEatRemainTime && !isShowDrinkRemainTime) {
        //update th_limit = 0, nh_limit = 0 of thCourseMenu
        let newThCourseMenu = thCourseMenu;
        if (newThCourseMenu) {
          newThCourseMenu = {
            ...thCourseMenu,
            th_limit: 0,
            nh_limit: 0,
          };
          appDispatch(allYouCanEatActions.setThCourseMenu(newThCourseMenu));
        }
      }
    });
  };

  useEffect(() => {
    timeInterval.current = setInterval(() => {
      // disable ReOrder Button Feature
      // checkRemainTime();
    }, TH_INTERVAL_TIME_CHECK_REMAIN_TIME);

    return () => {
      clearInterval(timeInterval.current as NodeJS.Timer);
    };
  }, []);

  useEffect(() => {
    // disable ReOrder Button Feature
    // checkRemainTime();
  }, []);

  // use Effects to check if body tag has full height class
  useEffect(() => {
    const body = document.getElementsByTagName('BODY')[0];
    if (!body.classList.contains('full-height')) {
      body.classList.add('full-height');
    }

    return () => {
      document.getElementsByTagName('BODY')[0].removeAttribute('style');
      if (body.classList.contains('full-height')) {
        body.classList.remove('full-height');
      }
    };
  }, []);

  return (
    <div id="cart">
      <PageHeader handleGoBack={handleGoBack} />
      <div id="order-history" className="cart-body">
        {Rows}
      </div>
      <div className="cart-footer">
        <div className="row g-0 cart-price">
          <div className="col-3 total-price-text">{t('OrderHistory.total_price')}</div>
          <div className="col total-price">
            <DishPrice price={totCost} />
          </div>
        </div>
      </div>
    </div>
  );
}

function Row({ orderType, orderState, cost }: RowProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const menuInfo = useAppSelector(selectMenuInfo);
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);
  const dishCode: string = orderState.MenuCode;

  const sourceMenuInfo = getSourceMenuInfo(dishCode, menuInfo, thMenuInfo, phMenuInfo);

  const dishInfo = getDishInfo(sourceMenuInfo, dishCode);
  if (!dishInfo) return null;
  const dishQuantity: number = parseInt(orderState.CountInfo.Count);
  const imgSrc = imgFullPath(dishInfo?.img);
  const orderName = stripHtml(attrLang(dishInfo, 'lang'));

  return (
    <div className="card dish-card">
      <div className="d-none">{orderType}</div>
      <div className="row g-0">
        <div className="col-6">
          <div className="img-cover">
            <LazyLoadImage
              wrapperClassName="d-block"
              className="img-fluid rounded-start"
              alt="..."
              effect="opacity"
              src={imgSrc}
            />
          </div>
        </div>
        <div className="col-6 d-flex flex-column justify-content-between">
          <div className="card-content">
            <DishStatus orderState={orderState} />
            <h3>{orderName}</h3>
            <DishDescription orderState={orderState} />
            <DishPrice price={cost} />
          </div>
          <div className="card-quantity d-flex">
            <div className="align-self-center text-quantity">{t('Cart.quantity')}</div>
            <div className="align-self-center value-quantity">{dishQuantity}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

function DishStatus({ orderState }: DishStatusProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  let dishStatus;
  let className = '';
  switch (orderState.Status) {
    case '0':
      dishStatus = t('OrderHistory.cooking');
      className = 'cooking';
      break;
    case '1':
      dishStatus = t('OrderHistory.done_preparing');
      className = 'done_preparing';
      break;
    default:
      dishStatus = t('OrderHistory.served');
      className = 'served';
  }

  return (
    <>
      <div className={'order-status order-status-' + className}>{dishStatus}</div>
      {/* {orderState.Status === '0' && orderState.Cancelable === '1' && <CancelOrderIcon orderState={orderState} />} */}
    </>
  );
}

function DishDescription({ orderState }: DishDescriptionProps) {
  const menuInfo = useAppSelector(selectMenuInfo);
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);
  const dishCode: string = orderState.MenuCode;
  const sourceMenuInfo = getSourceMenuInfo(dishCode, menuInfo, thMenuInfo, phMenuInfo);

  return (
    <ul className="option-list">
      {orderState.MenuInfo?.map((optionItem, index) => {
        const optionItemCode = optionItem.MenuCode;
        const optionItemInfo = getDishInfo(sourceMenuInfo, optionItemCode);
        const optionItemName = attrLang(optionItemInfo, 'lang');
        const optionItemQuantity = optionItem.CountInfo.Count;
        return (
          <li key={optionItemCode + '-' + index}>
            {optionItemName} ({optionItemQuantity})
          </li>
        );
      })}
    </ul>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ReorderIcon({ orderState }: ReorderIconProps) {
  const [hidden, setHidden] = useState<boolean>(false);
  const appDispatch = useAppDispatch();
  const menuInfo = useAppSelector(selectMenuInfo);
  const haveAdult = useAppSelector(selectHaveAdult);
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const thCourseMenu = useAppSelector(selectThCourseMenu);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);
  const phCourseMenu = useAppSelector(selectPhCourseMenu);
  const allYouCanEatState = useAppSelector(selectAllYouCanEatState);
  const pitaState = useAppSelector(selectPitaState);
  const navigate = useNavigate();
  const historyFlag = useAppSelector(selectHistoryFlag);
  const timeFrame = useAppSelector(selectTimeFrame);
  const dishCode = orderState.MenuCode;

  const sourceMenuInfo = getSourceMenuInfo(dishCode, menuInfo, thMenuInfo, phMenuInfo);

  const dishInfo = getDishInfo(sourceMenuInfo, dishCode);

  useEffect(() => {
    const modeCode = getModeCodeFromDishInfo(dishInfo);

    const dishMessage = getErrorMessage(
      modeCode,
      timeFrame,
      dishInfo,
      historyFlag,
      thMenuInfo,
      thCourseMenu,
      allYouCanEatState,
      phMenuInfo,
      phCourseMenu,
      pitaState,
      haveAdult,
      false
    );

    let isHidden = true;
    if (orderState.ReOrderable === '0' || dishMessage !== '' || modeCode === '') {
      isHidden = true;
    } else {
      if (modeCode === GRAND_MENU_MODEID) {
        isHidden = false; //always show
      } else if (modeCode === LUNCH_MENU_MODEID) {
        isHidden = timeFrame !== TIME_FRAME_LUNCH;
      } else if (modeCode === ALL_YOU_CAN_EAT_MENU_MODEID) {
        const isMenuOver = checkAllYouCanEatMenuOver(dishInfo, allYouCanEatState);
        isHidden = isMenuOver;
      }
    }

    setHidden(() => {
      return isHidden;
    });
  }, [allYouCanEatState, timeFrame]);

  const handleAddCart = async () => {
    if (hidden) return undefined;
    playSound('page');
    const initialState = initDishState(sourceMenuInfo, dishCode, false);
    const addedState = addHistoryOrderToCart(orderState, initialState[0]);
    const modeCode = dishInfo?.mode.slice(-1)[0] || '1';
    const dishKey = JSON.stringify([modeCode, dishCode]);

    const dishData: CartStateType = {
      [dishKey]: {
        state: Array.from({ length: parseInt(orderState.CountInfo.Count) }, () => addedState),
        init: initialState[0],
      },
    };
    log({
      op_type: LOG_OP_TYPE_ORDER_HISTORY_SCREEN_REORDER,
      op_detail: {
        poscd: dishCode,
      },
    });
    appDispatch(cartActions.add(dishData));
    navigate('/cart/', { replace: true });
  };

  return !hidden ? (
    <a onClick={handleAddCart}>
      <img src={cartImage} className="re-order-icon" />
    </a>
  ) : (
    <></>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function CancelOrderIcon({ orderState }: CancelOrderIconProps) {
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();
  const appDispatch = useAppDispatch();
  const { t } = useTranslation();
  const [hidden, setHidden] = useState<boolean>(false);

  const menuInfo = useAppSelector(selectMenuInfo);
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const phMenuInfo = useAppSelector(selectPhMenuInfo);
  const dishCode: string = orderState.MenuCode;

  const sourceMenuInfo = getSourceMenuInfo(dishCode, menuInfo, thMenuInfo, phMenuInfo);

  const dishInfo = getDishInfo(sourceMenuInfo, dishCode);
  const menuItemName = attrLang(dishInfo, 'lang');

  useEffect(() => {
    const disableTimeOut = setTimeout(() => {
      setHidden(true);
    }, 30000);
    return () => clearTimeout(disableTimeOut);
  }, []);

  const handleConfirm = () => {
    const cancelledData = prepareCancelledData(orderState);
    showGlobalModal<ConfirmModalProps>(MODAL_TYPES.CONFIRM_MODAL, {
      message: t('OrderHistory.confirm_cancel').replace(/{name}/i, menuItemName),
      actionAgree: cancelOrder.bind(null, cancelledData as OrderAPIType, showGlobalModal, appDispatch),
    });
  };

  return !hidden ? (
    <button className="btn btn-primary cancel-button" onClick={handleConfirm}>
      {t('OrderHistory.cancel')}
    </button>
  ) : (
    <></>
  );
}
