import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { allYouCanEatReducer } from 'store/allYouCanEatSlice';
import { cartReducer } from 'store/cartSlice';
import { customerQuantityReducer } from 'store/customerQuantitySlice';
import { errorNotificationReducer } from 'store/errorNotificationSlice';
import { mainReducer } from 'store/mainSlice';
import { pitaReducer } from 'store/pitaSlice';
import { timeFrameReducer } from 'store/timeFrameSlice';
import rootSaga from './rootSaga';

const rootReducer = combineReducers({
  errorNotification: errorNotificationReducer,
  main: mainReducer,
  timeFrame: timeFrameReducer,
  customerQuantity: customerQuantityReducer,
  allYouCanEat: allYouCanEatReducer,
  pita: pitaReducer,
  cart: cartReducer,
});

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: true,
    }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
