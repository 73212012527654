import { useAppSelector } from 'app/hooks';
import CourseIntroductionModal from 'components/CourseIntroductionModal/CourseIntroductionModal';
import { useRef, useState } from 'react';
import { playSound } from 'sound/Sound';
import { selectCurrentModeCode, selectMenuInfo } from 'store/mainSlice';
import { Swiper as SwiperCore } from 'swiper';
import { Payload } from 'types';
import ModeThumbnail from '../ModeThumbnail/ModeThumbnail';
import './scss/ModeBar.scss';

export interface ModeBarProps {
  mainSwiper?: SwiperCore;
}

function ModeBar({ mainSwiper }: ModeBarProps) {
  const currentModeCode = useAppSelector(selectCurrentModeCode);
  const menuInfo = useAppSelector(selectMenuInfo);
  const modeData: Payload = menuInfo.mode;
  const modeBarRef = useRef(null);

  //state
  const [courseIntroModalState, setCourseIntroModalState] = useState<{
    showCourseIntro: boolean;
    typeCourseIntro: string;
  }>({
    showCourseIntro: false,
    typeCourseIntro: '',
  });
  const { showCourseIntro, typeCourseIntro } = courseIntroModalState;

  const handleShowCourseIntroduce = (show: boolean, type: string) => {
    setCourseIntroModalState({ showCourseIntro: show, typeCourseIntro: type });

    const pageWrapper = document.querySelector('.page-wrapper') as HTMLElement;
    if (show) {
      playSound('confirm');

      // detect position of modal to show
      if (pageWrapper) {
        document.documentElement.style.setProperty('--position-modal-top', `${pageWrapper.scrollTop}px`);
        pageWrapper.style.overflowY = 'hidden';
      }

      if (modeBarRef.current) {
        (modeBarRef.current as HTMLDivElement).style.zIndex = '1000';
      }
    } else {
      if (pageWrapper) {
        document.documentElement.style.setProperty('--position-modal-top', `0px`);
        pageWrapper.removeAttribute('style');
      }

      if (modeBarRef.current) {
        (modeBarRef.current as HTMLDivElement).style.zIndex = '999';
      }
    }
  };

  const ListOfModes = Object.entries(modeData).map(([modeCode, modeInfo]) => {
    const active = currentModeCode === modeCode;
    return (
      <ModeThumbnail
        key={modeCode}
        mainSwiper={mainSwiper}
        modeInfo={modeInfo}
        active={active}
        showCourseIntroduce={handleShowCourseIntroduce}
      />
    );
  });

  return (
    <div className="mode-bar" ref={modeBarRef}>
      <div id="mode-thumbnail-block" className="mode-bar-wrapper">
        {ListOfModes}
      </div>
      <CourseIntroductionModal
        show={showCourseIntro}
        type={typeCourseIntro}
        onClose={handleShowCourseIntroduce.bind(null, false, '')}
      />
    </div>
  );
}

export default ModeBar;
