import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { Payload, TimeFrame } from 'types';

export interface TimeFrameState {
  loading: boolean;
  data: TimeFrame;
}

const initialState: TimeFrameState = {
  loading: false,
  data: { timeframe: 1, tm_h: 0, tm_m: 0 },
};

const timeFrameSlice = createSlice({
  name: 'timeFrame',
  initialState,
  reducers: {
    setTimeFrameData(state, action: PayloadAction<TimeFrame>) {
      state.data = action.payload;
    },
    fetchTimeFrameData(state) {
      state.loading = true;
    },
    fetchTimeFrameDataSuccess(state, action: PayloadAction<Payload>) {
      state.data = action.payload.data as TimeFrame;
      state.loading = false;
    },
    fetchTimeFrameDataFailed(state) {
      state.loading = false;
    },
  },
});

// Actions
export const timeFrameActions = timeFrameSlice.actions;

// Selectors
export const selectTimeFrameLoading = (state: RootState) => state.timeFrame.loading;
export const selectTimeFrame = (state: RootState) => state.timeFrame.data.timeframe;

// Reducer
export const timeFrameReducer = timeFrameSlice.reducer;
