import { GlobalModalContext } from 'components/GlobalModal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { playSound } from 'sound/Sound';
import { BarInfo } from 'types';
import { attrLang, imgFullPath } from 'utils/Utils';
import { TAP_ANIMATION_TIME } from '../../constants';
import './scss/MenuBarOrderSuccessModal.scss';

export interface MenuBarOrderSuccessModalProps {
  barInfo: BarInfo;
  onClose?: () => void;
}

function MenuBarOrderSuccessModal({ barInfo, onClose }: MenuBarOrderSuccessModalProps) {
  const { t } = useTranslation();
  const { closeGlobalModal } = GlobalModalContext.useGlobalModalContext();

  const imagepath = imgFullPath(barInfo.img);
  const msg = attrLang(barInfo, 'msg');
  const title = attrLang(barInfo, 'title');

  const handleClose = () => {
    setTimeout(() => {
      playSound('close');
      onClose?.();
      closeGlobalModal();
    }, TAP_ANIMATION_TIME / 2);
  };

  return (
    <div
      className="modal alert-modal menu-bar-order-success-modal"
      data-bs-backdrop="static"
      tabIndex={-1}
      style={{ display: 'block' }}
    >
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '330px', margin: '0 auto' }}>
        <div className="modal-content">
          <div className="modal-body">
            <div className="text-center fw-bold mb-1">{t('Cart.order_success')}</div>
            <div className="menu-bar-order-success-title text-center" dangerouslySetInnerHTML={{ __html: title }}></div>
            <div className="text-center my-1">
              <img width={300} height={200} src={imagepath} />
            </div>
            <div className="fw-bold text-center small" dangerouslySetInnerHTML={{ __html: msg }}></div>
          </div>
          <div className="dialog-footer d-flex justify-content-center">
            <button className="ara-btn ara-btn-outline single" onClick={handleClose}>
              <div>{t('App.close_modal')}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(MenuBarOrderSuccessModal);
