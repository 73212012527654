/* eslint-disable react/react-in-jsx-scope */
interface AraPlusIconProps {
  size?: number | string;
  color?: string;
}
export const AraPlusIcon = ({ size = 14, color = '#F27900' }: AraPlusIconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="5.5" width="3" height="14" rx="1.5" fill="#F27900" />
      <rect x="14" y="5.5" width="3" height="14" rx="1.5" transform="rotate(90 14 5.5)" fill={color} />
    </svg>
  );
};
